import { useContext } from 'react';
import { AuthContext } from 'components/context/auth';
import { useGetFetch, postFetch, putFetch } from 'apis/fetcher';
import URLs from 'constant/urls';
import toQueryString from 'libs/api/toQueryString';

export const useProductService = () => {
  const serviceURL = URLs.product;
  const { token } = useContext(AuthContext);

  return {
    useAllProduct: (queryOptions, swrOptions, ableToFetch = true) => {
      const query = toQueryString({ sort: 'updated_at desc', ...queryOptions });
      const fetchURL = ableToFetch ? `${serviceURL}${query}` : null;
      return useGetFetch(fetchURL, swrOptions);
    },
    useOneProduct: (productId, swrOptions, ableToFetch = true) => {
      const fetchURL = productId && (ableToFetch ? `${serviceURL}/${productId}` : null);
      return useGetFetch(fetchURL, swrOptions);
    },
    addProduct: async data => {
      return await postFetch(serviceURL, { data, token });
    },
    updateProduct: async (productId, data) => {
      return await putFetch(`${serviceURL}/${productId}`, { data, token });
    },
  };
};

export const useProductCategoryService = () => {
  const serviceURL = URLs.productCategory;
  const { token } = useContext(AuthContext);

  return {
    useAllProductCategory: (queryOptions, swrOptions, ableToFetch = true) => {
      const query = toQueryString({
        sort: 'created_at desc',
        limit: 'zero',
        ...queryOptions,
      });
      const fetchURL = ableToFetch ? `${serviceURL}${query}` : null;
      return useGetFetch(fetchURL, swrOptions);
    },
    //GET ONE 目前還沒用到，有API
    useOneProductCategory: () => {},
    addProductCategory: async data => {
      return await postFetch(serviceURL, { data, token });
    },
    updateProductCategory: async (categoryId, data) => {
      return await putFetch(`${serviceURL}/${categoryId}`, { data, token });
    },
  };
};

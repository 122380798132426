import { useState } from 'react';
import { isEmpty } from 'lodash';
import { SearchInput, SearchButtonGroup } from 'components/SearchForm';
import { SelectProject, SelectUsers } from 'components/Input/Search';
import { AutoCompleteCustomer } from 'components/Input/Search/';
import { Grid } from '@mui/material';

const defaultState = {
  ownerUsernames: [],
  projectId: '',
  customer: {},
};

export default function SearchForm(props) {
  const [select, setSelect] = useState(defaultState);

  const handleChange = (name, value) => evt => {
    setSelect({ ...select, [name]: value || evt.target.value });
  };

  const handleClear = () => {
    props.onClearSearch();
    setSelect(defaultState);
  };

  return (
    <>
      <Grid container spacing={2} wrap="no-wrap">
        <Grid item xs={6}>
          <SelectUsers
            label="負責業務/人員 (複選)"
            name="ownerUsernames"
            value={select.ownerUsernames}
            onChange={handleChange('ownerUsernames')}
            onClear={() => setSelect(prev => ({ ...prev, ownerUsernames: [] }))}
          />
        </Grid>
        <Grid item xs={3}>
          <SelectProject label="所屬專案" name="projectId" value={select.projectId} onChange={handleChange('projectId')} />
        </Grid>
        <Grid item xs={3}>
          <AutoCompleteCustomer
            value={isEmpty(select.customer) ? null : select.customer}
            onChange={(e, nv) => handleChange('customer', nv)(e)}
            renderInput={p => {
              return <SearchInput label="客戶名稱" {...p} size="small" />;
            }}
          />
          <input name="customerId" value={select.customer?.id} style={{ display: 'none' }} />
        </Grid>
      </Grid>
      <Grid container spacing={2} wrap="no-wrap">
        <Grid item xs>
          <SearchInput label="提醒日期(起)" type="date" name="remindDateTimeStart" hide />
        </Grid>
        <Grid item xs>
          <SearchInput label="提醒日期(迄)" type="date" name="remindDateTimeEnd" hide />
        </Grid>
        <Grid item xs>
          <SearchInput label="建立日期(起)" type="date" name="createDateTimeStart" hide />
        </Grid>
        <Grid item xs>
          <SearchInput label="建立日期(迄)" type="date" name="createDateTimeEnd" hide />
        </Grid>
      </Grid>
      <SearchButtonGroup formId={props.formId} onClearSearch={handleClear} />
    </>
  );
}

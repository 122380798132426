import { useState } from 'react';

export default function useSearch() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState({});

  const handleChangePage = page => {
    setPage(page);
  };

  const handleChangeLimit = limit => {
    setLimit(limit);
  };

  const handleChangeQuery = query => {
    setQuery(query);
  };

  return {
    page,
    limit,
    query,
    handleChangePage,
    handleChangeLimit,
    handleChangeQuery,
  };
}

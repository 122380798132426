import { FormControl, InputLabel, Select, MenuItem, FormHelperText, Box, Chip, OutlinedInput, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function MultiSelect({ label, value, defaultValue, onChange, displayKey, data, formHelperText, name, onCancel, ...props }) {
  return (
    <FormControl {...props}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        name={name}
        {...(value && { value })}
        {...(defaultValue && { defaultValue })}
        onChange={onChange}
        input={
          <OutlinedInput
            label="選擇人員"
            endAdornment={
              onCancel && (
                <IconButton sx={{ mr: 2 }} onClick={onCancel}>
                  <CloseIcon />
                </IconButton>
              )
            }
          />
        }
        renderValue={selected => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map(value => (
              <Chip
                key={displayKey ? JSON.parse(value)[displayKey] : JSON.parse(value)}
                label={displayKey ? JSON.parse(value)[displayKey] : JSON.parse(value)}
              />
            ))}
          </Box>
        )}
        MenuProps={MenuProps}
      >
        {(data || []).map(item => (
          <MenuItem key={displayKey ? item[displayKey] : item} value={JSON.stringify(item)}>
            {displayKey ? item[displayKey] : item}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>{formHelperText}</FormHelperText>
    </FormControl>
  );
}

import { useState } from 'react';
import { Menu, MenuItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import { ActionButton } from 'components/Button';
import CustomerFormDialog from 'components/Customer/CustomerFormDialog';
import Icon from 'components/Icon';

import { ImportDialog, PoolDialog, BatchLogDialog } from 'components/BatchAction';
import AssignDialog from 'components/BatchAction/Assign/AssignDialog';
import useUserAbility from 'libs/hooks/useUserAbility';
import ParentTransferDialog from 'components/BatchAction/ParentTransfer/ParentTransferDialog';

export default function Actions({ query, totalCount }) {
  const { isAssign } = useUserAbility();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <CustomerFormDialog
        addNewCustomer
        button={
          <ActionButton color="info" startIcon={<Icon name="add" />}>
            新增單筆客戶
          </ActionButton>
        }
      />
      <ActionButton color="info" startIcon={<Icon name="menu" />} onClick={handleClick}>
        批次作業選單
      </ActionButton>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {isAssign && (
          <ImportDialog
            button={
              <MenuItem>
                <ListItemIcon>
                  <Icon name="table" />
                </ListItemIcon>
                <ListItemText>名單多筆匯入</ListItemText>
              </MenuItem>
            }
          />
        )}
        {isAssign && (
          <AssignDialog
            button={
              <MenuItem>
                <ListItemIcon>
                  <Icon name="assign" />
                </ListItemIcon>
                <ListItemText>客戶名單分配</ListItemText>
              </MenuItem>
            }
          />
        )}
        <PoolDialog
          query={query}
          totalCount={totalCount}
          button={
            <MenuItem>
              <ListItemIcon>
                <Icon name="assignInd" />
              </ListItemIcon>
              <ListItemText>客戶加入專案</ListItemText>
            </MenuItem>
          }
        />
        <ParentTransferDialog
          button={
            <MenuItem>
              <ListItemIcon>
                <Icon name="parentChild" />
              </ListItemIcon>
              <ListItemText>母子專案名單轉移</ListItemText>
            </MenuItem>
          }
        />
        <Divider sx={{ my: 1 }} />
        <BatchLogDialog
          button={
            <MenuItem>
              <ListItemIcon>
                <Icon name="cached" />
              </ListItemIcon>
              <ListItemText>批次作業進度查看</ListItemText>
            </MenuItem>
          }
        />
      </Menu>
    </>
  );
}

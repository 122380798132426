import { cloneElement, Children } from 'react';
import PropTypes from 'prop-types';
import { Typography, Stack, Select, MenuItem, Alert } from '@mui/material';

import { TooltipIconButton } from 'components/Button';
import Paginator from 'components/Paginator';

export default function PaginatorContainer({
  children,
  actions,
  totalCount,
  onRefresh,
  limit,
  onLimitChange,
  isLoading,
  showBtmPaginator,
  ...props
}) {
  const paginatorProps = {
    totalPage: props.totalPage,
    currentPage: props.currentPage,
    onChange: props.onPageChange,
    sx: props.sx,
  };

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        sx={{
          '& > div, > p': {
            flex: 1,
            '&:nth-of-type(2)': {
              alignItems: 'center',
            },
          },
        }}
      >
        <Stack direction="row" spacing={1} sx={{ '& > *': { flex: 1 } }}>
          {actions && cloneElement(actions)}
        </Stack>
        <Stack textAlign="center">
          <Paginator {...paginatorProps} />
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="flex-end" spacing={1}>
          {onRefresh && <TooltipIconButton iconName="refresh" onClick={onRefresh} title="重新整理" />}
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography>每頁</Typography>
            <Select
              size="small"
              value={limit}
              onChange={e => {
                props.onPageChange(1);
                onLimitChange(e.target.value);
              }}
            >
              <MenuItem value={5}>5</MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={15}>15</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
            </Select>
            <Typography>筆</Typography>
            <Typography>{`/ 搜尋條件下共 ${totalCount} 筆`}</Typography>
          </Stack>
        </Stack>
      </Stack>
      {children}
      {(Children.count(children) > 5 || showBtmPaginator) && totalCount !== 0 && <Paginator {...paginatorProps} />}
      {!isLoading && totalCount === 0 && (
        <Alert sx={{ width: '100%' }} severity="info">
          查無資料
        </Alert>
      )}
    </>
  );
}

PaginatorContainer.propTypes = {
  actions: PropTypes.element,
  totalCount: PropTypes.number,
  onRefresh: PropTypes.func,

  //for paginator
  totalPage: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  sx: PropTypes.object,

  //for limit
  limit: PropTypes.number.isRequired,
  onLimitChange: PropTypes.func.isRequired,
};

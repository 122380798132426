import { useState } from 'react';
import dayjs from 'dayjs';
import { Stack, Typography, TextField, Button, Paper, MenuItem, FormControlLabel, Switch, Collapse } from '@mui/material';

import { useCampaignVisitService } from 'apis/campaign';
import { useSystemInfoService } from 'apis/systemInfo';
import useInputRangeSelection from 'libs/hooks/useInputRangeSelection';
import useAsync from 'libs/hooks/useAsync';

import { SelectContactStatus } from 'components/Input/Select';
import { visitTypeDisplayName } from 'constant/visitType';
import FaqButtons from './FaqButtons';
import HistoryRecordSection from './HistoryRecordSection';

export default function RecordPanel(props) {
  const { useAllCampaignVisit, updateCampaignVisit, addCampaignVisit, deleteCampaignVisit } = useCampaignVisitService();
  const handleAsync = useAsync();
  const { useOneSystemInfo } = useSystemInfoService();

  const { data: progressBarInfo } = useOneSystemInfo('progressBar');

  const { data: visitsData, mutate } = useAllCampaignVisit({
    projectId: props.projectId,
    customerId: props.customerId,
    limit: 'zero',
  });

  const [selection, handleSelectionChange, handleSelectionTranslation] = useInputRangeSelection();
  const [recordContent, setRecordContent] = useState('');

  const [makeTodo, setMakeTodo] = useState(false);

  const handleClickButton = (content, last = true) => {
    const { selectionStart, selectionEnd } = selection;

    setRecordContent(prev =>
      last ? prev + (prev ? '\n' : '') + content : prev.slice(0, selectionStart) + content + prev.slice(selectionEnd, prev.length),
    );
    handleSelectionTranslation(content.length);
  };

  const handleSubmit = evt => {
    evt.preventDefault();

    const formData = new FormData(evt.target);
    const form = {
      projectId: props.projectId,
      customerId: props.customerId,
      visitedAt: dayjs().toISOString(),
      visitType: formData.get('visitType'),
      description: formData.get('description'),
      visitedResult: formData.get('visitedResult'),
    };

    if (makeTodo) {
      form.task = {};
      form.task.topic = formData.get('taskTopic');
      form.task.description = formData.get('taskDescription');
      form.task.remindAt = dayjs(`${formData.get('remindDate')} ${formData.get('remindTime')}`).toISOString();
    }

    handleAsync(
      addCampaignVisit.bind(null, form),
      () => mutate(),
      () => evt.target.reset(),
    );
  };

  const handleDelete = visitRecordId => {
    handleAsync(deleteCampaignVisit.bind(null, visitRecordId), () => mutate());
  };

  const handleUpdate = (visitRecordId, description) => {
    handleAsync(updateCampaignVisit.bind(null, visitRecordId, { description }), () => mutate());
  };

  return (
    <>
      <Stack p={2} spacing={1.5} sx={{ overflow: 'auto' }}>
        <form onSubmit={handleSubmit}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography variant="h4">新增訪談紀錄</Typography>
            <Stack direction="row" spacing={1}>
              <TextField fullWidth label="訪談類型" size="small" name="visitType" select required defaultValue="">
                <MenuItem value="" disabled>
                  訪談類型
                </MenuItem>
                {Object.entries(visitTypeDisplayName).map(([key, value]) => (
                  <MenuItem key={`record_${key}`} value={key}>
                    {progressBarInfo?.details?.[key]?.customName || value}
                  </MenuItem>
                ))}
              </TextField>
              <SelectContactStatus name="visitedResult" required size="small" />
            </Stack>
            <Stack spacing={1}>
              <FaqButtons onClick={handleClickButton} />
              <TextField
                multiline
                label="紀錄內容"
                name="description"
                minRows={4}
                maxRows={24}
                onClick={handleSelectionChange}
                onKeyUp={handleSelectionChange}
                onChange={e => setRecordContent(e.target.value)}
                value={recordContent}
                defaultValue=""
                required
              />
              <FormControlLabel control={<Switch checked={makeTodo} onChange={e => setMakeTodo(p => !p)} />} label="建立待辦事項" />
              <Collapse in={makeTodo}>
                <Stack direction="row" flexWrap="wrap" gap={1} py={1}>
                  <TextField
                    name="remindDate"
                    label="提醒日期"
                    type="date"
                    required={makeTodo}
                    defaultValue={dayjs().format('YYYY-MM-DD')}
                    inputProps={{
                      min: dayjs().format('YYYY-MM-DD'),
                    }}
                    sx={{ flexBasis: 'calc(25% - 6px)' }}
                  />
                  <TextField
                    name="remindTime"
                    label="提醒時間"
                    type="time"
                    required={makeTodo}
                    defaultValue={dayjs().format('HH:mm')}
                    inputProps={{
                      min: dayjs().format('YYYY-MM-DDTHH:mm'),
                    }}
                    sx={{ flexBasis: 'calc(25% - 6px)' }}
                  />

                  <TextField
                    name="taskTopic"
                    label="主題"
                    required={makeTodo}
                    inputProps={{
                      maxLength: 64,
                    }}
                    sx={{ flexBasis: 'calc(50% - 4px)' }}
                  />
                  <TextField
                    name="taskDescription"
                    label="描述"
                    sx={{ flexBasis: '100%' }}
                    inputProps={{
                      maxLength: 256,
                    }}
                    multiline
                  />
                </Stack>
              </Collapse>
              <Stack direction="row" justifyContent="flex-end">
                <Button variant="contained" color="info" type="submit">
                  儲存紀錄
                </Button>
              </Stack>
            </Stack>
          </Paper>
        </form>

        <HistoryRecordSection visitsData={visitsData} onEdit={handleUpdate} onDelete={handleDelete} />
      </Stack>
    </>
  );
}

import { Stack, Typography } from '@mui/material';

export default function DateBlock(props) {
  const [year, month, date] = props.date.split('-');
  return (
    <Stack alignItems="flex-start" sx={{ color: '#6E6E6E', '& > p': { lineHeight: 1 } }}>
      <Typography variant="body2">{year}</Typography>
      <Typography fontWeight="bolder">
        {month}.{date}
      </Typography>
    </Stack>
  );
}

import { styled } from '@mui/system';
import { Stack } from '@mui/material';

export const SizedStack = styled(Stack)({
  width: '520px',
  margin: '0 0 11px 0',
  '&:nth-of-type(2n)': {
    marginLeft: 20,
  },
  '&:nth-of-type(2n + 1)': {
    marginRight: 20,
  },
  '& > div': {
    gap: 4,
  },
});

export const TimeSection = styled(props => <Stack divider={<div className="midline" />} {...props} />)({
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& > div.midline': {
    margin: '0 22px',
    width: 7,
    border: '1px solid #6E6E6E',
  },
  '& .MuiTextField-root': {
    '& label': {
      backgroundColor: '#fff',
    },
  },
});

import { useParams } from 'react-router-dom';
import { Stack } from '@mui/material';
import { useCustomerService } from 'apis/customer';
import PageContainer from 'components/UI/layout/PageContainer';
import CustomerInfoCard from 'components/Customer/CustomerInfoCard';
import CampaignCard from './CampaignCard';
import { ActionButton } from 'components/Button';
import JoinProjectDialog from './JoinProjectDialog';
import RecordDialog from 'components/Campaign/RecordDialog';
import { useCampaignService } from 'apis/campaign';

export default function RecordsPage() {
  const { id: customerId } = useParams();
  const { useOneCustomer } = useCustomerService();
  const { removeCustomerFromCampaign } = useCampaignService();
  const { data, mutate } = useOneCustomer(customerId);

  const handleDelete = async projectId => {
    await removeCustomerFromCampaign(projectId, customerId);
    mutate();
  };

  return (
    <PageContainer p={3.5} spacing={2}>
      <CustomerInfoCard customerId={customerId} />
      <JoinProjectDialog onAdd={mutate} customerId={customerId} button={<ActionButton iconName="assign">加入專案</ActionButton>} />
      <Stack direction="row" sx={{ flexWrap: 'wrap', gap: 2 }}>
        {data &&
          data?.projectCustomers &&
          data.projectCustomers.map(campaign => (
            <RecordDialog
              onDelete={handleDelete}
              key={`${campaign.projectId}_${campaign.customerId}`}
              customerId={campaign.customerId}
              projectId={campaign.projectId}
              button={<CampaignCard {...campaign} onAddUser={mutate} />}
            />
          ))}
      </Stack>
    </PageContainer>
  );
}

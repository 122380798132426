import { styled } from '@mui/system';
import { Select } from '@mui/material';

const StyledSelect = styled(p => <Select variant="filled" size="small" {...p} />)({
  flex: '1 0 23%',

  overflow: 'hidden',
  backgroundColor: 'white',
  borderRadius: 5,
  '&.Mui-focused': {
    backgroundColor: '#FEFEFE',
  },
  '&:hover': {
    backgroundColor: '#00000017',
  },
});

export default StyledSelect;

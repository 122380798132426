import { useState } from 'react';
import { MenuItem } from '@mui/material';
import { SearchInput, SearchSelect, SearchButtonGroup } from 'components/SearchForm';
import { useProductCategoryService } from 'apis/product';
export default function SearchForm(props) {
  const { useAllProductCategory } = useProductCategoryService();
  const { data: categoryData } = useAllProductCategory({
    limit: 'zero',
    isEnable: true,
  });

  const [select, setSelect] = useState({
    isEnable: '',
    productCategoryId: '',
  });

  const handleChange = evt => {
    const { name, value } = evt.target;
    setSelect({ ...select, [name]: value });
  };

  const handleClear = () => {
    props.onClearSearch();
    setSelect({ isEnable: '', productCategoryId: '' });
  };

  return (
    <>
      <SearchInput label="商品名稱" name="productName" />
      <SearchInput label="商品代碼" name="productCode" />
      <SearchInput label="商品類別" name="productCategoryId" />
      <SearchSelect label="商品類別" name="productCategoryId" value={select.productCategoryId} onChange={handleChange}>
        <MenuItem value="">清除</MenuItem>
        {categoryData &&
          categoryData.list.map(cat => (
            <MenuItem key={cat.id} value={cat.id}>
              {cat.categoryName}
            </MenuItem>
          ))}
      </SearchSelect>
      <SearchInput label="國際條碼" name="internationalBarcode" />
      <SearchSelect label="啟用狀態" name="isEnable" value={select.isEnable} onChange={handleChange}>
        <MenuItem value="">清除</MenuItem>
        <MenuItem value={true}>只顯示啟用中</MenuItem>
        <MenuItem value={false}>只顯示未啟用</MenuItem>
      </SearchSelect>
      <SearchButtonGroup formId={props.formId} onClearSearch={handleClear} />
    </>
  );
}

import PropTypes from 'prop-types';
import { Tooltip, Chip } from '@mui/material';

export default function TagLabel(props) {
  const { tag, ...restProps } = props;
  const displayName = tag.tagName.length > 5 ? `${tag.tagName.slice(0, 5)}...` : tag.tagName;
  const tooltipTitle = tag.description ? `${tag.tagName} | ${tag.description}` : tag.tagName;

  return (
    <Tooltip title={tooltipTitle}>
      <Chip label={displayName} color={props.tag.color} size="small" variant="outlined" {...restProps} />
    </Tooltip>
  );
}

TagLabel.propTypes = {
  tag: PropTypes.shape({
    tagName: PropTypes.string.isRequired,
    description: PropTypes.string,
    tagColor: PropTypes.string,
  }),
};

const MAIN_URL = process.env.REACT_APP_API_URL;

const URLs = {
  user: MAIN_URL + '/user',

  product: MAIN_URL + '/product',
  productCategory: MAIN_URL + '/product-category',

  project: MAIN_URL + '/project',
  campaign: MAIN_URL + '/campaign',
  customer: MAIN_URL + '/customer',
  customerSource: MAIN_URL + '/customer/source',
  customerTag: MAIN_URL + '/customer/tag',
  contact: MAIN_URL + '/contact',

  batch: MAIN_URL + '/batch',

  /* faq */
  faq: MAIN_URL + '/faq',
  projectFaq: MAIN_URL + '/project/faq',

  /* task */
  task: MAIN_URL + '/project/customer/task',

  /* system info */
  systemInfo: MAIN_URL + '/info',
};

export default URLs;

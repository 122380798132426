import * as XLSX from 'xlsx';
import { useState } from 'react';
import useToggle from 'libs/hooks/useToggle';
export default function useSheetReader() {
  const { state: isLoading, toggle } = useToggle();
  const [naturalsData, setNaturalsData] = useState([]);
  const [legalsData, setLegalsData] = useState([]);

  const handleClear = () => {
    setNaturalsData([]);
    setLegalsData([]);
  };

  const handleFile = file => {
    toggle.toTrue();
    handleClear();
    const reader = new FileReader();
    reader.onload = e => {
      /* Parser */
      const ab = e.target.result;
      const wb = XLSX.read(ab, { type: 'array' });
      /* First worksheet */

      if (wb.Sheets.hasOwnProperty('一般客戶')) {
        const naturalsWS = wb.Sheets['一般客戶'];
        /* Convert to JSON */
        const naturalsRawData = XLSX.utils.sheet_to_json(naturalsWS);

        setNaturalsData(
          naturalsRawData.map(row => {
            const gender = row.gender === '男' ? true : row.gender === '女' ? false : null;
            const marriage = row.marriage === '1' ? true : false;
            const tags = row?.tags ? { tags: row.tags.split(',').map(item => item.trim()) } : {};
            //確保以下欄位都是string
            const tempObj = { ...row };
            Object.keys(tempObj).forEach(key => {
              tempObj[key] = `${tempObj[key]}`.trim();
            });

            return { ...tempObj, gender, marriage, ...tags };
          }),
        );
      }
      if (wb.Sheets.hasOwnProperty('法人客戶')) {
        const legalsWS = wb.Sheets['法人客戶'];
        /* Convert to JSON */
        const legalsRawData = XLSX.utils.sheet_to_json(legalsWS).filter(row => Boolean(row.name) === true);

        setLegalsData(
          legalsRawData.map(row => {
            const tags = row?.tags ? { tags: row.tags.split(',').map(item => item.trim()) } : {};
            const tempObj = row;
            //確保以下欄位都是string
            Object.keys(tempObj).forEach(key => {
              tempObj[key] = `${tempObj[key]}`.trim();
            });

            const {
              contactPersonName,
              contactPersonPhone,
              contactPersonDepartment,
              contactPersonGender,
              contactPersonEmail,
              contactPersonDescription,
              ...data
            } = tempObj;

            const contactPersons = [
              {
                name: contactPersonName,
                phone: contactPersonPhone,
                department: contactPersonDepartment || '',
                gender: contactPersonGender === '男' ? true : contactPersonGender === '女' ? false : null,
                email: contactPersonEmail || '',
                description: contactPersonDescription || '',
              },
            ];

            return {
              ...data,
              contactPersons,
              ...tags,
            };
          }),
        );
      }
      setTimeout(() => toggle.toFalse(), 500);
    };

    reader.readAsArrayBuffer(file);
  };

  return { naturalsData, legalsData, handleFile, handleClear, isLoading };
}

import { useMemo } from 'react';
import { MenuItem, ListSubheader } from '@mui/material';
import { SearchSelect } from 'components/SearchForm';
import { useProjectService } from 'apis/project';

export default function SelectProject(props) {
  const { useAllProject } = useProjectService();
  const { data: project } = useAllProject({
    isParent: false,
    limit: 'zero',
    isEnable: true,
  });

  const groupProjectsData = useMemo(() => {
    if (!project) return null;

    return project.list.reduce(
      (acc, cur) => {
        const isNormal = !Boolean(cur.parentProjectId); //if false as normal project
        const isChildProject = Boolean(cur.parentProjectId);

        if (isNormal) {
          acc.find(e => e.name === '一般專案').list.push(cur);
          return acc;
        }

        if (isChildProject) {
          const found = acc.find(e => e.name === cur.parentProject.projectName);
          if (found) {
            found.list.push(cur);
          } else {
            acc.push({ name: cur.parentProject.projectName, list: [cur] });
          }

          return acc;
        }

        throw new Error('??');
      },
      [{ name: '一般專案', list: [] }],
    );
  }, [project]);

  return (
    <SearchSelect
      fullWidth
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: '400px',
          },
        },
      }}
      {...props}
    >
      <MenuItem value="">清除</MenuItem>
      {groupProjectsData &&
        groupProjectsData.map(group => [
          <ListSubheader>{group.name}</ListSubheader>,
          group.list.map(project => (
            <MenuItem key={project.id} value={project.id} sx={{ ml: 2 }}>
              {project.projectName}
            </MenuItem>
          )),
        ])}
    </SearchSelect>
  );
}

import { useContext, useId } from 'react';
import { toast } from 'react-toastify';
import { LoadingBackdropContext } from 'components/context/loadingBackdrop';

export default function useAsync(opt) {
  const toastId = useId();
  const toggleLoading = useContext(LoadingBackdropContext);

  // mainFunction as for Call API or dealing some async action
  // funcs as for actions after mainFunction was success
  const handleAsync = async (mainFunction, ...funcs) => {
    toggleLoading();
    toast(opt?.loadingMsg || '處理中');

    try {
      await mainFunction();
      for (const func of funcs) {
        await func();
      }
      toast.dismiss();
      toast.success(opt?.successMsg || '操作成功', {
        toastId,
      });
    } catch (error) {
      toast.dismiss();
      toast.error(opt?.errorMsg || error.message, {
        toastId,
      });
    } finally {
      setTimeout(() => toggleLoading(), 250);
    }
  };

  return handleAsync;
}

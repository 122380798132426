import { styled } from '@mui/system';
import { Stack, Fab } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useCallback } from 'react';
const Container = styled(Stack)(({ theme }) => ({
  width: '100%',
  paddingBottom: 60,
}));

const PageContainer = ({ children, ...props }) => {
  const handleToTop = useCallback(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Container {...props}>
      {children}
      <Fab color="primary" size="small" sx={{ position: 'fixed', right: 10, bottom: 10 }} onClick={handleToTop}>
        <KeyboardArrowUpIcon />
      </Fab>
    </Container>
  );
};

export default PageContainer;

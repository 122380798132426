/**
  "details": {
      "close": {
          "customName": ""
      },
      "intro": {
          "customName": ""
      },
      "contract": {
          "customName": ""
      },
      "quotation": {
          "customName": ""
      },
      "negotiation": {
          "customName": ""
      }
  }
 */

import { useContext } from 'react';
import { AuthContext } from 'components/context/auth';
import { useGetFetch, postFetch } from 'apis/fetcher';
import URLs from 'constant/urls';

export const useSystemInfoService = () => {
  const serviceURL = URLs.systemInfo;
  const { token } = useContext(AuthContext);

  return {
    useOneSystemInfo: (infoName, swrOptions, ableToFetch = true) => {
      const fetchURL = infoName && (ableToFetch ? `${serviceURL}/${infoName}` : null);
      return useGetFetch(fetchURL, swrOptions);
    },
    updateSystemInfo: async data => {
      return await postFetch(serviceURL, { data, token });
    },
  };
};

import { cloneElement, forwardRef, useImperativeHandle, useState, Children } from 'react';

import { Button, MenuItem, Stack, TextField, Typography } from '@mui/material';
import { TooltipIconButton } from 'components/Button';
import Icon from 'components/Icon';
import { pick } from 'lodash';

const PersonItem = ({ children }) => {
  return (
    <Stack direction="row" flexWrap="wrap" justifyContent="space-between">
      {Children.map(children, (child, index) => {
        const isHead = index === 0;
        const isLast = index === 5 || index === 6;
        return cloneElement(child, {
          ...child.props,
          sx: {
            width: isHead ? '100%' : isLast ? '50%' : '25%',
            mb: 1.5,
            pr: 1,
          },
        });
      })}
    </Stack>
  );
};

const ContactPersonForm = (props, ref) => {
  const { data } = props;

  const [list, setList] = useState(data || []);

  useImperativeHandle(ref, () => ({
    getContactPersons: () =>
      list.map(item => ({
        ...pick(item, ['name', 'department', 'phone', 'email', 'description']),
        gender: item.gender,
      })),
  }));

  const handleAddPerson = () => {
    setList(prev => [
      ...prev,
      {
        name: '',
        gender: '',
        department: '',
        phone: '',
        email: '',
        description: '',
      },
    ]);
  };

  const handleDeletePerson = index => {
    setList(prev => prev.filter((_, i) => i !== index));
  };

  const handleChange = (index, name) => evt => {
    setList(prev => {
      const preArray = prev.slice(0, index);
      const target = prev.slice(index, index + 1)[0];
      const sufArray = prev.slice(index + 1);

      return [...preArray, { ...target, [name]: evt.target.value }, ...sufArray];
    });
  };

  return (
    <>
      <Button
        variant="outlined"
        startIcon={<Icon name="add" />}
        sx={{ position: 'absolute', right: 25, top: 15, alignItems: 'center' }}
        onClick={handleAddPerson}
      >
        新增聯絡人
      </Button>

      {list.map((person, index) => (
        <PersonItem key={`contactPerson_${index}_${person?.id || 'new'}`}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="body1">聯絡人 {index + 1}</Typography>
            <Stack direction="row" spacing={1}>
              <TooltipIconButton iconName="delete" color="error" onClick={handleDeletePerson.bind(null, index)} />
            </Stack>
          </Stack>
          <TextField label="聯絡人" value={person.name} onChange={handleChange(index, 'name')} required />
          <TextField label="電話" value={person.phone} onChange={handleChange(index, 'phone')} required />
          <TextField label="部門" value={person.department} onChange={handleChange(index, 'department')} />
          <TextField select label="性別" value={person.gender} onChange={handleChange(index, 'gender')}>
            <MenuItem value="">性別</MenuItem>
            <MenuItem value={true}>男性</MenuItem>
            <MenuItem value={false}>女性</MenuItem>
          </TextField>
          <TextField label="信箱" value={person.email} onChange={handleChange(index, 'email')} />
          <TextField label="備註" value={person.description} onChange={handleChange(index, 'description')} />
        </PersonItem>
      ))}
    </>
  );
};

export default forwardRef(ContactPersonForm);

import { useContext, useCallback } from 'react';
import { AuthContext } from 'components/context/auth';
import ROLE from 'constant/role';

export default function usePathCheck() {
  const { user } = useContext(AuthContext);

  const isCurUserIncludeIn = useCallback(
    path => {
      if (ROLE[user?.role.toLowerCase()] === 0) {
        return true;
      }

      return path.split('.').find(username => username === user?.username);
    },
    [user],
  );

  return isCurUserIncludeIn;
}

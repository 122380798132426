import { useContext } from 'react';
import { AuthContext } from 'components/context/auth';
import { useGetFetch, putFetch, deleteFetch } from 'apis/fetcher';
import toQueryString from 'libs/api/toQueryString';
import URLs from 'constant/urls';

/**
 * GET /project/customer/task
 * GET /project/customer/{id}
 * PUT /project/customer/{id}
 * DELETE /project/customer/{id}
 */
export const useTaskService = () => {
  const serviceURL = URLs.task;
  const { token } = useContext(AuthContext);

  return {
    useAllTask: (queryOptions, swrOptions, ableToFetch = true) => {
      const query = toQueryString({ ...queryOptions });
      const fetchURL = ableToFetch ? serviceURL + query : null;
      return useGetFetch(fetchURL, swrOptions);
    },
    useOneTask: (taskId, swrOptions, ableToFetch = true) => {
      const fetchURL = taskId && (ableToFetch ? `${serviceURL}/${taskId}` : null);
      return useGetFetch(fetchURL, swrOptions);
    },
    updateTask: async (taskId, data) => {
      return await putFetch(`${serviceURL}/${taskId}`, { data, token });
    },
    deleteTask: async taskId => {
      return await deleteFetch(`${serviceURL}/${taskId}`, { token });
    },
  };
};

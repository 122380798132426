import { cloneElement } from 'react';
import useToggle from 'libs/hooks/useToggle';
import { useProjectService } from 'apis/project';
import { useCustomerService } from 'apis/customer';
import { Chip, Dialog, DialogContent, Divider, Stack, Typography, Button } from '@mui/material';
import html2canvas from 'html2canvas';
const CustomerChip = ({ title, value }) => {
  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      <Chip label={title} size="large" sx={{ fontSize: '1.5rem' }} />
      <Typography variant="h3">{value || '--'}</Typography>
    </Stack>
  );
};

export default function RecordSheetDialog({ button, customerId, projectId, userName, recordTime, description }) {
  const { state, toggle } = useToggle();
  const { useOneProject } = useProjectService();
  const { useOneCustomer } = useCustomerService();
  const { data: customer } = useOneCustomer(customerId, {}, state);
  const { data: project } = useOneProject(projectId, {}, state);

  const handleImage = async () => {
    const element = document.querySelector('#sheet');
    const canvas = await html2canvas(element, { scale: 2 });
    const image = canvas.toDataURL('image/png');

    const a = document.createElement('a');
    a.href = image;
    a.download = `電訪紀錄單_${customer.name}_${recordTime}.png`;
    a.click();
  };

  return (
    <>
      {cloneElement(button, { onClick: toggle })}
      <Dialog open={state} onClose={toggle} maxWidth="xl" fullWidth>
        <Stack m={1}>
          <Button onClick={handleImage} variant="outlined">
            下載
          </Button>
        </Stack>
        <DialogContent sx={{ backgroundColor: '#00000050' }}>
          <Stack
            sx={{
              width: '1240px',
              minHeight: '1754px',
              p: 1,
              px: 2.5,
              backgroundColor: 'white',
              boxShadow: '0px 2px 2px #00000025',
            }}
            spacing={3}
            id="sheet"
          >
            <div id="sheet-container"></div>
            {customer && project && (
              <>
                <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
                  <Typography variant="h1">
                    {customer.name} | {project.projectName}
                  </Typography>
                  <Typography variant="h2">電訪紀錄單</Typography>
                </Stack>
                <Divider />
                <Stack direction="row" sx={{ flexWrap: 'wrap', gap: 2, '& > div': { flexBasis: '30%' } }}>
                  <Typography variant="h3" flexGrow="1" sx={{ width: '100%' }}>
                    基本資料
                  </Typography>
                  <CustomerChip title="手機" value={customer.phone} />
                  <CustomerChip
                    title="家電"
                    value={`${customer.localAreaCode ?? ''}${customer.localPhone ?? ''}#${customer.localExt ?? ''}`}
                  />
                  <CustomerChip title="信箱" value={customer.email} />
                  <CustomerChip title="生日" value={customer.birthday} />
                  <CustomerChip title="婚姻狀態" value={customer.marriage ? '已婚' : '未婚'} />
                  <CustomerChip title="備註" value={customer.description} />
                  {customer.customerType === 'natural' && <CustomerChip title="性別" value={customer.gender ? '男' : '女'} />}
                </Stack>
                <Stack direction="row" sx={{ flexWrap: 'wrap', gap: 1, '& > div': { flexBasis: '30%' } }}>
                  <Typography variant="h3" flexGrow="1" sx={{ width: '100%' }}>
                    公司資訊
                  </Typography>
                  <CustomerChip title="名稱" value={customer.customerType === 'legal' ? customer.name : customer.companyName} />
                  <CustomerChip title="統編" value={customer.taxIdNo} />
                  <CustomerChip title="電話" value={customer.customerType === 'legal' && customer.phone} />
                  {customer.customerType === 'natural' && <CustomerChip title="部門" value={customer.department} />}
                  <CustomerChip title="職位" value={customer.jobTitle} />

                  <CustomerChip title="專線" value={customer.customerType === 'legal' && customer.phone} />
                </Stack>
                <Stack direction="column" spacing={1}>
                  <Typography variant="h3" flexGrow="1" sx={{ width: '100%' }}>
                    地址
                  </Typography>
                  <CustomerChip
                    title="通訊地址"
                    value={[
                      customer.communicationAddressZipCode,
                      customer.communicationAddressCounty,
                      customer.communicationAddressCity,
                      customer.communicationAddressStreet,
                    ].join('')}
                  />
                  <CustomerChip
                    title="送貨地址"
                    value={[
                      customer.shipAddressZipCode,
                      customer.shipAddressCounty,
                      customer.shipAddressCity,
                      customer.shipAddressStreet,
                    ].join('')}
                  />

                  <CustomerChip
                    title="發票地址"
                    value={[
                      customer.invoiceAddressZipCode,
                      customer.invoiceAddressCounty,
                      customer.invoiceAddressCity,
                      customer.invoiceAddressStreet,
                    ].join('')}
                  />
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
                  <Typography variant="h3">紀錄內容</Typography>
                  <Typography variant="h3">{`記錄人員：${userName}　時間：${recordTime}`}</Typography>
                </Stack>
                <Divider />
                <Typography variant="h3" sx={{ whiteSpace: 'pre-wrap', px: 1, fontWeight: 'regular' }}>
                  {description}
                </Typography>
              </>
            )}
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  );
}

import { useState } from 'react';
import { isEmpty } from 'lodash';
import { Autocomplete } from '@mui/material';
import { useCustomerService } from 'apis/customer';

export default function AutoCompleteCustomer(props) {
  const { useAllCustomer } = useCustomerService();

  const [customerName, setCustomerName] = useState('');

  const { data } = useAllCustomer({
    customerName: props?.inputValue || customerName,
    limit: 30,
  });

  return (
    <Autocomplete
      options={data?.list || []}
      getOptionLabel={option => option.name}
      inputValue={customerName}
      onInputChange={(e, nv) => setCustomerName(nv)}
      {...props}
      loading={isEmpty(data?.list)}
      loadingText={'建議選項整理中...'}
      openOnFocus
    />
  );
}

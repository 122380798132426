import PropTypes from 'prop-types';
import { useState, useMemo } from 'react';
import { MenuItem, Box, Chip, ListSubheader } from '@mui/material';

import { useCustomerSourceService, useCustomerTagService } from 'apis/customer';
import { useProjectService } from 'apis/project';
import { SearchInput, SearchSelect, SearchButtonGroup } from 'components/SearchForm';
import { FreeSoloCompanyName, FreeSoloCounty, FreeSoloPhone } from 'components/Input/Search';

export default function SearchForm(props) {
  const { useAllProject } = useProjectService();
  const { useAllCustomerTag } = useCustomerTagService();
  const { useAllCustomerSource } = useCustomerSourceService();

  const { data: tagsData } = useAllCustomerTag({ limit: 'zero' });
  const { data: sourcesData } = useAllCustomerSource();
  const { data: parentProjectsData } = useAllProject({
    isParent: true,
    limit: 'zero',
    isEnable: true,
  });
  const { data: nonParentProjectsData } = useAllProject({
    isParent: false,
    limit: 'zero',
    isEnable: true,
  });

  const groupNonParentProjectsData = useMemo(() => {
    if (!nonParentProjectsData) return null;

    return nonParentProjectsData.list.reduce(
      (acc, cur) => {
        const isNormal = !Boolean(cur.parentProjectId); //if false as normal project
        const isChildProject = Boolean(cur.parentProjectId);

        if (isNormal) {
          acc.find(e => e.name === '一般專案').list.push(cur);
          return acc;
        }

        if (isChildProject) {
          const found = acc.find(e => e.name === cur.parentProject.projectName);
          if (found) {
            found.list.push(cur);
          } else {
            acc.push({ name: cur.parentProject.projectName, list: [cur] });
          }

          return acc;
        }

        throw new Error('??');
      },
      [{ name: '一般專案', list: [] }],
    );
  }, [nonParentProjectsData]);

  const [select, setSelect] = useState({
    phone: '',
    addressCounty: '',
    gender: '',
    grade: '',
    customerType: '',
    sourceId: '',
    isAlone: '',
    projectId: '',
    tagIds: [],
    parentProjectIds: [],
    companyName: '',
  });

  const handleChange = evt => {
    const { name, value } = evt.target;
    setSelect({ ...select, [name]: value });
  };

  const handleClear = () => {
    props.onClearSearch();
    setSelect({
      phone: '',
      addressCounty: '',
      gender: '',
      grade: '',
      customerType: '',
      sourceId: '',
      isAlone: '',
      projectId: '',
      tagIds: [],
      parentProjectIds: [],
      companyName: '',
    });
  };

  return (
    <>
      {/* <SearchInput label="電話" name="phone" /> */}
      <FreeSoloPhone
        inputValue={select.phone}
        onInputChange={(e, nv) => handleChange({ target: { name: 'phone', value: nv } })}
        renderInput={p => {
          return <SearchInput label="電話" name="phone" {...p} size="small" />;
        }}
        sx={{ width: '24%' }}
      />
      <SearchInput label="姓名/公司名稱" name="customerName" />
      {/* <SearchInput label="地址(縣市)" name="addressCounty" /> */}
      <FreeSoloCounty
        inputValue={select.addressCounty}
        onInputChange={(e, nv) => handleChange({ target: { name: 'addressCounty', value: nv } })}
        renderInput={p => {
          return <SearchInput label="地址(縣市)" name="addressCounty" {...p} size="small" />;
        }}
        sx={{ width: '24%' }}
      />
      <SearchSelect label="性別" name="gender" value={select.gender} onChange={handleChange}>
        <MenuItem value="">清除</MenuItem>
        <MenuItem value={true}>男性</MenuItem>
        <MenuItem value={false}>女性</MenuItem>
      </SearchSelect>
      <FreeSoloCompanyName
        inputValue={select.companyName}
        onInputChange={(e, nv) => handleChange({ target: { name: 'companyName', value: nv } })}
        renderInput={p => {
          return <SearchInput label="服務機關名稱" name="companyName" {...p} size="small" />;
        }}
        sx={{ width: '24%' }}
      />
      <SearchInput label="服務機關部門" name="department" />
      <SearchInput label="服務機關職稱" name="jobTitle" />
      <SearchSelect label="等級" name="grade" value={select.grade} onChange={handleChange}>
        <MenuItem value="">清除</MenuItem>
        <MenuItem value="normal">一般</MenuItem>
        <MenuItem value="VIP">VIP</MenuItem>
      </SearchSelect>
      <SearchInput label="統一編號" name="taxIdNo" />
      <SearchInput label="年紀範圍(起)" name="ageStart" />
      <SearchInput label="年紀範圍(迄)" name="ageEnd" />
      <SearchSelect label="客戶類別" name="customerType" value={select.customerType} onChange={handleChange}>
        <MenuItem value="">客戶類別</MenuItem>
        <MenuItem value="natural">一般客戶</MenuItem>
        <MenuItem value="legal">法人(公司行號)</MenuItem>
      </SearchSelect>
      <SearchSelect label="客戶來源" name="sourceId" value={select.sourceId} onChange={handleChange}>
        <MenuItem value="">清除</MenuItem>
        {sourcesData &&
          sourcesData.list.map(source => (
            <MenuItem key={source.id} value={source.id}>
              {source.sourceName}
            </MenuItem>
          ))}
      </SearchSelect>
      <SearchSelect
        label="所屬專案"
        name="projectId"
        value={select.projectId}
        onChange={handleChange}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: '400px',
            },
          },
        }}
      >
        <MenuItem value="">清除</MenuItem>
        {/* {nonParentProjectsData &&
          nonParentProjectsData.list.map(project => (
            <MenuItem key={project.id} value={project.id}>
              <ListItemText>{project.projectName}</ListItemText>
              <Typography>
                {project.isParent ? "母專案" : project.parentProjectId ? "子專案" : ""}
              </Typography>
            </MenuItem>
          ))} */}
        {groupNonParentProjectsData &&
          groupNonParentProjectsData.map(group => [
            <ListSubheader>{group.name}</ListSubheader>,
            group.list.map(project => (
              <MenuItem key={project.id} value={project.id} sx={{ ml: 2 }}>
                {project.projectName}
              </MenuItem>
            )),
          ])}
      </SearchSelect>
      <SearchInput label="專案日期(起)" name="projectStartDate" type="date" hide />
      <SearchInput label="專案日期(迄)" name="projectEndDate" type="date" hide />
      <SearchInput label="專案日期" name="projectDate" type="date" hide />
      <SearchSelect
        width="49.25%"
        label="母專案(複選)"
        name="parentProjectIds"
        value={select.parentProjectIds}
        onChange={handleChange}
        onClear={() => setSelect(prev => ({ ...prev, parentProjectIds: [] }))}
        multiple
        renderValue={selected => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map(value => (
              <Chip key={value} label={parentProjectsData.list.find(tag => tag.id === value).projectName} />
            ))}
          </Box>
        )}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: '400px',
            },
          },
        }}
      >
        {parentProjectsData &&
          parentProjectsData.list.map(project => (
            <MenuItem key={project.id} value={project.id}>
              {project.projectName}
            </MenuItem>
          ))}
      </SearchSelect>
      <SearchSelect label="客戶無任何專案" name="isAlone" value={select.isAlone} onChange={handleChange}>
        <MenuItem value="">清除</MenuItem>
        <MenuItem value={true}>是</MenuItem>
      </SearchSelect>

      <SearchSelect
        label="客戶標籤(複選)"
        name="tagIds"
        multiple
        value={select.tagIds}
        onChange={handleChange}
        onClear={() => setSelect(prev => ({ ...prev, tagIds: [] }))}
        renderValue={selected => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map(value => (
              <Chip key={value} label={tagsData.list.find(tag => tag.id === value).tagName} />
            ))}
          </Box>
        )}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: '400px',
            },
          },
        }}
      >
        {tagsData &&
          tagsData.list.map(tag => (
            <MenuItem key={tag.id} value={tag.id}>
              {tag.tagName}
            </MenuItem>
          ))}
      </SearchSelect>

      <SearchButtonGroup formId={props.formId} onClearSearch={handleClear} />
    </>
  );
}

SearchForm.propTypes = {
  formId: PropTypes.string.isRequired,
  onClearSearch: PropTypes.func.isRequired,
};

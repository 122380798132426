import { useState } from 'react';

import Helmet from 'components/Helmet';
import PageContainer from 'components/UI/layout/PageContainer';
import ProductCategory from 'components/maintain/ProductCategory';
import Source from 'components/maintain/Source';
import RecordFaq from 'components/maintain/RecordFaq';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CustomerTag from 'components/maintain/CustomerTag';
import SystemInfo from 'components/maintain/SystemInfo';

export default function MaintainPage() {
  const [value, setValue] = useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Helmet title="維護項目管理" />
      <PageContainer>
        <Box sx={{ width: '100%', typography: 'body1' }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'lightgray', background: 'white' }}>
              <TabList onChange={handleChange} aria-label="lab API tabs example">
                <Tab label="商品類別" value="1" />
                <Tab label="來源項目" value="2" />
                <Tab label="紀錄快捷按鈕" value="3" />
                <Tab label="客戶標籤" value="4" />
                <Tab label="系統設定" value="5" />
              </TabList>
            </Box>
            <TabPanel value="1">
              <ProductCategory />
            </TabPanel>
            <TabPanel value="2">
              <Source />
            </TabPanel>
            <TabPanel value="3">
              <RecordFaq />
            </TabPanel>
            <TabPanel value="4">
              <CustomerTag />
            </TabPanel>
            <TabPanel value="5">
              <SystemInfo />
            </TabPanel>
          </TabContext>
        </Box>
      </PageContainer>
    </>
  );
}

import { cloneElement, useId, useState, useEffect } from 'react';
import { has } from 'lodash';
import { MenuItem, Stack, FormControlLabel, Checkbox, Switch, InputAdornment, Button } from '@mui/material';
import useToggle from 'libs/hooks/useToggle';
import { FullScreenDialog } from 'components/Dialog';
import { FormContainer } from 'components/Container';
import { SizedInput } from 'components/Input';
import { useUserService } from 'apis/user';
import useAsync from 'libs/hooks/useAsync';

export default function EmployeeFormDialog({ button, employeeId, addNewEmployee }) {
  const formId = useId();
  const { state, toggle } = useToggle();
  const handleAsync = useAsync();

  const { useAllUser, useOneUser, updateUser, addUser, validateDuplicateUsername } = useUserService();
  const { data: managerData } = useAllUser({ role: 'manager' }, null, state);
  const { data: userData, isLoading, mutate } = useOneUser(employeeId, null, state && !addNewEmployee);

  const [isValid, setIsValid] = useState(addNewEmployee ? false : true);
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');

  useEffect(() => {
    setRole(addNewEmployee ? '' : userData?.role || '');
    // eslint-disable-next-line
  }, [userData]);

  useEffect(() => {
    return () => setIsValid(addNewEmployee ? false : true);
    // eslint-disable-next-line
  }, [state]);

  const handleSubmit = async evt => {
    evt.preventDefault();
    const form = new FormData(evt.target);
    const obj = {};
    for (const [k, v] of form) {
      if (!addNewEmployee) {
        if (k === 'password' && v === '') {
          continue;
        }
      }
      if (k === 'supervisorUsername' && v === '') {
        continue;
      }
      if (k === 'username') {
        obj[k] = v.toLowerCase();
      }

      obj[k] = v;
    }
    if (has(obj, 'isEnable')) {
      obj.isEnable = true;
    } else {
      obj.isEnable = false;
    }
    if (has(obj, 'isAssign')) {
      obj.isAssign = true;
    } else {
      obj.isAssign = false;
    }

    handleAsync(
      () => {
        if (!isValid) throw new Error('請先驗證帳號');
      },
      addNewEmployee ? addUser.bind(null, obj) : updateUser.bind(null, employeeId, obj),
      () => mutate(null),
    );
  };

  const handleValidateDuplicateUsername = () => {
    handleAsync(validateDuplicateUsername.bind(null, username), () => setIsValid(true));
  };

  return (
    <>
      {cloneElement(button, { onClick: toggle })}
      <FullScreenDialog
        open={state}
        onClose={toggle}
        onConfirm={toggle}
        formId={formId}
        isConfirmAsSubmit
        isLoading={isLoading && !addNewEmployee}
        title={button ? button.props.children : addNewEmployee ? '新增客戶' : '編輯客戶資料'}
      >
        {(userData || addNewEmployee) && (
          <Stack
            component="form"
            id={formId}
            onSubmit={handleSubmit}
            sx={{
              padding: '36px 30px 16rem',
              gap: 4,
            }}
          >
            <FormContainer title="帳號">
              {!addNewEmployee && (
                <FormControlLabel
                  control={<Switch name="isEnable" inputProps={{ name: 'isEnable' }} defaultChecked={userData.isEnable} />}
                  label="啟用員工"
                  sx={{ width: '100%', mb: 2 }}
                />
              )}

              <SizedInput
                required
                disabled={!addNewEmployee}
                label="帳號"
                name="username"
                value={userData?.username || username}
                onChange={e => {
                  setIsValid(false);
                  setUsername(e.target.value.toLowerCase());
                }}
                defaultValue={addNewEmployee ? '' : userData.username}
                {...(addNewEmployee && {
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <Button variant="contained" color={isValid ? 'success' : 'error'} onClick={handleValidateDuplicateUsername}>
                          {isValid ? '可使用' : '請點擊驗證'}
                        </Button>
                      </InputAdornment>
                    ),
                  },
                })}
                inputProps={{
                  minLength: 6,
                  pattern: '^[0-9A-Za-z_]*$',
                }}
                helperText="最小長度 6 位數，僅能使用英文與數字"
              />
              <SizedInput
                required={addNewEmployee}
                label="密碼"
                name="password"
                type="password"
                defaultValue={''}
                inputProps={{
                  pattern: '^[0-9A-Za-z_]*$',
                }}
              />
            </FormContainer>
            <FormContainer title="員工資訊">
              <SizedInput label="員工姓名" name="displayName" defaultValue={addNewEmployee ? '' : userData.displayName} required />
              <SizedInput label="電話" name="phone" type="phone" defaultValue={addNewEmployee ? '' : userData.phone} required />
              <SizedInput
                required={role === 'manager'}
                disabled={role !== 'manager'}
                label="所屬區域"
                name="zone"
                defaultValue={addNewEmployee ? '' : userData.zone}
              />
              <SizedInput label="職位" name="position" defaultValue={addNewEmployee ? '' : userData.position} />
              <SizedInput
                select
                label="直屬主管"
                name="supervisorUsername"
                defaultValue={addNewEmployee ? '' : userData.supervisorUsername}
              >
                <MenuItem value="">清除</MenuItem>
                {managerData &&
                  managerData.list.map(manager => (
                    <MenuItem key={manager.id} value={manager.username}>
                      {manager.displayName}
                    </MenuItem>
                  ))}
              </SizedInput>
              <SizedInput select required label="角色權限" name="role" value={role} onChange={e => setRole(e.target.value)}>
                <MenuItem value="">清除</MenuItem>
                <MenuItem value="user">一般人員</MenuItem>
                <MenuItem value="manager">主管</MenuItem>
              </SizedInput>
            </FormContainer>
            <FormContainer title="權限">
              <FormControlLabel
                control={
                  <Checkbox name="isAssign" inputProps={{ name: 'isAssign' }} defaultChecked={addNewEmployee ? false : userData.isAssign} />
                }
                label="匯入名單以及分派權限"
              />
            </FormContainer>
          </Stack>
        )}
      </FullScreenDialog>
    </>
  );
}

import { cloneElement, useEffect, useId, useState, useMemo, useRef } from 'react';
import { size, isEmpty } from 'lodash';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button,
  Stack,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Tooltip,
} from '@mui/material';

import { useUserService } from 'apis/user';
import { useBatchService } from 'apis/batch';
import useToggle from 'libs/hooks/useToggle';
import useAsync from 'libs/hooks/useAsync';
import generateBatchCode from 'libs/generateBatchCode';
import DistributeTable from './DistributeTable';

export default function TransferDialog({ button, query, totalCount }) {
  const { useAllUser } = useUserService();

  const tableRef = useRef();
  const formId = useId();
  const { state, toggle } = useToggle();
  const { state: isAutoAssign, toggle: toggleAutoAssign } = useToggle(true);
  const handleAsync = useAsync();

  const [chooseUsers, setChooseUsers] = useState([]);

  const { data: usersData } = useAllUser({ isHierarchy: true });
  const { transferCustomer } = useBatchService();

  //transfer
  const statCount = useMemo(() => {
    return [totalCount, 0];
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    return () => {
      setChooseUsers([]);
    };
  }, [state]);

  const handleSubmit = evt => {
    evt.preventDefault();

    const formData = new FormData(evt.target);
    const form = {};

    for (const [key, value] of formData) {
      form[key] = value;
    }

    const assignUsers = isAutoAssign
      ? chooseUsers.map(e => ({ username: e.split('_')[0] }))
      : chooseUsers.map(e => ({
          username: e.split('_')[0],
          quantity: Number(tableRef.current.getList()[e.split('_')[0]]),
        }));

    handleAsync(async () => {
      if (!isAutoAssign) {
        const assignUsersQuantity = assignUsers.reduce((acc, cur) => acc + Number(cur.quantity), 0);
        if (assignUsersQuantity !== statCount[0] - statCount[1]) {
          throw new Error('填寫總數量 需等於 可分配客戶數量');
        }
      }

      await transferCustomer(query, {
        batchCode: generateBatchCode('TRANSFER'),
        assignUsers,
        ...form,
      });

      toggle();
    });
  };

  const handleRemove = index => {
    setChooseUsers(prev => prev.filter((_, i) => i !== index));
  };

  return (
    <>
      <Tooltip title="此功能會採用當前搜尋進行作業，請先使用搜尋並確認結果，再行使用" placement="right">
        <span>
          {cloneElement(button, {
            onClick: toggle,
            disabled: isEmpty(query) || totalCount === 0,
          })}
        </span>
      </Tooltip>
      <Dialog open={state} onClose={toggle} maxWidth="sm" fullWidth>
        <DialogTitle>批次作業 - 轉移名單</DialogTitle>
        <DialogContent>
          <DialogContentText>
            將客戶轉移，轉移過程可至 <b>批次作業進度</b> 查看
          </DialogContentText>
          <Stack component="form" id={formId} onSubmit={handleSubmit} spacing={2} mt={1}>
            <FormControl required>
              <InputLabel>選擇人員(複選)</InputLabel>
              <Select label="選擇人員(複選)" multiple value={chooseUsers} onChange={e => setChooseUsers(e.target.value)} displayEmpty>
                {usersData &&
                  usersData.list.map(user => (
                    <MenuItem key={`opt_${user.id}`} value={`${user.username}_${user.displayName}`}>
                      {user.displayName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Stack>
          <DistributeTable
            ref={tableRef}
            users={chooseUsers}
            statCount={statCount}
            onRemove={handleRemove}
            isAutoAssign={isAutoAssign}
            toggleAutoAssign={toggleAutoAssign}
            disabled={!size(chooseUsers)}
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit" form={formId} disabled={!size(chooseUsers)}>
            確認
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

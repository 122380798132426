import { useState } from 'react';
import { Fab, Drawer, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SideMenu from './SideMenu';

const SideDrawer = ({ isManager, onLogout, userName }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Fab
        variant="extended"
        color="info"
        onClick={handleOpen}
        sx={{ position: 'fixed', bottom: 15, left: 15, pl: 1, pr: 2, alignItems: 'center' }}
      >
        <MenuIcon sx={{ mr: 1 }} />
        <Typography>選單 | {userName}</Typography>
      </Fab>
      <Drawer open={open} onClose={handleClose} sx={{ '& .MuiPaper-root': { height: '100%' } }}>
        <SideMenu isManager={isManager} onLogout={onLogout} userName={userName} onLinkClick={handleClose} />
      </Drawer>
    </>
  );
};

SideDrawer.Menu = SideMenu;

export default SideDrawer;

import { cloneElement, useEffect, useId, useMemo, useRef, useState } from 'react';
import { Stack, Typography, TextField, FormControl, InputLabel, Select, MenuItem, Button } from '@mui/material';

import useAsync from 'libs/hooks/useAsync';
import useToggle from 'libs/hooks/useToggle';
import { useCustomerService, useCustomerTagService } from 'apis/customer';
import { FullScreenDialog } from 'components/Dialog';
import { FormContainer } from 'components/Container';
import { SizedInput, MultiSelect } from 'components/Input';
import AddressForm from './AddressForm';
import ContactPersonForm from './ContactPersonForm';
import { SizedStack, TimeSection } from './style';
import PrincipleUsersForm from './PrincipleUsersForm';

export default function CustomerFormDialog({ button, customerId, addNewCustomer }) {
  const formId = useId();
  const contactFormRef = useRef();
  const handleAsync = useAsync();
  const { state, toggle } = useToggle(false);

  // 編輯暫無來源
  // const { useAllCustomerSource } = useCustomerSourceService()
  // const { data: sourcesData } = useAllCustomerSource()

  const { useAllCustomerTag } = useCustomerTagService();
  const { data: tagsData } = useAllCustomerTag();

  const { useOneCustomer, updateCustomer, createCustomer, deleteCustomer } = useCustomerService();
  const { data: customerData, isLoading, mutate } = useOneCustomer(customerId, null, state && !addNewCustomer);

  const [chooseCustomerType, setChooseCustomerType] = useState('natural');

  const isNatural = useMemo(
    () => (addNewCustomer ? chooseCustomerType : customerData?.customerType) === 'natural',
    // eslint-disable-next-line
    [chooseCustomerType, customerData],
  );
  const isLegal = useMemo(
    () => (addNewCustomer ? chooseCustomerType : customerData?.customerType) === 'legal',
    // eslint-disable-next-line
    [chooseCustomerType, customerData],
  );

  const [principalUsers, setPrincipalUsers] = useState([]);

  useEffect(() => {
    if (customerData) {
      setPrincipalUsers(customerData?.principalUsers?.map(item => item.principalUser) || []);
    }
  }, [customerData]);

  const handleSubmit = async e => {
    e.preventDefault();
    const obj = {};
    for (const [k, v] of new FormData(e.target)) {
      //k, v as key and value

      if (k === 'marriage' || k === 'gender') {
        obj[k] = v === 'true' ? true : v === 'false' ? false : null;
        continue;
      }

      if (k === 'tags') {
        obj[k] = v ? JSON.parse(`[${v}]`).map(e => e.tagName) : [];
        continue;
      }

      //workingHourFrom, workingMinFrom, workingHourTo, workingMinTo...etc
      if (k === 'working_From' || k === 'working_To' || k === 'rest_From' || k === 'rest_To') {
        const [hour, min] = v.split(':');
        obj[k.replace('_', 'Hour')] = hour;
        obj[k.replace('_', 'Min')] = min;
        continue;
      }

      // 編輯暫無來源
      // if (k === "sources") {
      //   obj[k] = v ? JSON.parse(`[${v}]`).map(e => e.id) : []
      //   continue
      // }

      obj[k] = v;
    }
    obj.principalUsers = principalUsers.map((user, index) => ({
      username: user.username,
      seq: index,
    }));

    if (isLegal) {
      obj.contactPersons = contactFormRef.current.getContactPersons();
    }

    if (addNewCustomer) {
      const { custType, ...restData } = obj;
      handleAsync(createCustomer.bind(null, { custType, customer: { ...restData } }), mutate.bind(null, null));
      return;
    }

    handleAsync(updateCustomer.bind(null, customerId, obj), mutate.bind(null, null));
  };

  const handleDelete = () => {
    const validateString = prompt('請輸入「確認刪除」來確認刪除。');

    if (validateString === '確認刪除') {
      handleAsync(deleteCustomer.bind(null, customerId), mutate.bind(null, null), toggle);
    }
  };

  return (
    <>
      {button ? (
        cloneElement(button, { onClick: toggle })
      ) : (
        <Button variant="contained" color="info" size="large" onClick={toggle}>
          {addNewCustomer ? '新增客戶' : '編輯客戶資料'}
        </Button>
      )}
      <FullScreenDialog
        open={state}
        onClose={toggle}
        onConfirm={toggle}
        // button={button}
        formId={formId}
        isConfirmAsSubmit
        isLoading={isLoading && !addNewCustomer}
        title={button ? button.props.children : addNewCustomer ? '新增客戶' : '編輯客戶資料'}
      >
        {(customerData || addNewCustomer) && (
          <Stack
            component="form"
            id={formId}
            onSubmit={handleSubmit}
            sx={{
              padding: '36px 30px 16rem',
              gap: 4,
            }}
          >
            <FormContainer title="基本資訊">
              {addNewCustomer && (
                <>
                  <TextField
                    name="custType"
                    label="客戶類別"
                    sx={{ mb: 2 }}
                    select
                    fullWidth
                    value={chooseCustomerType}
                    onChange={e => setChooseCustomerType(e.target.value)}
                    required
                  >
                    <MenuItem value="natural">一般客戶</MenuItem>
                    <MenuItem value="legal">法人(公司行號)</MenuItem>
                  </TextField>
                  <div></div>
                </>
              )}
              <SizedInput name="name" label="名稱" defaultValue={addNewCustomer ? '' : customerData.name} required />
              {isNatural && (
                <FormControl sx={{ ml: 2.5, width: '520px', mb: '11px' }}>
                  <InputLabel>性別</InputLabel>
                  <Select label="性別" name="gender" defaultValue={addNewCustomer ? '' : customerData.gender}>
                    <MenuItem value="">清除</MenuItem>
                    <MenuItem value={true}>男</MenuItem>
                    <MenuItem value={false}>女</MenuItem>
                  </Select>
                </FormControl>
              )}
              {isNatural && <SizedInput label="電話" required name="phone" defaultValue={addNewCustomer ? '' : customerData.phone} />}
              {isNatural && (
                <SizedInput label="生日" type="date" name="birthday" defaultValue={addNewCustomer ? '' : customerData.birthday} />
              )}
              {isLegal && (
                <SizedInput
                  label="公司統編"
                  name="taxIdNo"
                  defaultValue={addNewCustomer ? '' : customerData.taxIdNo}
                  required
                  inputProps={{
                    maxLength: 8,
                  }}
                />
              )}

              <SizedInput label="信箱" name="email" defaultValue={addNewCustomer ? '' : customerData.email} />
              <div style={{ visibility: 'none', width: '520px' }} />
              <MultiSelect
                sx={{ width: '520px', mr: 2.5 }}
                label="客戶標籤(多選)"
                defaultValue={addNewCustomer ? [] : customerData?.tags ? customerData.tags.map(tag => JSON.stringify(tag)) : []}
                name="tags"
                displayKey="tagName"
                data={tagsData?.list}
              />
              <FormControl sx={{ ml: 2.5, width: '520px', mb: '11px' }}>
                <InputLabel>客戶等級</InputLabel>
                <Select label="客戶等級" name="grade" defaultValue={addNewCustomer ? '' : customerData.grade}>
                  <MenuItem value="">清除</MenuItem>
                  <MenuItem value="normal">一般</MenuItem>
                  <MenuItem value="VIP">VIP</MenuItem>
                </Select>
              </FormControl>
              {/* <MultiSelect
                disabled
                sx={{ width: "520px", ml: 2.5 }}
                label="客戶來源"
                defaultValue={addNewCustomer ?"":
                  customerData?.sources
                    ? customerData.sources.map(source => JSON.stringify(source))
                    : []
                }
                name="sources"
                displayKey="sourceName"
                data={sourcesData?.list}
              /> */}

              <SizedStack>
                <Typography mb={1} mt={1.5}>
                  {isNatural ? '家用(室內)電話' : '公司電話'}
                </Typography>
                <Stack direction="row">
                  <TextField label="區碼" name="localAreaCode" defaultValue={addNewCustomer ? '' : customerData.localAreaCode} />
                  <TextField label="電話" name="localPhone" defaultValue={addNewCustomer ? '' : customerData.localPhone} />
                  <TextField
                    label="分機"
                    InputProps={{ startAdornment: '#' }}
                    name="localExt"
                    defaultValue={addNewCustomer ? '' : customerData.localExt}
                  />
                </Stack>
              </SizedStack>
              {isNatural && (
                <FormControl
                  sx={{
                    ml: 2.5,
                    width: '520px',
                    alignSelf: 'flex-end',
                    mb: '11px',
                  }}
                >
                  <InputLabel>婚姻狀態</InputLabel>
                  <Select label="婚姻狀態" name="marriage" defaultValue={addNewCustomer ? '' : customerData.marriage}>
                    <MenuItem value="">清除</MenuItem>
                    <MenuItem value={true}>已婚</MenuItem>
                    <MenuItem value={false}>未婚</MenuItem>
                  </Select>
                </FormControl>
              )}
              {isLegal && (
                <>
                  <div style={{ visibility: 'none', width: '520px' }} />
                  <SizedStack>
                    <Typography>上班時間</Typography>
                    <TimeSection mt={1}>
                      <TextField
                        label="開始時間"
                        fullWidth
                        type="time"
                        name="working_From"
                        defaultValue={addNewCustomer ? '' : `${customerData.workingHourFrom}:${customerData.workingMinFrom}`}
                      />
                      <TextField
                        label="結束時間"
                        fullWidth
                        type="time"
                        name="working_To"
                        defaultValue={addNewCustomer ? '' : `${customerData.workingHourTo}:${customerData.workingMinTo}`}
                      />
                    </TimeSection>
                  </SizedStack>
                  <SizedStack>
                    <Typography>休息時間</Typography>
                    <TimeSection mt={1}>
                      <TextField
                        label="開始時間"
                        fullWidth
                        type="time"
                        name="rest_From"
                        defaultValue={addNewCustomer ? '' : `${customerData.restHourFrom}:${customerData.restMinFrom}`}
                      />
                      <TextField
                        label="結束時間"
                        fullWidth
                        type="time"
                        name="rest_To"
                        defaultValue={addNewCustomer ? '' : `${customerData.restHourTo}:${customerData.restMinTo}`}
                      />
                    </TimeSection>
                  </SizedStack>
                </>
              )}

              <TextField
                label="備註"
                multiline
                rows={5}
                fullWidth
                name="description"
                defaultValue={addNewCustomer ? '' : customerData.description}
              />
            </FormContainer>
            <FormContainer title="負責窗口">
              <PrincipleUsersForm value={principalUsers} onChange={setPrincipalUsers} />
            </FormContainer>
            <FormContainer title="地址">
              <AddressForm data={customerData} addNewCustomer={addNewCustomer} />
            </FormContainer>
            {isNatural && (
              <FormContainer title="公司資訊">
                <SizedInput label="公司名稱" name="companyName" defaultValue={addNewCustomer ? '' : customerData.companyName} />
                <SizedInput label="公司統編" name="taxIdNo" defaultValue={addNewCustomer ? '' : customerData.taxIdNo} />
                <SizedInput label="公司部門" name="department" defaultValue={addNewCustomer ? '' : customerData.department} />
                <SizedInput label="職稱" name="jobTitle" defaultValue={addNewCustomer ? '' : customerData.jobTitle} />
              </FormContainer>
            )}
            {isLegal && (
              <FormContainer title="聯絡人">
                <ContactPersonForm ref={contactFormRef} data={addNewCustomer ? [] : customerData?.contactPersons} addNewCustomer />
              </FormContainer>
            )}

            {!addNewCustomer && (
              <Button sx={{ mx: 'auto', mt: 5 }} color="error" variant="contained" onClick={handleDelete}>
                刪除客戶資料
              </Button>
            )}
          </Stack>
        )}
      </FullScreenDialog>
    </>
  );
}

import { cloneElement } from 'react';
import useToggle from 'libs/hooks/useToggle';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

export default function ConfirmDialog(props) {
  const { state, toggle } = useToggle();
  return (
    <>
      {cloneElement(props.button, { onClick: toggle })}
      <Dialog open={state} onClose={toggle} maxWidth="sm" fullWidth>
        <DialogTitle>確認操作？</DialogTitle>
        {props.children && <DialogContent>{props.children}</DialogContent>}
        <DialogActions>
          <Button onClick={props.onConfirm} variant="outlined">
            確認
          </Button>
          <Button onClick={toggle} color="error">
            取消
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

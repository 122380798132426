import { cloneElement, useEffect } from 'react';
import dayjs from 'dayjs';
import { Divider, Stack, MenuItem, Button } from '@mui/material';
import { useProjectService } from 'apis/project';
import { useCampaignService } from 'apis/campaign';
import Formatter from 'libs/fomatter';
import useToggle from 'libs/hooks/useToggle';
import useAsync from 'libs/hooks/useAsync';
import { ConfirmDialog, FullScreenDialog } from 'components/Dialog';
import Toolbar from './Toolbar';
import { Container } from './style';
import ProjectInformation from './ProjectInformation';
import ProjectStatusBar from 'components/ProjectStatusBar';
import { SearchSelect } from 'components/SearchForm';
import { visitTypeMap, visitTypeDisplayName } from 'constant/visitType';
import RecordPanel from './RecordPanel';
import { useSystemInfoService } from 'apis/systemInfo';

export default function RecordDialog({ button, customerId, projectId, onDelete, isOpen, onClose }) {
  const { state, toggle } = useToggle();
  const handleAsync = useAsync();
  const { useOneSystemInfo } = useSystemInfoService();
  const { useOneProject } = useProjectService();
  const { useOneCampaign, updateCampaignContactStatus, updateCampaignProjectStatus } = useCampaignService();
  const { data: progressBarInfo } = useOneSystemInfo('progressBar');
  const { data: campaignData, isLoading, mutate } = useOneCampaign({ customerId, projectId }, null, state);
  const { data: projectData, isLoading: isProjectDataLoading } = useOneProject(projectId, null, state);

  const handleUpdateContactStatus = status => {
    handleAsync(updateCampaignContactStatus.bind(null, { projectId, customerId, status }), () => mutate());
  };

  const handleUpdateProjectStatus = event => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const key = visitTypeMap[formData.get('projectStatus')];
    const value = dayjs().toISOString();
    handleAsync(
      updateCampaignProjectStatus.bind(null, {
        projectId,
        customerId,
        [key]: value,
      }),
      () => mutate(),
    );
  };

  const handleDelete = () => {
    handleAsync(
      onDelete.bind(null, projectId),
      () => toggle(),
      () => mutate(),
    );
  };

  const handleClose = () => {
    toggle.toFalse();

    onClose?.();
  };

  useEffect(() => {
    if (isOpen) {
      toggle.toTrue();
    } else {
      toggle.toFalse();
    }
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <>
      {button && cloneElement(button, { onClick: toggle })}
      <FullScreenDialog
        open={state}
        onClose={handleClose}
        isLoading={isLoading || isProjectDataLoading}
        title={
          campaignData
            ? `${Formatter.combineNameWithJobTitle(
                campaignData.customer.companyName,
                campaignData.customer.department,
                campaignData.customer.jobTitle,
                campaignData.customer.name,
              )} | ${campaignData.project.projectName}專案 | ${campaignData.customer.phone ?? '--'} | ${Formatter.fullPhone(
                campaignData.customer.localAreaCode,
                campaignData.customer.localPhone,
                campaignData.customer.localExt,
              )}`
            : ''
        }
        toolbar={
          campaignData && (
            <Toolbar
              customerId={customerId}
              status={campaignData.status}
              statusAt={campaignData.statusAt}
              onUpdate={handleUpdateContactStatus}
            />
          )
        }
      >
        <Container sx={{ overflow: 'hidden' }}>
          <Stack>
            <RecordPanel projectId={projectId} customerId={customerId} />
          </Stack>

          <Stack
            p={2}
            spacing={1}
            sx={{
              borderLeft: '1px solid',
              borderLeftColor: 'divider',
              overflow: 'auto',
            }}
          >
            {onDelete && (
              <ConfirmDialog
                onConfirm={handleDelete}
                button={
                  <Button variant="contained" color="error">
                    移除專案
                  </Button>
                }
              />
            )}
            {campaignData && (
              <Stack spacing={1}>
                <ProjectStatusBar {...campaignData} barProps={{ position: 'relative' }} borderRadius="5px" />
                <form onSubmit={handleUpdateProjectStatus}>
                  <Stack direction="row" justifyContent="space-between" sx={{ '& > *': { flex: 1 } }} spacing={1}>
                    <SearchSelect label="約訪進度紀錄" name="projectStatus" required>
                      <MenuItem value="" disabled>
                        約訪進度紀錄
                      </MenuItem>
                      {Object.entries(visitTypeDisplayName).map(([key, value]) => (
                        <MenuItem key={key} value={key}>
                          {progressBarInfo?.details?.[key]?.customName || value}
                        </MenuItem>
                      ))}
                    </SearchSelect>
                    <Button variant="contained" color="warning" type="submit">
                      更新進度時間
                    </Button>
                  </Stack>
                </form>
              </Stack>
            )}
            <Divider />
            {projectData && <ProjectInformation data={projectData} />}
          </Stack>
        </Container>
      </FullScreenDialog>
    </>
  );
}

import dayjs from 'dayjs';
import { Chip, Stack, Typography, Divider, Accordion, AccordionSummary, AccordionDetails, LinearProgress } from '@mui/material';
import Icon from 'components/Icon';
import { TooltipIconButton } from 'components/Button';
import { InformationLabel } from 'components/Label';
import RecordSheetDialog from 'components/RecordSheet/Dialog/RecordSheetDialog';
import EditRecordDialog from '../../EditRecordDialog';
import { ConfirmDialog } from 'components/Dialog';
import { visitTypeDisplayName } from 'constant/visitType';
import { useCampaignVisitService } from 'apis/campaign';
import useToggle from 'libs/hooks/useToggle';
import Brand from 'components/Brand';

export default function HistoryRecordItem({ visitRecordId, visitData, onEdit, onDelete }) {
  const { state, toggle } = useToggle();
  const { useOneCampaignVisit } = useCampaignVisitService();
  const { data, isLoading, mutate } = useOneCampaignVisit(visitRecordId, null, state);

  return (
    <Accordion expanded={state} onChange={toggle}>
      <AccordionSummary expandIcon={<Icon name="expand" />}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
          <InformationLabel type="time" label={dayjs(visitData.visitedAt).format('YYYY/MM/DD HH:mm:ss')} />
          <Stack direction="row" spacing={1}>
            <InformationLabel type="ownerUser" label={visitData.visitedUser.displayName} />
            <Chip size="small" icon={<Icon name="label" />} label={visitTypeDisplayName[visitData.visitType]} color="success" />
            <InformationLabel type="phone" label={visitData.visitedResult} />
          </Stack>
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ backgroundColor: '#E5E5E550' }}>
        {isLoading && <LinearProgress />}
        {data && (
          <>
            <Stack direction="row" alignItems="center">
              <Typography variant="body2" sx={{ flex: 1 }}>
                紀錄內容
              </Typography>
              <RecordSheetDialog
                projectId={data.projectId}
                customerId={data.customerId}
                userName={data.visitedUser.displayName}
                recordTime={dayjs(data.visitedAt).format('YYYY/MM/DD HH:mm:ss')}
                description={data.Description}
                button={<TooltipIconButton title="列印約訪單" iconName="print" />}
              />
              <EditRecordDialog
                onUpdate={description => {
                  onEdit(data.id, description);
                  mutate(null);
                }}
                description={data.Description}
                button={<TooltipIconButton title="編輯" iconName="edit" />}
              />
              <ConfirmDialog
                onConfirm={onDelete.bind(null, data.id)}
                button={<TooltipIconButton title="刪除" iconName="delete" color="error" />}
              >
                確定要刪除此筆紀錄？
              </ConfirmDialog>
            </Stack>
            <Divider />
            <Typography sx={{ whiteSpace: 'pre-wrap', pt: 1 }}>{data.Description}</Typography>
            {data.task && (
              <>
                <Divider sx={{ my: 1 }} />

                <Stack direction="row" alignItems="center" spacing={1} justifyContent="flex-start">
                  <Brand>
                    <Typography variant="body2">提醒</Typography>
                  </Brand>
                  <Typography variant="body2" flex="1">
                    {dayjs(data.task.remindAt).format('YYYY-MM-DD HH:mm')}
                  </Typography>
                  <Typography variant="body2" flex="1">
                    {data.task.topic}
                  </Typography>
                  <Typography variant="body2" flex="4">
                    {data.task.description}
                  </Typography>
                </Stack>
              </>
            )}
          </>
        )}
      </AccordionDetails>
    </Accordion>
  );
}

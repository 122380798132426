import useToggle from 'libs/hooks/useToggle';
import { useCustomerTagService } from 'apis/customer';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import CustomerTagForm from 'components/maintain/CustomerTagForm';

export default function CustomerTagFormDialog({ ButtonComponent, tag, onUpdate }) {
  const { state, toggle } = useToggle();
  const { useOneCustomerTag } = useCustomerTagService();
  const { data: oneCustomerTagData } = useOneCustomerTag(tag.id, null, state);

  return (
    <>
      <ButtonComponent onClick={toggle} />
      <Dialog open={state} onClose={toggle.toFalse} maxWidth="lg" fullWidth>
        <DialogTitle>編輯</DialogTitle>
        <DialogContent>
          {oneCustomerTagData && <CustomerTagForm mode="update" defaultValue={oneCustomerTagData} onUpdate={onUpdate} />}
        </DialogContent>
      </Dialog>
    </>
  );
}

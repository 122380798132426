export const dataFieldsMap = {
  'customer.companyName': {
    key: 'customer.companyName',
    displayName: '服務機關名稱',
  },
  // "customer.gender": {
  //   key: "customer.gender",
  //   displayName: "性別",
  //   opt: {
  //     true: "男性",
  //     false: "女性",
  //   },
  // },
};

export const dataFieldsList = Object.values(dataFieldsMap);

export const dataGridColumn = [
  {
    field: 'fields',
    headerName: '篩選條件',
    width: 400,
  },
  {
    field: 'totalCount',
    headerName: '總數量',
  },
  {
    field: 'assignedCount',
    headerName: '已分配',
  },
  {
    field: 'notAssignedCount',
    headerName: '未分配',
  },
];

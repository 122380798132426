import PropTypes from 'prop-types';
import { Stack, Typography, Accordion, AccordionSummary, AccordionDetails, Paper } from '@mui/material';
import Icon from 'components/Icon';

export default function ProjectInformation(props) {
  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
        <Typography variant="h4">{props.data.projectName} 專案資訊</Typography>
        <Typography>
          {props.data.startDate} ~ {props.data.endDate}
        </Typography>
      </Stack>
      <div>
        <Accordion
          sx={{ overflow: 'hidden' }}
          defaultExpanded={Boolean(props.data.description)}
          disabled={!Boolean(props.data.description)}
        >
          <AccordionSummary expandIcon={<Icon name="expand" />} sx={{ position: 'sticky', top: '-24px', background: 'white' }}>
            <Typography>{!Boolean(props.data.description) && '無'}專案說明</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ maxHeight: '350px', overflow: 'auto', whiteSpace: 'pre-wrap' }}>
            <Typography>{props.data.description}</Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ overflow: 'hidden' }} disabled={!Boolean(props.data.products)}>
          <AccordionSummary expandIcon={<Icon name="expand" />} sx={{ position: 'sticky', top: '-24px', background: 'white' }}>
            <Typography>{!Boolean(props.data.products) && '無'}商品資訊</Typography>
          </AccordionSummary>
          <AccordionDetails
            sx={{
              maxHeight: '550px',
              overflow: 'auto',
              backgroundColor: '#E5E5E5',
            }}
          >
            <Stack spacing={1}>
              {props.data.products &&
                props.data.products.map(product => (
                  <Paper sx={{ p: 1 }} key={product.id}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      sx={{
                        ...(product.description && {
                          borderBottom: '1px solid',
                          borderBottomColor: 'divider',
                          pb: 1,
                          mb: 1,
                        }),
                      }}
                    >
                      <Stack>
                        <Typography variant="h4">{product.productName}</Typography>
                        <Typography>產品代碼：{product.productCode}</Typography>
                      </Stack>
                      <Typography>$ {product.price}</Typography>
                    </Stack>
                    <Typography>{product.description}</Typography>
                  </Paper>
                ))}
            </Stack>
          </AccordionDetails>
        </Accordion>
        <Accordion sx={{ overflow: 'hidden' }} disabled={!Boolean(props.data.faqs)}>
          <AccordionSummary expandIcon={<Icon name="expand" />} sx={{ position: 'sticky', top: '-24px', background: 'white' }}>
            <Typography>{!Boolean(props.data.faqs) && '無'}常見問答</Typography>
          </AccordionSummary>
          <AccordionDetails sx={{ maxHeight: '550px', overflow: 'auto', backgroundColor: '#E5E5E5' }}>
            <Stack>
              {props.data.faqs &&
                props.data.faqs.map(faq => (
                  <Accordion defaultExpanded sx={{ overflow: 'hidden' }} key={faq.id}>
                    <AccordionSummary expandIcon={<Icon name="expand" />} sx={{ position: 'sticky', top: '-24px', background: 'white' }}>
                      <Typography fontWeight="bolder">{faq.question}</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{ maxHeight: '350px', overflow: 'auto', whiteSpace: 'pre-wrap' }}>
                      <Typography>{faq.answer}</Typography>
                    </AccordionDetails>
                  </Accordion>
                ))}
            </Stack>
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
}

ProjectInformation.propTypes = {
  data: PropTypes.object.isRequired,
};

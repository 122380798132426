import PropTypes from 'prop-types';
import { useState } from 'react';
import { MenuItem, Box, Chip } from '@mui/material';

import { useProductService } from 'apis/product';
import { useProjectService } from 'apis/project';
import { SearchInput, SearchSelect, SearchButtonGroup } from 'components/SearchForm';

export default function SearchForm(props) {
  const { useAllProject } = useProjectService();
  const { useAllProduct } = useProductService();

  const { data: projectsData } = useAllProject({
    isParent: true,
    limit: 'zero',
  });
  const { data: productsData } = useAllProduct({ limit: 'zero' });

  const [select, setSelect] = useState({
    isParent: '',
    isEnable: true,
    productIds: [],
    parentProjectIds: [],
  });

  const handleChange = evt => {
    const { name, value } = evt.target;
    setSelect({ ...select, [name]: value });
  };

  const handleClear = () => {
    props.onClearSearch();
    setSelect({
      isParent: '',
      isEnable: '',
      productIds: [],
      parentProjectIds: [],
    });
  };

  return (
    <>
      <SearchInput label="專案名稱" name="projectName" />
      <SearchInput label="專案日期" type="date" name="projectDate" hide />
      <SearchInput label="專案開始日期" type="date" name="projectStartDate" hide />
      <SearchInput label="專案結束日期" type="date" name="projectEndDate" hide />

      <SearchSelect
        width="49.25%"
        label="母專案(複選)"
        name="parentProjectIds"
        value={select.parentProjectIds}
        onChange={handleChange}
        onClear={() => setSelect(prev => ({ ...prev, parentProjectIds: [] }))}
        multiple
        renderValue={selected => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map(value => (
              <Chip key={value} label={projectsData.list.find(tag => tag.id === value).projectName} />
            ))}
          </Box>
        )}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: '400px',
            },
          },
        }}
      >
        {projectsData &&
          projectsData.list.map(project => (
            <MenuItem key={project.id} value={project.id}>
              {project.projectName}
            </MenuItem>
          ))}
      </SearchSelect>
      <SearchSelect
        multiple
        width="49.25%"
        label="關聯商品(複選)"
        name="productIds"
        value={select.productIds}
        onClear={() => setSelect(prev => ({ ...prev, productIds: [] }))}
        onChange={handleChange}
        sx={{ height: 'auto!important' }}
        renderValue={selected => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map(value => (
              <Chip key={value} label={productsData.list.find(product => product.id === value).productName} />
            ))}
          </Box>
        )}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: '400px',
            },
          },
        }}
      >
        {productsData &&
          productsData.list.map(product => (
            <MenuItem key={product.id} value={product.id}>
              {product.productName}
            </MenuItem>
          ))}
      </SearchSelect>
      <SearchSelect label="專案性質" name="isParent" value={select.isParent} onChange={handleChange}>
        <MenuItem value="">清除</MenuItem>
        <MenuItem value={true}>只顯示母專案</MenuItem>
        <MenuItem value={false}>只顯示非母專案</MenuItem>
      </SearchSelect>
      <SearchSelect label="啟用狀態" name="isEnable" value={select.isEnable} onChange={handleChange}>
        <MenuItem value="">清除</MenuItem>
        <MenuItem value={true}>只顯示啟用中</MenuItem>
        <MenuItem value={false}>只顯示未啟用</MenuItem>
      </SearchSelect>
      <SearchButtonGroup formId={props.formId} onClearSearch={handleClear} />
    </>
  );
}

SearchForm.propTypes = {
  formId: PropTypes.string.isRequired,
  onClearSearch: PropTypes.func.isRequired,
};

import { cloneElement, useId, useState, useEffect, useTransition, useRef } from 'react';
import { has } from 'lodash';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import { Stack, Autocomplete, Checkbox, TextField, FormControlLabel, Switch } from '@mui/material';

import useToggle from 'libs/hooks/useToggle';
import useAsync from 'libs/hooks/useAsync';
import { FormContainer } from 'components/Container';
import { FullScreenDialog } from 'components/Dialog';
import { SizedInput } from 'components/Input';
import { useProjectService } from 'apis/project';
import { useProductService } from 'apis/product';
import Icon from 'components/Icon';
import ProjectProperty from './ProjectProperty';
import ProjectFaqForm from './ProjectFaqForm';

export default function ProjectFormDialog({ button, projectId, addNewProject }) {
  const formId = useId();
  const projectFaqRef = useRef();
  const { state, toggle } = useToggle();
  const handleAsync = useAsync();
  const { useAllProduct } = useProductService();
  const { useOneProject, updateProject, addProject } = useProjectService();

  const { data: projectData, isLoading, mutate } = useOneProject(projectId, null, state && !addNewProject);

  const [products, setProducts] = useState([]);
  const [productQuery, setProductQuery] = useState('');
  const { data: productsData } = useAllProduct({ limit: 10, productName: productQuery });

  const [projectProperty, setProjectProperty] = useState({});
  // eslint-disable-next-line
  const [_, startTransition] = useTransition();

  useEffect(() => {
    if (projectData) {
      if (projectData?.parentProject) {
        setProjectProperty({ parentProject: projectData.parentProject });
      }
      setProducts(projectData.products || []);
    }
  }, [projectData]);

  useEffect(() => {
    return () => {
      setProducts([]);
      setProductQuery('');
      setProjectProperty({});
    };
  }, [state]);

  const handlePropertyChange = data => {
    startTransition(() => {
      setProjectProperty(data);

      if (data?.parentProject) {
        setProducts(data?.parentProject?.products || []);
      }
    });
  };

  const handleSubmit = evt => {
    evt.preventDefault();

    const formData = new FormData(evt.target);
    const form = {};
    for (const [k, v] of formData) {
      form[k] = v;
    }
    if (has(form, 'isEnable')) {
      form.isEnable = true;
    } else {
      form.isEnable = false;
    }

    handleAsync(
      async () => {
        if (dayjs(form.startDate).isAfter(dayjs(form.endDate))) {
          throw new Error('開始日期 不可晚於 結束日期');
        }

        if (projectProperty?.parentProject) {
          const { startDate, endDate } = projectProperty?.parentProject;
          dayjs.extend(isBetween);
          const isStartDateValid = dayjs(form.startDate).isBetween(startDate, endDate, null, '[]');
          const isEndDateValid = dayjs(form.endDate).isBetween(startDate, endDate, null, '[]');

          if (!(isStartDateValid && isEndDateValid)) {
            throw new Error('子專案時間不得大於母專案');
          }
        }

        const payload = {
          ...form,
          products: products.map((p, i) => ({
            productId: p.id,
            seq: i + 1,
            description: p.description,
          })),
          faqs: projectFaqRef.current.getFaqs(),
        };

        if (addNewProject) {
          payload.isParent = projectProperty?.isParent ? true : false;
          payload.parentProjectId = projectProperty?.parentProject?.id || null;

          await addProject(payload);
          return;
        }

        await updateProject(projectId, payload);
      },
      () => mutate(),
    );
  };

  const handleClear = () => {
    setProducts(projectData?.products || []);
  };

  return (
    <>
      {cloneElement(button, { onClick: toggle })}
      <FullScreenDialog
        open={state}
        onClose={toggle}
        onConfirm={toggle}
        onClear={handleClear}
        formId={formId}
        isConfirmAsSubmit
        isLoading={isLoading && !addNewProject}
        title={button ? button.props.children : addNewProject ? '建立新專案' : '編輯專案資料'}
      >
        {(projectData || addNewProject) && (
          <Stack
            component="form"
            id={formId}
            onSubmit={handleSubmit}
            sx={{
              padding: '36px 30px 16rem',
              gap: 4,
            }}
          >
            <FormContainer title="專案性質設定">
              <ProjectProperty onChange={handlePropertyChange} addNewProject={addNewProject} projectData={projectData} />
            </FormContainer>
            <FormContainer title="專案資訊" key={`${projectProperty?.parentProject ? 'child_form_basic_info' : 'regular_form_basic_info'}`}>
              {!addNewProject && (
                <FormControlLabel
                  control={<Switch name="isEnable" inputProps={{ name: 'isEnable' }} defaultChecked={projectData.isEnable} />}
                  label="啟用專案"
                  sx={{ position: 'absolute', top: 15, right: 10 }}
                />
              )}
              <SizedInput
                name="projectName"
                label="專案名稱"
                sx={{ width: '100%' }}
                defaultValue={addNewProject ? projectProperty?.parentProject?.projectName || '' : projectData.projectName}
                required
                noAutoMargin
              />
              <div style={{ display: 'none' }} />
              <SizedInput
                name="startDate"
                label="開始日期"
                type="date"
                defaultValue={addNewProject ? projectProperty?.parentProject?.startDate || '' : projectData.startDate}
                required
              />
              <SizedInput
                name="endDate"
                label="結束日期"
                type="date"
                defaultValue={addNewProject ? projectProperty?.parentProject?.endDate || '' : projectData.endDate}
                required
              />
              <SizedInput
                name="description"
                label="專案敘述"
                sx={{ width: '100%' }}
                multiline
                rows={8}
                defaultValue={addNewProject ? projectProperty?.parentProject?.description || '' : projectData.description}
                noAutoMargin
              />

              <Autocomplete
                fullWidth
                multiple
                value={products}
                onChange={(e, n) => setProducts(n)}
                inputValue={productQuery}
                onInputChange={(e, n) => setProductQuery(n)}
                options={productsData ? productsData.list : []}
                isOptionEqualToValue={(opt, val) => opt.id === val.id}
                disableCloseOnSelect
                getOptionLabel={option => option.productName}
                renderOption={(props, option, { selected }) => (
                  <li {...props}>
                    <Checkbox
                      icon={<Icon name="outlineCheckBox" fontSize="small" />}
                      checkedIcon={<Icon name="checkBox" fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {option.productName}
                  </li>
                )}
                renderInput={params => <TextField {...params} label="關聯商品(複選)" placeholder="點此新增/搜尋商品" />}
              />
            </FormContainer>
            <FormContainer title="專案FAQ" key={`${projectProperty?.parentProject ? 'child_form_faqs' : 'regular_form_faqs'}`}>
              <ProjectFaqForm data={projectData || projectProperty?.parentProject} ref={projectFaqRef} />
            </FormContainer>
          </Stack>
        )}
      </FullScreenDialog>
    </>
  );
}

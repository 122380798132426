import PropTypes from 'prop-types';
import { styled } from '@mui/system';
import { Button } from '@mui/material';

import Icon from 'components/Icon';

const StyledButton = styled(p => <Button variant="outlined" {...p} />)(({ theme }) => ({
  borderRadius: 4,
  backgroundColor: 'white',
  width: 'max-content',
  paddingLeft: '29px',
  paddingRight: '29px',
  height: 36,
  ...theme.typography.body2,
}));

export default function ActionButton(props) {
  const { iconName, ...restProps } = props;
  return <StyledButton {...(iconName && { startIcon: <Icon name={iconName} /> })} {...restProps} />;
}

ActionButton.propTypes = {
  iconName: PropTypes.string,
};

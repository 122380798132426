import { styled } from '@mui/system';
import { Box } from '@mui/material';

const Container = styled(Box, { shouldForwardProp: prop => prop !== 'color' })(({ theme, color }) => ({
  backgroundColor: color ? theme.palette[color].main : theme.palette.info.main,
  width: '100%',
  padding: '27px 0',
  marginBottom: '12px',
  zIndex: 2,
}));

const SearchContainer = ({ color, ...props }) => {
  return <Container color={color} {...props} />;
};

export default SearchContainer;

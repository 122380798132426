import StyledSelect from './selectStyle';
import SearchSelectContainer from './SearchSelectContainer';
import { IconButton, InputLabel } from '@mui/material';
import Icon from 'components/Icon';
export default function SearchSelect({ label, width, required, onClear, fullWidth, ...props }) {
  return (
    <SearchSelectContainer width={width} required={required} fullWidth={fullWidth}>
      <InputLabel sx={{ top: -2 }}>{label}</InputLabel>
      <StyledSelect
        {...props}
        endAdornment={
          props.multiple &&
          props.value.length !== 0 &&
          onClear && (
            <IconButton onClick={() => onClear()} sx={{ mr: 2 }}>
              <Icon name="close" sx={{ fontSize: '22px' }} />
            </IconButton>
          )
        }
      />
    </SearchSelectContainer>
  );
}

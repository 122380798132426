import { useContext } from 'react';
import { AuthContext } from 'components/context/auth';
import { useGetFetch, postFetch, putFetch } from 'apis/fetcher';
import toQueryString from 'libs/api/toQueryString';
import URLs from 'constant/urls';

/* record of customer's project */
/* visitType 
    0: 介紹 intro
    1: 報價 quotation
    2: 協商 negotiation
    3: 簽約 contract
    4: 結案 close
*/
export const useProjectService = () => {
  const serviceURL = URLs.project;
  const { token } = useContext(AuthContext);

  return {
    useAllProject: (queryOptions, swrOptions, ableToFetch = true) => {
      const query = toQueryString({ ...queryOptions });
      const fetchURL = ableToFetch ? serviceURL + query : null;
      return useGetFetch(fetchURL, swrOptions);
    },
    useOneProject: (projectId, swrOptions, ableToFetch = true) => {
      const fetchURL = projectId && (ableToFetch ? `${serviceURL}/${projectId}` : null);
      return useGetFetch(fetchURL, swrOptions);
    },
    addProject: async data => {
      return await postFetch(serviceURL, { data, token });
    },
    updateProject: async (projectId, data) => {
      return await putFetch(`${serviceURL}/${projectId}`, { data, token });
    },
  };
};

import { styled } from '@mui/system';
import { Box, Accordion, Tabs } from '@mui/material';
export const StyledAccordion = styled(Accordion)({
  boxShadow: 'none',
  '& .MuiAccordionSummary-content': {
    height: 50,
    margin: 0,
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  '& .MuiAccordionDetails-root': {
    paddingLeft: 0,
    paddingRight: 0,
    backgroundColor: '#FAFAFA',
    height: 150,
    display: 'flex',
  },
});

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  backgroundColor: 'white',
  width: 110,
  height: 120,
  '& .MuiTab-root': {
    width: '100%',
    height: '30px!important',
    padding: 8,
    minHeight: 0,
    fontSize: 16,
    color: '#000000AA',
    fontWeight: 700,
    '&.Mui-selected': {
      color: theme.palette.warning.main,
    },
  },
  '& .MuiTabs-indicator': {
    backgroundColor: theme.palette.warning.main,
  },
}));

export const StyledContent = styled(Box)({
  width: 'calc(100% - 120px)',
  margin: '0 20px',
  background: 'white',
  boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',
  padding: 10,
});

import { cloneElement, useEffect, useId, useState, useMemo, useRef } from 'react';
import { size, isEmpty } from 'lodash';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogContentText,
  DialogActions,
  Button,
  Stack,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Tooltip,
  ListItemText,
  Typography,
} from '@mui/material';

import { useProjectService } from 'apis/project';
import { useUserService } from 'apis/user';
import { useBatchService } from 'apis/batch';
import useToggle from 'libs/hooks/useToggle';
import useAsync from 'libs/hooks/useAsync';
import generateBatchCode from 'libs/generateBatchCode';
import DistributeTable from './DistributeTable';

export default function PoolDialog({ button, query, totalCount }) {
  const { useAllProject } = useProjectService();
  const { useAllUser } = useUserService();

  const tableRef = useRef();
  const formId = useId();
  const { state, toggle } = useToggle();
  const { state: isAutoAssign, toggle: toggleAutoAssign } = useToggle(true);
  const handleAsync = useAsync();

  const [chooseUsers, setChooseUsers] = useState([]);

  const { data: projectsData } = useAllProject({
    limit: 'zero',
    isEnable: true,
  });

  const { data: usersData } = useAllUser();
  const { addFromCustomerPool } = useBatchService();

  //pool則是查看query數量
  const statCount = useMemo(() => {
    return [totalCount, 0];
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    return () => {
      setChooseUsers([]);
    };
  }, [state]);

  const handleSubmit = evt => {
    evt.preventDefault();

    if (!size(chooseUsers)) {
      const isConfirm = window.confirm('確認不選擇業務繼續分配?');

      if (!isConfirm) return;
    }

    const formData = new FormData(evt.target);
    const form = {};

    for (const [key, value] of formData) {
      form[key] = value;
    }

    const assignUsers = isAutoAssign
      ? chooseUsers.map(e => ({ username: e.split('_')[0] }))
      : chooseUsers.map(e => ({
          username: e.split('_')[0],
          quantity: Number(tableRef.current.getList()[e.split('_')[0]]),
        }));

    handleAsync(async () => {
      if (!isAutoAssign) {
        const assignUsersQuantity = assignUsers.reduce((acc, cur) => acc + Number(cur.quantity), 0);
        if (assignUsersQuantity !== statCount[0] - statCount[1]) {
          throw new Error('填寫總數量 需等於 可分配客戶數量');
        }
      }

      await addFromCustomerPool(query, {
        batchCode: generateBatchCode('POOL'),
        assignUsers,
        ...form,
      });

      toggle();
    });
  };

  const handleRemove = index => {
    setChooseUsers(prev => prev.filter((_, i) => i !== index));
  };

  return (
    <>
      <Tooltip title="此功能會採用當前搜尋進行作業，請先使用搜尋並確認結果，再行使用" placement="right">
        <span>
          {cloneElement(button, {
            onClick: toggle,
            disabled: isEmpty(query) || totalCount === 0,
          })}
        </span>
      </Tooltip>
      <Dialog open={state} onClose={toggle} maxWidth="sm" fullWidth>
        <DialogTitle>批次作業 - 客戶加入專案</DialogTitle>
        <DialogContent>
          <DialogContentText>
            將客戶加入現有專案並分配，分配過程可至 <b>批次作業進度</b> 查看
          </DialogContentText>
          <Stack component="form" id={formId} onSubmit={handleSubmit} spacing={2} mt={1}>
            <TextField select label="專案" required name="projectId" defaultValue="">
              <MenuItem value="">專案</MenuItem>

              {projectsData &&
                projectsData.list.map(project => (
                  <MenuItem key={project.id} value={project.id}>
                    <ListItemText>{project.projectName}</ListItemText>
                    <Typography>{project.isParent ? '母專案' : project.parentProjectId ? '子專案' : ''}</Typography>
                  </MenuItem>
                ))}
            </TextField>
            <FormControl>
              <InputLabel>選擇人員(複選)</InputLabel>
              <Select label="選擇人員(複選)" multiple value={chooseUsers} onChange={e => setChooseUsers(e.target.value)} displayEmpty>
                {usersData &&
                  usersData.list.map(user => (
                    <MenuItem key={`opt_${user.id}`} value={`${user.username}_${user.displayName}`}>
                      {user.displayName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Stack>
          <DistributeTable
            ref={tableRef}
            users={chooseUsers}
            statCount={statCount}
            onRemove={handleRemove}
            isAutoAssign={isAutoAssign}
            toggleAutoAssign={toggleAutoAssign}
            disabled={!size(chooseUsers)}
          />
        </DialogContent>
        <DialogActions>
          <Button type="submit" form={formId}>
            確認
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import { Stack, Table, TableHead, TableRow, TableBody, TableCell } from '@mui/material';
import Formatter from 'libs/fomatter';
export default function ImportPreviewTable({ data }) {
  return (
    <Stack
      sx={{
        flex: 2,
        pr: 1,
        pb: 1,
        ml: 1,
        height: '70vh',
        overflow: 'scroll',
      }}
    >
      <Table stickyHeader size="small" sx={{ backgroundColor: '#EFEFEF55' }}>
        <TableHead>
          <TableRow
            sx={{
              '& .MuiTableCell-root': {
                borderLeft: '1px solid #00000025',
                minWidth: '100px',
                '&[colspan="4"]': {
                  minWidth: '500px',
                },
                '&[colspan="6"]': {
                  minWidth: '600px',
                },
              },
            }}
          >
            <TableCell>名稱</TableCell>
            <TableCell>電話(phone)</TableCell>
            <TableCell>性別</TableCell>
            <TableCell>婚姻狀態</TableCell>
            <TableCell>信箱</TableCell>
            <TableCell>生日</TableCell>
            <TableCell colSpan={3}>市話</TableCell>
            <TableCell>等級</TableCell>
            <TableCell>備註</TableCell>
            <TableCell colSpan={4}>通訊地址</TableCell>
            <TableCell colSpan={4}>送貨地址</TableCell>
            <TableCell colSpan={4}>發票地址</TableCell>
            <TableCell>公司名稱</TableCell>
            <TableCell>部門</TableCell>
            <TableCell>職位</TableCell>
            <TableCell>統編</TableCell>
            <TableCell colSpan={4}>上班時間</TableCell>
            <TableCell colSpan={4}>下班時間</TableCell>
            <TableCell colSpan={6}>聯絡人</TableCell>
            <TableCell>客戶標籤</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((data, i) => (
            <TableRow
              key={`import_${data.name}_${i}`}
              sx={{
                '& .MuiTableCell-root': {
                  borderLeft: '1px solid #00000025',
                },
              }}
            >
              <TableCell>{data.name}</TableCell>
              <TableCell>{data.phone}</TableCell>
              <TableCell>{data.gender ? Formatter.genderName(data.gender) : ''}</TableCell>
              <TableCell>{data.marriage ? Formatter.marriageStatus(data.marriage) : ''}</TableCell>
              <TableCell>{data.email}</TableCell>
              <TableCell>{data.birthday}</TableCell>
              <TableCell>{data.localAreaCode}</TableCell>
              <TableCell>{data.localPhone}</TableCell>
              <TableCell>{data.localExt}</TableCell>
              <TableCell>{data.grade}</TableCell>
              <TableCell>{data.description}</TableCell>
              <TableCell>{data.CommunicationAddressZipCode}</TableCell>
              <TableCell>{data.CommunicationAddressCounty}</TableCell>
              <TableCell>{data.CommunicationAddressCity}</TableCell>
              <TableCell>{data.CommunicationAddressStreet}</TableCell>
              <TableCell>{data.InvoiceAddressZipCode}</TableCell>
              <TableCell>{data.InvoiceAddressCounty}</TableCell>
              <TableCell>{data.InvoiceAddressCity}</TableCell>
              <TableCell>{data.InvoiceAddressStreet}</TableCell>
              <TableCell>{data.ShipAddressZipCode}</TableCell>
              <TableCell>{data.ShipAddressCounty}</TableCell>
              <TableCell>{data.ShipAddressCity}</TableCell>
              <TableCell>{data.ShipAddressStreet}</TableCell>
              <TableCell>{data.companyName}</TableCell>
              <TableCell>{data.department}</TableCell>
              <TableCell>{data.jobTitle}</TableCell>
              <TableCell>{data.taxIdNo}</TableCell>
              <TableCell>{data.workingHourFrom}</TableCell>
              <TableCell>{data.workingMinFrom}</TableCell>
              <TableCell>{data.workingHourTo}</TableCell>
              <TableCell>{data.workingMinTo}</TableCell>
              <TableCell>{data.restHourFrom}</TableCell>
              <TableCell>{data.restMinFrom}</TableCell>
              <TableCell>{data.restHourTo}</TableCell>
              <TableCell>{data.restMinTo}</TableCell>

              {data?.contactPersons ? (
                <>
                  <TableCell>{data.contactPersons[0].name}</TableCell>
                  <TableCell>{data.contactPersons[0].phone}</TableCell>
                  <TableCell>{data.contactPersons[0].department}</TableCell>
                  <TableCell>{data.contactPersons[0].email}</TableCell>
                  <TableCell>{Formatter.genderName(data.contactPersons[0].gender)}</TableCell>
                  <TableCell>{data.contactPersons[0].description}</TableCell>
                </>
              ) : (
                <TableCell colSpan={6} />
              )}
              <TableCell>{data.tags?.join(', ')}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Stack>
  );
}

import { ActionButton } from 'components/Button';
import EmployeeFormDialog from 'components/Employee/EmployeeFormDialog';
import useRoleCheck from 'libs/hooks/useRoleCheck';

export default function Actions() {
  const isRoleHasRightAs = useRoleCheck();
  return (
    <EmployeeFormDialog
      addNewEmployee
      button={
        <ActionButton color="info" iconName="add" disabled={!isRoleHasRightAs('MANAGER')}>
          新增員工
        </ActionButton>
      }
    />
  );
}

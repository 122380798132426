import { useState, forwardRef, useImperativeHandle } from 'react';
import { Table, TableBody, TableCell, TableHead, TableRow, Button, TextField } from '@mui/material';
import { TooltipIconButton } from 'components/Button';
import Icon from 'components/Icon';
import { pick } from 'lodash';
const ProjectFaqForm = forwardRef(({ data }, ref) => {
  const [list, setList] = useState(data?.faqs || []);

  useImperativeHandle(ref, () => ({
    getFaqs: () =>
      list.map((set, index) => ({
        ...pick(set, ['question', 'answer']),
        isEnable: true,
        seq: index + 1,
      })),
  }));

  const handleAdd = () => {
    setList(prev => [...prev, { seq: 'NEW', question: '', answer: '' }]);
  };

  const handleDelete = index => {
    setList(prev => prev.filter((_, i) => i !== index));
  };

  const handleChange = (index, name) => evt => {
    setList(prev => {
      const preArray = prev.slice(0, index);
      const target = prev.slice(index, index + 1)[0];
      const sufArray = prev.slice(index + 1);

      return [...preArray, { ...target, [name]: evt.target.value }, ...sufArray];
    });
  };

  return (
    <>
      <Button variant="outlined" startIcon={<Icon name="add" />} sx={{ position: 'absolute', top: 20, right: 20 }} onClick={handleAdd}>
        新增問答
      </Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>序</TableCell>
            <TableCell sx={{ maxWidth: '100px' }}>問題</TableCell>
            <TableCell sx={{ minWidth: '470px' }}>回答</TableCell>
            <TableCell align="right">動作</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((set, index) => (
            <TableRow key={`faq_${index}_${set?.id || 'new'}`}>
              <TableCell>{set.seq}</TableCell>
              <TableCell>
                <TextField label="問題" required fullWidth value={set.question} multiline onChange={handleChange(index, 'question')} />
              </TableCell>
              <TableCell>
                <TextField
                  label="回答"
                  required
                  fullWidth
                  value={set.answer}
                  multiline
                  maxRows={8}
                  onChange={handleChange(index, 'answer')}
                />
              </TableCell>
              <TableCell align="right">
                <TooltipIconButton iconName="delete" onClick={handleDelete.bind(null, index)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button variant="outlined" startIcon={<Icon name="add" />} sx={{ margin: '0 0 0 auto', mt: 2 }} onClick={handleAdd}>
        新增問答
      </Button>
    </>
  );
});

export default ProjectFaqForm;

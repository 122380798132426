import { styled } from '@mui/system';
import { Button } from '@mui/material';

const ToolButton = styled(Button)(({ variant }) => ({
  width: 'fit-content',
  minWidth: 100,
  height: 40,
  margin: '0 0 0 10px',
  ...(variant === 'outlined' && { backgroundColor: 'white' }),
}));

export default ToolButton;

import { useMemo, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import dayjs from 'dayjs';
import { isEmpty } from 'lodash';
import { List, LinearProgress } from '@mui/material';
import { useTaskService } from 'apis/task';
import useSearch from 'libs/hooks/useSearch';

import ContentContainer from 'components/UI/layout/ContentContainer';
import PageContainer from 'components/UI/layout/PageContainer';
import PaginatorContainer from 'components/UI/layout/PaginatorContainer';
import RecordDialog from 'components/Campaign/RecordDialog';
import { SearchFields, Tabs, TaskItem } from 'components/Task';

const MotionList = motion(List);

/**
 * tab: 1 past
 * tab: 2 now
 * tab: 3 future
 */
const getTabQuery = tab => {
  switch (tab) {
    case 1:
      return {
        remindDateTimeEnd: dayjs().subtract(1, 'day').endOf('day').toISOString(),
        sort: 'remind_at desc, created_at desc',
      };
    case 2: {
      return {
        remindDateTimeStart: dayjs().startOf('day').toISOString(),
        remindDateTimeEnd: dayjs().endOf('day').toISOString(),
        sort: 'remind_at asc, created_at desc',
      };
    }
    case 3:
      return {
        remindDateTimeStart: dayjs().add(1, 'day').startOf('day').toISOString(),
        sort: 'remind_at asc, created_at desc',
      };
    default: {
      return {};
    }
  }
};

export default function OwnTaskList() {
  const { useAllTask } = useTaskService();
  const [dialogProps, setDialogProps] = useState({});

  const [tab, setTab] = useState(2);
  const { page, limit, handleChangePage, handleChangeLimit } = useSearch();
  const [chosenCustomer, setChosenCustomer] = useState(null);
  const [chosenProject, setChosenProject] = useState(null);
  const tabQuery = useMemo(() => getTabQuery(tab), [tab]);

  const {
    data: taskData,
    isLoading,
    mutate,
  } = useAllTask({
    page,
    limit,
    isHierarchy: false,
    ...tabQuery,
    customerId: chosenCustomer?.id || '',
    projectId: chosenProject?.id || '',
  });

  const handleOpenDialog = (customerId, projectId) => {
    setDialogProps({
      customerId,
      projectId,
    });
  };

  return (
    <>
      <PageContainer sx={{ pb: 2 }}>
        <ContentContainer>
          <Tabs currentTab={tab} onTabChange={setTab} />
          <PaginatorContainer
            totalPage={taskData?.totalPage || 0}
            currentPage={page}
            limit={limit}
            onPageChange={handleChangePage}
            onLimitChange={handleChangeLimit}
            totalCount={taskData?.totalCount || 0}
            onRefresh={() => mutate(null)}
            isLoading={isLoading}
            actions={
              <SearchFields
                chosenCustomer={chosenCustomer}
                setChosenCustomer={setChosenCustomer}
                chosenProject={chosenProject}
                setChosenProject={setChosenProject}
              />
            }
          >
            <AnimatePresence mode="wait">
              <MotionList
                key={`${tab}_${page}`}
                sx={{ width: '100%' }}
                initial={{ x: -25, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: 25, opacity: 0 }}
                transition={{ type: 'string' }}
              >
                {isLoading && <LinearProgress sx={{ position: 'absolute', bottom: 0, left: 0, width: '100%' }} />}
                <AnimatePresence>
                  {taskData &&
                    taskData.list.map(task => (
                      <TaskItem
                        key={task.id}
                        {...task}
                        onOpen={() => handleOpenDialog(task.customerId, task.projectId)}
                        onUpdate={() => mutate()}
                      />
                    ))}
                </AnimatePresence>
              </MotionList>
            </AnimatePresence>
          </PaginatorContainer>
        </ContentContainer>
      </PageContainer>
      <RecordDialog {...dialogProps} isOpen={!isEmpty(dialogProps)} onClose={() => setDialogProps({})} />
    </>
  );
}

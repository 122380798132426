import { Stack, Divider, Typography } from '@mui/material';

import { ActionButton } from 'components/Button';
import ListItem from 'components/ListItem';
import ProductFormDialog from './ProductFormDialog';
export default function ProductListItem(props) {
  return (
    <ListItem>
      <ListItem.MainContent disabled>
        <Stack>
          <Typography variant="h4">{props.productName}</Typography>
          <Divider flexItem orientation="vertical" sx={{ mx: 1 }} />
          <Typography variant="h6">{props.productCode}</Typography>
        </Stack>
        <Typography variant="h6">{props.productCategory.categoryName}</Typography>
        {props.description && (
          <>
            <Divider flexItem sx={{ my: 0.5 }} />
            <Typography variant="body2" textAlign="left">
              {props.description.slice(0, 20) + '...'}
            </Typography>
          </>
        )}
      </ListItem.MainContent>
      <ListItem.SubContent>
        <Typography variant="h5">$ {props.price}</Typography>
      </ListItem.SubContent>
      <ListItem.Actions>
        <ProductFormDialog
          productId={props.id}
          button={
            <ActionButton iconName="edit" color="info">
              編輯商品資料
            </ActionButton>
          }
        />
      </ListItem.Actions>
    </ListItem>
  );
}

ProductListItem.propTypes = {
  // id: "4a1f979a-c3e5-41fe-881d-2fad25849685",
  // createdAt: "2022-08-08T03:51:15Z",
  // createUsername: "admin",
  // updatedAt: "2022-08-26T03:55:01Z",
  // updatedUsername: "test08",
  // deletedAt: null,
  // productName: "小額融資",
  // productCode: "TEST",
  // internationalBarcode: "",
  // price: 500,
  // description:
  //   "荷能封歡身；掃朱跟誰合清澡封還地布竹蝶北法，書士魚讀牠弟口唱合司爪鼻婆飽後明男斤，事尼第犬幫司事怎明昌錯，做豆入胡力豆三，姐冬乙活毛干竹直二。節可事合經拉幫經把、瓜卜冬送。\n\n直象亮雞亭七美，頁抱笑活員苗安氣扒點友。有北遠「貓辛字化由」竹什國。飛王老像左辛故！流蛋世耳右子眼司己走封你。",
  // productCategoryId: "f8b928bb-570c-4395-9ba0-9162a1a3530a",
  // productCategory: {
  //   id: "f8b928bb-570c-4395-9ba0-9162a1a3530a",
  //   createdAt: "2022-08-23T02:13:42Z",
  //   createUsername: "test08",
  //   updatedAt: "2022-08-26T03:53:45Z",
  //   updatedUsername: "test08",
  //   deletedAt: null,
  //   categoryName: "機車貸款",
  //   isEnable: true,
  // },
  // isEnable: true,
};

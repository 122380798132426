import { useId } from 'react';
import { useUserService } from 'apis/user';
import useSearch from 'libs/hooks/useSearch';
import PageContainer from 'components/UI/layout/PageContainer';
import ContentContainer from 'components/UI/layout/ContentContainer';
import SearchContainer from 'components/UI/layout/SearchContainer';
import PaginatorContainer from 'components/UI/layout/PaginatorContainer';
import { EmployeeListItem } from 'components/Employee';
import Actions from './Actions';
import SearchForm from './SearchForm';

export default function EmployeeList() {
  const formId = useId();
  const { useAllUser } = useUserService();
  const { page, limit, query, handleChangePage, handleChangeLimit, handleChangeQuery } = useSearch();
  const { data: usersData, isLoading, mutate } = useAllUser({ page, limit, ...query, sort: 'created_at desc' });

  const handleSearch = evt => {
    evt.preventDefault();
    const formData = new FormData(evt.target);
    const query = {};
    for (const [k, v] of formData) {
      query[k] = v;
    }
    handleChangePage(1);
    handleChangeQuery(query);
  };

  const handleClearSearch = () => {
    handleChangeQuery({});
  };

  return (
    <PageContainer>
      <SearchContainer>
        <form id={formId} onSubmit={handleSearch}>
          <ContentContainer>
            <SearchForm formId={formId} onClearSearch={handleClearSearch} />
          </ContentContainer>
        </form>
      </SearchContainer>
      <ContentContainer justifyContent="center">
        <PaginatorContainer
          totalPage={usersData?.totalPage || 0}
          currentPage={page}
          limit={limit}
          onPageChange={handleChangePage}
          onLimitChange={handleChangeLimit}
          totalCount={usersData?.totalCount || 0}
          onRefresh={() => mutate(null)}
          isLoading={isLoading}
          actions={<Actions />}
        >
          {usersData && usersData.list.map(user => <EmployeeListItem key={user.username} {...user} />)}
        </PaginatorContainer>
      </ContentContainer>
    </PageContainer>
  );
}

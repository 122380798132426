import { size } from 'lodash';
import { Typography, Stack, Alert } from '@mui/material';

import HistoryRecordItem from './HistoryRecordItem';

export default function HistoryRecordSection({ visitsData, onEdit, onDelete }) {
  return (
    <>
      <Typography variant="h4">歷史訪談紀錄</Typography>

      <Stack>
        {visitsData && size(visitsData.list) === 0 && (
          <Alert severity="info" color="info">
            無紀錄
          </Alert>
        )}
        {visitsData &&
          visitsData.list.map(visit => (
            <HistoryRecordItem key={visit.id} visitRecordId={visit.id} visitData={visit} onEdit={onEdit} onDelete={onDelete} />
          ))}
      </Stack>
    </>
  );
}

import PropTypes from 'prop-types';
import { Chip } from '@mui/material';

import Icon from 'components/Icon';

export default function LevelLabel({ isVip, ...props }) {
  const displayName = isVip ? 'VIP' : '一般';
  return (
    <Chip
      variant="outlined"
      icon={<Icon name="star" />}
      label={displayName}
      size="small"
      {...props}
      sx={{
        '& .MuiChip-icon': {
          color: theme => (isVip ? theme.palette.warning.main : '#00000050'),
        },
      }}
    />
  );
}

LevelLabel.propTypes = {
  isVip: PropTypes.bool.isRequired,
};

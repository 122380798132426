import { useFaqService } from 'apis/faq';
import useAsync from 'libs/hooks/useAsync';
import { Box, Paper, Stack, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Typography, Chip } from '@mui/material';
import MainContainer from 'components/UI/layout/MaintainContainer';
import { TooltipIconButton } from 'components/Button';
import RecordFaqFormDialog from 'components/maintain/RecordFaqFormDialog';
import RecordFaqForm from 'components/maintain/RecordFaqForm';
import { ConfirmDialog } from 'components/Dialog';

import { colorNameTable } from 'constant/color';

const ListItem = ({ faq, index, onUpdate, onDelete }) => {
  // const sequence = faq.seq
  const faqType = faq.faqType;
  const faqTypeName = faqType === 'question' ? '問題' : '回答';
  const label = faq.label;
  const content = faq.content;
  const color = faq.color;
  const colorName = colorNameTable[color];

  return (
    <TableRow hover>
      <TableCell>{index}</TableCell>
      <TableCell>{faqTypeName}</TableCell>
      <TableCell>{label}</TableCell>
      <TableCell>{content}</TableCell>
      <TableCell>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Box
            sx={{
              width: 15,
              height: 15,
              borderRadius: 1,
              backgroundColor: `${color}.main`,
            }}
          />
          <Typography variant="body2">{colorName}</Typography>
        </Stack>
      </TableCell>
      <TableCell align="center">啟用中</TableCell>
      <TableCell align="right">
        <RecordFaqFormDialog
          faq={faq}
          onUpdate={onUpdate}
          ButtonComponent={props => <TooltipIconButton title="編輯" iconName="edit" {...props} />}
        />
        <ConfirmDialog onConfirm={onDelete} button={<TooltipIconButton title="刪除" iconName="close" color="error" />} />
      </TableCell>
    </TableRow>
  );
};

export default function RecordFaq() {
  const handleAsync = useAsync();

  const { useAllFaq, addNewFaq, updateFaq, deleteFaq } = useFaqService();
  const { data: questionFaqsData, mutate: questionFaqsDataMutate } = useAllFaq({
    faqType: 'question',
  });
  const { data: answerFaqsData, mutate: answerFaqsDataMutate } = useAllFaq({
    faqType: 'answer',
  });

  const mutation = () => {
    questionFaqsDataMutate();
    answerFaqsDataMutate();
  };

  const handleAdd = data => handleAsync(addNewFaq.bind(null, data), mutation);
  const handleUpdate = (faqId, data) => handleAsync(updateFaq.bind(null, faqId, data), mutation);
  const handleDelete = faqId => handleAsync(deleteFaq.bind(null, faqId), mutation);

  return (
    <MainContainer title="紀錄快捷按鈕管理">
      <Stack spacing={2}>
        {questionFaqsData && answerFaqsData && (
          <RecordFaqForm
            mode="create"
            questionSeqs={questionFaqsData.totalCount}
            answerSeqs={answerFaqsData.totalCount}
            onAdd={handleAdd}
          />
        )}
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>次序</TableCell>
                <TableCell>類別</TableCell>
                <TableCell>名稱</TableCell>
                <TableCell>內容</TableCell>
                <TableCell>按鈕顏色</TableCell>
                <TableCell align="center">啟用中</TableCell>
                <TableCell align="right">動作</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell colSpan={7}>
                  <Chip label="問題" color="info" />
                </TableCell>
              </TableRow>
              {questionFaqsData &&
                questionFaqsData.list.map((faq, i) => (
                  <ListItem
                    key={faq.id}
                    faq={faq}
                    index={i + 1}
                    onUpdate={handleUpdate.bind(null, faq.id)}
                    onDelete={handleDelete.bind(null, faq.id)}
                  />
                ))}
              <TableRow>
                <TableCell colSpan={7}>
                  <Chip label="回答" variant="outlined" color="info" />
                </TableCell>
              </TableRow>
              {answerFaqsData &&
                answerFaqsData.list.map((faq, i) => (
                  <ListItem
                    key={faq.id}
                    faq={faq}
                    index={i + 1}
                    onUpdate={handleUpdate.bind(null, faq.id)}
                    onDelete={handleDelete.bind(null, faq.id)}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </MainContainer>
  );
}

import { createContext, useState } from 'react';

import { Backdrop, CircularProgress } from '@mui/material';

export const LoadingBackdropContext = createContext(false);

export const LoadingBackdropProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const toggleLoading = () => {
    setLoading(prev => !prev);
  };

  return (
    <LoadingBackdropContext.Provider value={toggleLoading}>
      <Backdrop open={loading} sx={{ zIndex: 9998 }}>
        <CircularProgress />
      </Backdrop>
      {children}
    </LoadingBackdropContext.Provider>
  );
};

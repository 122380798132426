import { useState, useMemo, memo, useImperativeHandle, forwardRef } from 'react';
import { motion, MotionConfig, AnimatePresence } from 'framer-motion';
import {
  Stack,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  TableFooter,
  FormControlLabel,
  Switch,
  IconButton,
  Collapse,
} from '@mui/material';
import useToggle from '@build-own-hooks/use-toggle';
import { SelectUser } from 'components/Input/AutoComplete';
import Icon from 'components/Icon';
import { size } from 'lodash';

const MotionTableRow = motion(TableRow);

const UserDistributeTable = forwardRef((props, ref) => {
  const { isOn: isAuto, toggle } = useToggle({ defaultState: true });

  const [users, setUsers] = useState([]);
  const [count, setCount] = useState({});

  const totalCount = useMemo(() => {
    return Object.values(count).reduce((acc, cur) => +acc + +cur, 0);
  }, [count]);

  const handleChangeCount = id => e => {
    setCount(prev => ({
      ...prev,
      [id]: e.target.value,
    }));
  };

  const handleRemoveUser = id => () => {
    setUsers(prev => prev.filter(user => user.id !== id));
  };

  useImperativeHandle(ref, () => ({
    getAssignUsers: () => {
      return users.map(user => ({
        username: user.username,
        ...(!isAuto && { quantity: +count[user.id] || 0 }),
      }));
    },
  }));

  return (
    <TableContainer sx={{ border: '1px solid lightgray', borderRadius: 1, backgroundColor: 'white' }}>
      <Stack mt={0.5} p={1}>
        <SelectUser multiple value={users} onChange={(e, nv) => setUsers(nv)} size="small" />
      </Stack>
      <Collapse in={size(users) > 0}>
        <MotionConfig transition={{ duration: 0.25 }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>人員</TableCell>

                <TableCell align="right" sx={{ width: '200px' }}>
                  <motion.div initial={{ opacity: isAuto ? 0 : 1 }} animate={{ opacity: isAuto ? 0 : 1 }}>
                    分配數量
                  </motion.div>
                </TableCell>

                <TableCell align="right">動作</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <AnimatePresence>
                {users.map(user => (
                  <MotionTableRow key={user.id} exit={{ opacity: 0 }}>
                    <TableCell>{user.displayName}</TableCell>
                    <TableCell align="right">
                      <motion.div initial={{ opacity: isAuto ? 0 : 1 }} animate={{ opacity: isAuto ? 0 : 1 }}>
                        <TextField
                          defaultValue={0}
                          value={count[user.id]}
                          onChange={handleChangeCount(user.id)}
                          size="small"
                          type="number"
                          inputProps={{ style: { textAlign: 'right' } }}
                          disabled={isAuto}
                        />
                      </motion.div>
                    </TableCell>
                    <TableCell align="right">
                      <IconButton onClick={handleRemoveUser(user.id)}>
                        <Icon iconName="close" color="error" />
                      </IconButton>
                    </TableCell>
                  </MotionTableRow>
                ))}
              </AnimatePresence>
            </TableBody>
            <TableFooter>
              <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell>
                  <FormControlLabel
                    control={<Switch color="warning" checked={isAuto} onChange={toggle} />}
                    label="自動分配"
                    labelPlacement="start"
                    sx={{ mx: 0 }}
                  />
                </TableCell>
                <TableCell align="right" colSpan={2}>
                  {isAuto ? '已開啟自動分配' : `已填寫總數 ${totalCount}`}
                </TableCell>
              </TableRow>
            </TableFooter>
          </Table>
        </MotionConfig>
      </Collapse>
    </TableContainer>
  );
});

export default memo(UserDistributeTable);

import { useState } from 'react';
import { useCustomerSourceService } from 'apis/customer';

import { TableContainer, Paper, TableHead, TableRow, TableBody, TableCell, TextField, Table, Button, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import MaintainContainer from 'components/UI/layout/MaintainContainer';
import useAsync from 'libs/hooks/useAsync';

const ListItem = ({ source }) => {
  //尚無編輯的API，實作時可參考商品類別
  return (
    <TableRow hover>
      <TableCell>{source.sourceName}</TableCell>
      <TableCell align="center">{source.isEnable ? '是' : '否'}</TableCell>
      <TableCell align="right">
        <IconButton size="small" disabled>
          <EditIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default function ProductCategory() {
  const handleAsync = useAsync();
  const { useAllCustomerSource, addNewCustomerSource } = useCustomerSourceService();
  const { data: customerSourcesData, mutate: customerSourcesDataMutate } = useAllCustomerSource();

  const [input, setInput] = useState('');

  const handleChange = event => {
    setInput(event.target.value);
  };

  const handleAdd = () => {
    if (!input.trim()) {
      alert('請輸入來源名稱');
      return;
    }
    return handleAsync(addNewCustomerSource.bind(null, { sourceName: input.trim() }), customerSourcesDataMutate);
  };

  return (
    <MaintainContainer title="來源項目管理">
      <Paper sx={{ p: 2, display: 'flex', gap: 2, justifyContent: 'space-between' }}>
        <TextField fullWidth label="來源項目" color="warning" value={input} onChange={handleChange} />
        <Button variant="contained" color="warning" onClick={handleAdd} disabled={!input}>
          新增
        </Button>
      </Paper>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>來源名稱</TableCell>
              <TableCell align="center">啟用中</TableCell>
              <TableCell align="right">動作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customerSourcesData &&
              customerSourcesData.list.map(source => <ListItem key={source.id} source={source} mutate={customerSourcesDataMutate} />)}
          </TableBody>
        </Table>
      </TableContainer>
    </MaintainContainer>
  );
}

import { styled } from '@mui/system';
import { TextField } from '@mui/material';

const SearchInput = styled(p => <TextField variant="filled" size="small" fullWidth {...p} />, {
  shouldForwardProp: prop => prop !== 'hide',
})(({ hide }) => ({
  flex: '1 0 23%',
  '& .MuiFilledInput-root': {
    backgroundColor: 'white',
    borderRadius: 5,
    overflow: 'hidden',
    '&.Mui-focused': {
      backgroundColor: '#FEFEFE',
    },
  },

  ...(hide && {
    '& .MuiInputLabel-root': {
      backgroundColor: 'white',
      height: '28px',
      width: '100px',

      '&.MuiInputLabel-shrink': {
        height: 'auto',
      },
    },
  }),
}));

export default SearchInput;

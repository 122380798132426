import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { pick } from 'lodash';

import Formatter from 'libs/fomatter';
import ListItem from 'components/ListItem';
import GenderIcon from 'components/Icon/GenderIcon';
import CompanyIcon from 'components/Icon/CompanyIcon';
import { LevelLabel, InformationLabel, TagLabel } from 'components/Label';
import ProjectStatusBar from 'components/ProjectStatusBar';
import { CustomerFormDialog } from '../Customer';
import { ActionButton } from 'components/Button';
import RecordDialog from './RecordDialog';

export default function CampaignListItem(props) {
  const isLegal = props.customer.customerType === 'legal';
  const isNatural = props.customer.customerType === 'natural';

  return (
    <ListItem>
      <ListItem.MainContent>
        <Stack>
          <Typography variant="h4">
            {Formatter.combineNameWithJobTitle(
              props.customer.companyName,
              props.customer.department,
              props.customer.jobTitle,
              props.customer.name,
            )}
          </Typography>
          {isNatural && <GenderIcon gender={props.customer.gender} />}
          {isLegal && <CompanyIcon />}
        </Stack>
        <Stack>
          <Typography>
            {isNatural && props.customer.phone + ' / '}
            {Formatter.fullPhone(props.customer.localAreaCode, props.customer.localPhone, props.customer.localExt)}
          </Typography>
        </Stack>
        <Stack mb={0.5}>
          {props.customer.grade && <LevelLabel isVip={props.customer.grade === 'VIP'} />}
          <InformationLabel type="source" label={Formatter.combineSource(props.customer.sources)} />
          {props.customer.tags && props.customer.tags.map(tag => <TagLabel key={tag.id} tag={tag} />)}
        </Stack>
        <Stack>
          <InformationLabel type="project" label={props.project.projectName} />
          <InformationLabel type="ownerUser" label={props.ownerUser.displayName} />
          <InformationLabel
            type="phone"
            label={`${props.lastVisitedResult ?? '無訪談紀錄'}${
              props.lastVisitedAt ? ` | ${dayjs(props.lastVisitedAt).format('YYYY/MM/DD HH:mm:ss')} (${props.lastVisitedUsername})` : ''
            }`}
          />
        </Stack>
      </ListItem.MainContent>
      <ListItem.SubContent sx={{ position: 'relative' }}>
        <ProjectStatusBar
          width="60px"
          height="40px"
          fontSize="14px"
          {...pick(props, ['introducedAt', 'quotedAt', 'negotiatedAt', 'signedAt', 'closedAt'])}
        />
      </ListItem.SubContent>
      <ListItem.Actions>
        <CustomerFormDialog
          customerId={props.customerId}
          button={
            <ActionButton iconName="edit" color="info">
              編輯客戶資料
            </ActionButton>
          }
        />
        <RecordDialog
          customerId={props.customerId}
          projectId={props.projectId}
          button={
            <ActionButton iconName="board" color="warning">
              紀錄面板
            </ActionButton>
          }
        />
        <ActionButton iconName="arrowRight" color="primary" onClick={props.onNav}>
          前往客戶資訊
        </ActionButton>
      </ListItem.Actions>
    </ListItem>
  );
}

CampaignListItem.propTypes = {
  customerId: PropTypes.string.isRequired,
  customer: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    customerType: PropTypes.string.isRequired,
    companyName: PropTypes.string,
    department: PropTypes.string,
    jobTitle: PropTypes.string,
    gender: PropTypes.bool,
    phone: PropTypes.string,
    localAreaCode: PropTypes.string,
    localPhone: PropTypes.string,
    localExt: PropTypes.string,
    grade: PropTypes.string,
    sources: PropTypes.arrayOf(
      PropTypes.shape({
        sourceName: PropTypes.string,
      }),
    ),
    tags: PropTypes.array,
  }).isRequired,
  project: PropTypes.shape({
    projectName: PropTypes.string.isRequired,
    updatedAt: PropTypes.string,
    updatedUsername: PropTypes.string,
  }).isRequired,
  ownerUsername: PropTypes.string.isRequired,
  ownerUser: PropTypes.shape({
    displayName: PropTypes.string.isRequired,
  }).isRequired,
};

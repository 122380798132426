import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

//最小font為body2 14px(0.875rem)

const theme = createTheme({
  palette: {
    primary: {
      dark: '#4f92a7',
      main: '#81c3d8',
      light: '#b3f6ff',
      contrastText: '#3E3E3E',
    },
    secondary: {
      dark: '#89a5ad',
      main: '#B9D6DF',
      light: '#ecffff',
      contrastText: '#3E3E3E',
    },
    warning: {
      dark: '#bc8d41',
      main: '#f1bd6f',
      light: '#ffef9f',
      contrastText: '#3E3E3E',
    },
    error: {
      dark: '#ba3e44',
      main: '#f16f6f',
      light: '#ffa09d',
      contrastText: '#3E3E3E',
    },
    info: {
      dark: '#1e5666',
      main: '#4f8394',
      light: '#7fb3c5',
      contrastText: '#ffffff',
    },
    success: {
      dark: '#2f8883',
      main: '#63b8b3',
      light: '#95ebe5',
      contrastText: '#3E3E3E',
    },
    background: {
      default: '#E5E5E5',
    },
  },
  typography: {
    h1: {
      fontSize: 36,
      fontWeight: 'bold',
      letterSpacing: '0.16px',
    },
    h2: {
      fontSize: 30,
      fontWeight: 'bold',
      letterSpacing: '0.16px',
    },
    h3: {
      fontSize: 28,
      fontWeight: 'bold',
      letterSpacing: '0.16px',
    },
    h4: {
      fontSize: 24,
      fontWeight: 'bold',
      letterSpacing: '0.16px',
    },
    h5: {
      fontSize: 18,
      fontWeight: 'bold',
      letterSpacing: '0.16px',
    },
    h6: {
      fontSize: 16,
      fontWeight: 'bold',
      letterSpacing: '0.16px',
    },
    body1: {
      fontSize: 16,
      letterSpacing: '0.16px',
    },
    body2: {
      fontSize: 14,
      letterSpacing: '0.16px',
    },
  },
});
(() => {
  window.theme = window.theme || theme;
})();

export default function HocThemeProvider({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}

import { Children, cloneElement } from 'react';
import PropTypes from 'prop-types';
import { Skeleton, Stack } from '@mui/material';

import { StyledButtonBase, Container } from './style';

function MainContent({ children, ...props }) {
  return (
    <Stack>
      <StyledButtonBase {...props}>{children}</StyledButtonBase>
    </Stack>
  );
}

function SubContent(props) {
  return <Stack {...props} />;
}

function Actions(props) {
  return <Stack {...props} />;
}

function LoadingItem() {
  return (
    <Skeleton variant="rectangular" sx={{ borderRadius: '5px' }}>
      <Container>
        <MainContent></MainContent>
        <SubContent></SubContent>
      </Container>
    </Skeleton>
  );
}

export default function ListItem({ children, isLoading }) {
  return <Container count={Children.count(children)}>{Children.map(children, child => cloneElement(child, child.props))}</Container>;
}

ListItem.MainContent = MainContent;
ListItem.SubContent = SubContent;
ListItem.Actions = Actions;
ListItem.Skeleton = LoadingItem;

ListItem.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element),
};

import { useState } from 'react';
import { Stack, Typography, AccordionSummary, AccordionDetails, Tab, Chip } from '@mui/material';

import { useCustomerService } from 'apis/customer';
import Icon from 'components/Icon';
import GenderIcon from 'components/Icon/GenderIcon';
import CompanyIcon from 'components/Icon/CompanyIcon';
import { LevelLabel, InformationLabel, TagLabel } from 'components/Label';
import { ActionButton } from 'components/Button';
import Formatter from 'libs/fomatter';

import TabPanel from './TablePanel';
import CustomChip from './CustomChip';
import { CustomerFormDialog } from '..';
import { StyledAccordion, StyledTabs } from './style';

export default function CustomerInfoCard(props) {
  const { useOneCustomer } = useCustomerService();
  const { data: customerData } = useOneCustomer(props.customerId);

  const [tab, setTab] = useState(0);
  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const isNatural = customerData && customerData.customerType === 'natural';
  const isLegal = customerData && customerData.customerType === 'legal';

  return (
    <StyledAccordion disableGutters square>
      <AccordionSummary expandIcon={<Icon name="expand" />} sx={{ userSelect: 'text' }}>
        {props.customerId && customerData && (
          <>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="h4">{customerData.name}</Typography>

              {isNatural && <GenderIcon gender={customerData.gender} />}
              {isLegal && <CompanyIcon />}
              <Typography mr={3}>{customerData.phone}</Typography>
              <InformationLabel type="source" label={Formatter.combineSource(props.sources)} />
              {customerData.grade && <LevelLabel isVip={customerData.grade === 'VIP'} />}
              {customerData.tags && customerData.tags.map(tag => <TagLabel key={tag.id} tag={tag} size="small" />)}
            </Stack>

            <CustomerFormDialog
              customerId={props.customerId}
              button={
                <ActionButton iconName="edit" sx={{ mr: 2 }}>
                  編輯客戶資料
                </ActionButton>
              }
            />
          </>
        )}
      </AccordionSummary>
      {props.customerId && customerData && (
        <AccordionDetails>
          <StyledTabs orientation="vertical" value={tab} onChange={handleTabChange}>
            <Tab label="基本資訊" />
            <Tab label="地址" />
            {isNatural && <Tab label="公司資訊" />}
            {isLegal && <Tab label="聯絡人資訊" />}
            <Tab label="負責窗口" />
          </StyledTabs>
          <TabPanel value={tab} index={0}>
            {isNatural && <CustomChip label="手機" text={customerData.phone} />}
            <CustomChip
              label="電話"
              text={Formatter.fullPhone(customerData.localAreaCode, customerData.localPhone, customerData.localExt)}
            />
            <CustomChip label="電子信箱" text={customerData.email} />
            {isNatural && <CustomChip label="生日" text={customerData.birthday} />}
            {isNatural && <CustomChip label="婚姻狀態" text={Formatter.marriageStatus(customerData.marriage)} />}
            {isLegal && (
              <>
                <CustomChip
                  label="上班時間"
                  text={Formatter.combineTime(
                    customerData.workingHourFrom,
                    customerData.workingMinFrom,
                    customerData.workingHourTo,
                    customerData.workingMinTo,
                  )}
                />
                <CustomChip
                  label="午休時間"
                  text={Formatter.combineTime(
                    customerData.restHourFrom,
                    customerData.restMinFrom,
                    customerData.restHourTo,
                    customerData.restMinTo,
                  )}
                />
              </>
            )}
            <CustomChip label="備註" text={customerData.description} />
            <CustomChip label="來源" text={customerData.sources ? customerData.sources.map(i => i.sourceName).join(',') : ''} />
          </TabPanel>
          <TabPanel value={tab} index={1} panelProps={{ sx: { '& > div': { width: '100%' } } }}>
            <CustomChip
              label="通訊地址"
              text={Formatter.fullAddress(
                customerData.communicationAddressZipCode,
                customerData.communicationAddressCounty,
                customerData.communicationAddressCity,
                customerData.communicationAddressStreet,
              )}
            />
            <CustomChip
              label="送貨地址"
              text={Formatter.fullAddress(
                customerData.shipAddressZipCode,
                customerData.shipAddressCounty,
                customerData.shipAddressCity,
                customerData.shipAddressStreet,
              )}
            />
            <CustomChip
              label="發票地址"
              text={Formatter.fullAddress(
                customerData.invoiceAddressZipCode,
                customerData.invoiceAddressCounty,
                customerData.invoiceAddressCity,
                customerData.invoiceAddressStreet,
              )}
            />
          </TabPanel>
          {isNatural && (
            <TabPanel value={tab} index={2}>
              <CustomChip label="公司名稱" text={customerData.companyName} />
              <CustomChip label="公司統編" text={customerData.taxIdNo} />
              <CustomChip label="公司部門" text={customerData.department} />
              <CustomChip label="職稱" text={customerData.jobTitle} />
            </TabPanel>
          )}
          {isLegal && (
            <TabPanel value={tab} index={2}>
              {customerData?.contactPersons?.map(person => (
                <Stack
                  key={person.id}
                  direction="row"
                  spacing={1}
                  sx={{
                    '& .MuiChip-root': {
                      minWidth: 53,
                      minHeight: 32,
                      fontSize: 16,
                      color: '#3E3E3E',
                    },
                    '& .MuiSvgIcon-root': {
                      color: 'gray',
                    },
                  }}
                >
                  <Chip label="聯絡人" />
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Icon name="user" fontSize="small" />
                    <Typography variant="body1">{person.name}</Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Icon name="circleOutline" fontSize="small" />
                    <Typography variant="body1">{person.department || '無填寫部門'}</Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Icon name="notification" fontSize="small" />
                    <Typography variant="body1">{person.phone}</Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Icon name="email" fontSize="small" />
                    <Typography variant="body1">{person.email || '無填寫信箱'}</Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Icon name="infoOutline" fontSize="small" />
                    <Typography variant="body1">{person.description || '無填寫敘述'}</Typography>
                  </Stack>
                </Stack>
              ))}
            </TabPanel>
          )}
          <TabPanel value={tab} index={3}>
            {customerData?.principalUsers?.map((user, index) => (
              <CustomChip label={index + 1} text={user.principalUser.displayName} />
            ))}
          </TabPanel>
        </AccordionDetails>
      )}
    </StyledAccordion>
  );
}

CustomerInfoCard.defaultProps = {
  customerId: 'f520f233-8092-460f-879e-cf171b2ed01a',
};

import { Divider, Stack, Typography } from '@mui/material';
import { ActionButton } from 'components/Button';
import { DateBlock } from 'components/Date';
import Icon from 'components/Icon';
import ListItem from 'components/ListItem';
import ProjectFormDialog from './ProjectFormDialog';

export default function ProjectListItem(props) {
  return (
    <ListItem>
      <ListItem.MainContent sx={{ gap: 0.5, flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center' }} disabled>
        <Stack direction="column!important" alignItems="flex-start!important">
          {props.isParent && (
            <Typography
              variant="body2"
              sx={{
                backgroundColor: 'warning.main',
                color: 'white',
                p: '1px 2px 0px',
                borderRadius: 0.5,
                mt: -1,
              }}
            >
              母專案
            </Typography>
          )}
          {props.parentProjectId && (
            <Typography
              variant="body2"
              sx={{
                border: '1px solid',
                borderColor: 'warning.main',
                color: 'warning.main',
                p: '1px 2px 0px',
                borderRadius: 0.5,
                mt: -1,
              }}
            >
              {props.parentProject.projectName} 子專案
            </Typography>
          )}
          <Stack direction="row" alignItems="center" spacing={1}>
            <Typography variant="h4">{props.projectName}</Typography>
            <Icon name="checkCircle" color="success" sx={{ opacity: props.isEnable ? 1 : 0.2 }} />
          </Stack>
          <Stack direction="row" spacing={1}>
            <DateBlock date={props.startDate} />
            <div style={{ width: '4px', height: '2px', backgroundColor: 'gray' }} />
            <DateBlock date={props.endDate} />
          </Stack>
        </Stack>
        <Stack flex="1" direction="column!important" alignItems="flex-end!important" justifyContent="center!important">
          <Typography variant="body2">總數：{props.statistics.total}</Typography>
          <Typography variant="body2">已分配：{props.statistics.assigned}</Typography>
          <Typography variant="body2">未分配：{props.statistics.total - props.statistics.assigned}</Typography>
        </Stack>
        <Divider flexItem orientation="vertical" sx={{ mx: 1.5 }} />
      </ListItem.MainContent>
      <ListItem.Actions>
        <ProjectFormDialog
          projectId={props.id}
          button={
            <ActionButton iconName="edit" color="info">
              編輯專案資訊
            </ActionButton>
          }
        />
      </ListItem.Actions>
    </ListItem>
  );
}

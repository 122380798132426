import { cloneElement, useId } from 'react';
import { Stack, Dialog, DialogContent, DialogTitle, TextField, DialogActions, Button } from '@mui/material';
import useToggle from 'libs/hooks/useToggle';
import { useTaskService } from 'apis/task';
import dayjs from 'dayjs';

export default function TaskEditDialog({ taskId, button, onEdit }) {
  const formId = useId();
  const { state, toggle } = useToggle();
  const { useOneTask } = useTaskService();
  const { data, mutate } = useOneTask(taskId, null, state);

  const handleSubmit = e => {
    e.preventDefault();
    const form = new FormData(e.target);
    const remindDate = form.get('remindDate');
    const remindTime = form.get('remindTime');
    const remindAt = dayjs(`${remindDate} ${remindTime}`).toISOString();
    onEdit({
      remindAt,
      topic: form.get('topic'),
      description: form.get('description'),
    });
    toggle();
    mutate();
  };

  return (
    <>
      {button && cloneElement(button, { onClick: toggle })}
      <Dialog open={state} onClose={toggle} maxWidth="sm" fullWidth>
        <DialogTitle>編輯待辦事項</DialogTitle>
        <DialogContent>
          <form id={formId} onSubmit={handleSubmit}>
            {data && (
              <Stack spacing={1.5} mt={2}>
                <TextField label="提醒時間" name="remindDate" required defaultValue={dayjs(data.remindAt).format('YYYY-MM-DD')} />
                <TextField label="提醒時間" name="remindTime" required defaultValue={dayjs(data.remindAt).format('HH:mm')} />
                <TextField label="主題" name="topic" required defaultValue={data.topic} multiline inputProps={{ maxLength: 64 }} />
                <TextField label="描述" name="description" defaultValue={data.description} multiline inputProps={{ maxLength: 256 }} />
              </Stack>
            )}
          </form>
        </DialogContent>
        <DialogActions>
          <Button form={formId} type="submit">
            確認
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import { useContext } from 'react';
import dayjs from 'dayjs';
import { AuthContext } from 'components/context/auth';
import { useGetFetch, postFetch, putFetch, deleteFetch } from 'apis/fetcher';
import URLs from 'constant/urls';
import toQueryString from 'libs/api/toQueryString';
import { isEmpty } from 'lodash';

export const useCampaignService = () => {
  const serviceURL = URLs.project;
  const { token } = useContext(AuthContext);

  return {
    useAllCampaign: ({ rawString, ...queryOptions }, swrOptions, ableToFetch = true) => {
      const query = toQueryString({ ...queryOptions });
      const fetchURL = ableToFetch ? `${serviceURL}/customer${query}&${rawString}` : null;

      return useGetFetch(fetchURL, swrOptions);
    },
    useOneCampaign: ({ projectId, customerId }, swrOptions, ableToFetch = true) => {
      const fetchURL = ableToFetch ? `${serviceURL}/${projectId}/customer/${customerId}` : null;
      return useGetFetch(fetchURL, swrOptions);
    },
    removeCustomerFromCampaign: async (projectId, customerId) => {
      return await deleteFetch(`${serviceURL}/${projectId}/customer/${customerId}`, { token });
    },

    updateCampaignContactStatus: async ({ projectId, customerId, status }) => {
      return await putFetch(`${serviceURL}/${projectId}/customer/${customerId}`, {
        token,
        data: {
          status,
          statusAt: dayjs().toISOString(),
        },
      });
    },
    updateCampaignProjectStatus: async ({ projectId, customerId, ...data }) => {
      return await putFetch(`${serviceURL}/${projectId}/customer/${customerId}`, {
        token,
        data,
      });
    },
    useCampaignStatisticsAssign: (queryOptions, swrOptions, ableToFetch = true) => {
      const query = toQueryString(queryOptions);
      const fetchURL = ableToFetch ? `${URLs.campaign}/statistics/assign${query}` : null;
      return useGetFetch(fetchURL, swrOptions);
    },
    useCampaignStatisticsClassify: ({ projectId = '', fields } = {}, swrOptions, ableToFetch = true) => {
      let query = toQueryString({ projectId }) || '?';

      if (!isEmpty(fields)) {
        for (let field of fields) {
          query += `&fields=${field}`;
        }
      }

      const fetchURL = ableToFetch ? `${URLs.campaign}/statistics/classify${query}` : null;
      return useGetFetch(fetchURL, swrOptions);
    },
    addCustomerToCampaign: async (projectId, customerId) => {
      return await postFetch(`${serviceURL}/${projectId}/customer/${customerId}`, { token });
    },
  };
};

export const useCampaignVisitService = () => {
  const serviceURL = `${URLs.project}/customer/visit`;
  const { token, user } = useContext(AuthContext);

  return {
    useAllCampaignVisit: (queryOptions, swrOptions, ableToFetch = true) => {
      const query = toQueryString(queryOptions);
      const fetchURL = ableToFetch ? `${serviceURL}${query}` : null;
      return useGetFetch(fetchURL, swrOptions);
    },
    useOneCampaignVisit: (id, swrOptions, ableToFetch = true) => {
      const fetchURL = id && (ableToFetch ? `${serviceURL}/${id}` : null);
      return useGetFetch(fetchURL, swrOptions);
    },
    addCampaignVisit: async data => {
      return await postFetch(serviceURL, {
        data: { ...data, visitedUsername: user.username },
        token,
      });
    },
    updateCampaignVisit: async (visitRecordId, data) => {
      return await putFetch(serviceURL + `/${visitRecordId}`, { data, token });
    },
    deleteCampaignVisit: async visitRecordId => {
      return await deleteFetch(serviceURL + `/${visitRecordId}`, { token });
    },
  };
};

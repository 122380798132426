import { useState, useCallback } from 'react';

/**
 * @deprecated
 */
export default function useToggle(defaultValue = false) {
  const [state, setState] = useState(defaultValue);

  const toggle = useCallback(() => setState(prev => !prev), []);
  toggle.toTrue = useCallback(() => setState(true), []);
  toggle.toFalse = useCallback(() => setState(false), []);

  return { state, toggle };
}

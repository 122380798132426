import { styled } from '@mui/system';
import { Box } from '@mui/material';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  minHeight: '100vh',
}));

const MainContainer = ({ children }) => {
  return <Container>{children}</Container>;
};

export default MainContainer;

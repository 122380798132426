import { Button, Divider, Stack, Table, TableBody, TableHead, TableRow, TextField, Typography } from '@mui/material';
import ContentContainer from 'components/UI/layout/ContentContainer';
import PageContainer from 'components/UI/layout/PageContainer';

import { Section, StyledTableRow, StyledTableCell } from './style';

export default function CreateOrder() {
  return (
    <PageContainer>
      <Stack
        sx={{
          height: '80px',
          backgroundColor: 'primary.main',
          justifyContent: 'center',
        }}
      >
        <ContentContainer>
          <Typography variant="h2" color="white">
            建立訂單
          </Typography>
        </ContentContainer>
      </Stack>

      <ContentContainer sx={{ mt: 2 }}>
        <Section
          direction="row"
          sx={{
            '& .MuiTextField-root': {
              flex: 1,
            },
          }}
        >
          <Typography variant="h3" sx={{ width: '100%' }}>
            客戶資訊
          </Typography>
          <TextField label="客戶名稱" />
          <TextField label="客戶電話" />
        </Section>
        <Section
          sx={{
            '& .MuiTextField-root': {
              flex: 1,
            },
          }}
        >
          <Typography variant="h3" sx={{ width: '100%' }}>
            訂單明細
          </Typography>
          <Stack direction="row" spacing={1}>
            <Stack sx={{ flex: 1.25 }} spacing={1}>
              <TextField size="small" label="請輸入商品名稱或代碼" />
              <Stack sx={{ height: '400px', overflowY: 'scroll' }} justifyContent="space-between">
                <Table size="small">
                  <TableHead
                    sx={{
                      position: 'sticky',
                      top: 0,
                    }}
                  >
                    <TableRow>
                      <StyledTableCell sx={{ borderRadius: '4px 0 0 0 ' }}>項次</StyledTableCell>
                      <StyledTableCell>商品名稱</StyledTableCell>
                      <StyledTableCell>定價</StyledTableCell>
                      <StyledTableCell>數量</StyledTableCell>
                      <StyledTableCell>折扣</StyledTableCell>
                      <StyledTableCell>合計</StyledTableCell>
                      <StyledTableCell>刪除</StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Array(18)
                      .fill('')
                      .map((_, i) => (
                        <StyledTableRow key={i}>
                          <StyledTableCell>{i}</StyledTableCell>
                          <StyledTableCell>商品名稱</StyledTableCell>
                          <StyledTableCell>定價</StyledTableCell>
                          <StyledTableCell>數量</StyledTableCell>
                          <StyledTableCell>折扣</StyledTableCell>
                          <StyledTableCell>合計</StyledTableCell>
                          <StyledTableCell>刪除</StyledTableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
                <Stack
                  sx={{
                    position: 'sticky',
                    bottom: 0,
                    borderRadius: '0 0 0px 4px',
                    backgroundColor: 'primary.main',
                    px: 1,
                    py: 0.5,
                    height: '30px',
                  }}
                  direction="row"
                >
                  <Typography sx={{ flex: 1 }}>全部刪除</Typography>
                  <Typography>$ 總金額：16520元</Typography>
                  <Typography>三 總數量：7</Typography>
                </Stack>
              </Stack>
            </Stack>
            <Stack direction="row" sx={{ flex: 1, flexWrap: 'wrap' }} alignItems="stretch" justifyContent="space-around">
              {Array(9)
                .fill('')
                .map((_, i) => (
                  <Stack
                    sx={{
                      flexBasis: '32%',
                      borderRadius: 1,
                      mb: 0.5,
                      boxShadow: '0px 1px 6px #00000035',
                      p: 1,
                    }}
                    justifyContent="space-between"
                  >
                    <Typography flex="1">商品名稱</Typography>
                    <Divider></Divider>
                    $200
                  </Stack>
                ))}
            </Stack>
            <Stack
              sx={{
                borderRight: '3px solid',
                borderRightColor: 'warning.main',
              }}
            >
              <Stack
                flex="1"
                sx={{
                  backgroundColor: 'warning.main',
                  color: 'white',
                  p: 1,
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                分類
              </Stack>
              {Array(2)
                .fill('')
                .map((_, i) => (
                  <Stack
                    flex="1"
                    sx={{
                      backgroundColor: 'white',
                      p: 1,
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    分類
                  </Stack>
                ))}
            </Stack>
          </Stack>
        </Section>
        <Section
          direction="row"
          sx={{
            '& .MuiTextField-root': {
              flex: 1,
            },
          }}
        >
          <Typography variant="h3" sx={{ width: '100%' }}>
            送貨資訊
          </Typography>
          <TextField label="託運對象" />
          <TextField label="收貨人" />
          <TextField label="收貨人電話" />
          <TextField label="收貨人電話" />
          <Divider flexItem orientation="vertically" sx={{ width: '100%' }} />
          <Typography variant="h5" sx={{ width: '100%' }}>
            送貨地址
          </Typography>
          <TextField label="郵遞區號" />
          <TextField label="縣市" />
          <TextField label="鄉鎮市區" />
          <TextField label="街道門號" />
        </Section>
        <Section
          direction="row"
          sx={{
            '& .MuiTextField-root': {
              flex: 1,
            },
          }}
        >
          <Typography variant="h3" sx={{ width: '100%' }}>
            發票資訊
          </Typography>
          <TextField label="統一編號" />
          <TextField label="發票抬頭" />
          <TextField label="Email" />
          <Divider flexItem orientation="vertically" sx={{ width: '100%' }} />
          <Typography variant="h5" sx={{ width: '100%' }}>
            發票地址
          </Typography>
          <TextField label="郵遞區號" />
          <TextField label="縣市" />
          <TextField label="鄉鎮市區" />
          <TextField label="街道門號" />
        </Section>
      </ContentContainer>
      <ContentContainer justifyContent="center" sx={{ mt: 2 }}>
        <Button variant="contained" color="primary">
          送出訂單
        </Button>
      </ContentContainer>
    </PageContainer>
  );
}

import { cloneElement, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';
import useToggle from 'libs/hooks/useToggle';

export default function EditRecordDialog(props) {
  const { state, toggle } = useToggle();
  const [description, setDescription] = useState(props.description);

  const handleUpdate = () => {
    if (!description) {
      return alert('紀錄不得為空白');
    }

    props.onUpdate(description);
    toggle();
  };

  return (
    <>
      {cloneElement(props.button, { onClick: toggle })}
      <Dialog open={state} onClose={toggle} maxWidth="sm" fullWidth>
        <DialogTitle>編輯紀錄</DialogTitle>
        <DialogContent>
          <TextField rows={8} value={description} onChange={e => setDescription(e.target.value)} fullWidth multiline required />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleUpdate}>
            儲存
          </Button>
          <Button color="error" onClick={toggle}>
            取消
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import { useState } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useCustomerService } from 'apis/customer';
import { useProjectService } from 'apis/project';

export default function SearchFields({ chosenCustomer, setChosenCustomer, chosenProject, setChosenProject }) {
  const { useAllCustomer } = useCustomerService();
  const { useAllProject } = useProjectService();

  const [customerNameQuery, setCustomerNameQuery] = useState('');

  const { data: allCustomerData, isLoading: isCustomerDataLoading } = useAllCustomer({
    customerName: customerNameQuery,
    limit: 30,
    isAlone: false,
  });

  const [projectNameQuery, setProjectNameQuery] = useState('');
  const { data: allProjectData, isLoading: isProjectDataLoading } = useAllProject({
    projectName: projectNameQuery,
    limit: 30,
    isEnable: true,
  });

  return (
    <>
      <Autocomplete
        value={chosenProject}
        onChange={(e, nv) => setChosenProject(nv)}
        inputValue={projectNameQuery}
        onInputChange={(e, nv) => setProjectNameQuery(nv)}
        options={allProjectData?.list || []}
        getOptionLabel={option => option.projectName}
        renderInput={props => <TextField {...props} label="專案名稱" />}
        loading={isProjectDataLoading}
        loadingText={'搜尋中...'}
      />
      <Autocomplete
        value={chosenCustomer}
        onChange={(e, nv) => setChosenCustomer(nv)}
        inputValue={customerNameQuery}
        onInputChange={(e, nv) => setCustomerNameQuery(nv)}
        options={allCustomerData?.list || []}
        getOptionLabel={option => option.name}
        renderInput={props => <TextField {...props} label="客戶名稱" />}
        loading={isCustomerDataLoading}
        loadingText={'搜尋中...'}
      />
    </>
  );
}

import { cloneElement, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, MenuItem, Button, Select } from '@mui/material';
import useToggle from 'libs/hooks/useToggle';
import useAsync from 'libs/hooks/useAsync';
import { useCampaignService } from 'apis/campaign';
import { useUserService } from 'apis/user';

export default function JoinUserDialog({ button, projectId, customerId, onAdd }) {
  const { state, toggle } = useToggle();
  const handleAsync = useAsync();
  const { useAllUser } = useUserService();
  const { updateCampaignProjectStatus } = useCampaignService();

  const { data: allUserData } = useAllUser({ limit: 'zero', isEnable: true }, null, state);
  console.log(projectId, customerId);
  const [username, setUsername] = useState('');

  const handleAdd = () => {
    handleAsync(updateCampaignProjectStatus.bind(null, { projectId, customerId, ownerUsername: username }), onAdd);
  };

  return (
    <>
      {button && cloneElement(button, { onClick: toggle })}
      <Dialog open={state} onClose={toggle} maxWidth="sm" fullWidth>
        <DialogTitle>人員指派</DialogTitle>
        <DialogContent>
          <Select fullWidth onChange={e => setUsername(e.target.value)} MenuProps={{ PaperProps: { style: { maxHeight: '400px' } } }}>
            {allUserData &&
              allUserData.list.map(user => (
                <MenuItem key={`select_opt_${user.id}`} value={user.username}>
                  {user.displayName}
                </MenuItem>
              ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAdd} disabled={!username}>
            確認
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import { Stack, Typography, Autocomplete, TextField } from '@mui/material';
import { useProjectService } from 'apis/project';
import useDelayValue from 'libs/hooks/useDelayValue';

export default function SelectProject({ query, ...params }) {
  const { useAllProject } = useProjectService();
  const { value, delayValue, setValue } = useDelayValue();

  const { data: projectData, isLoading } = useAllProject({
    projectName: delayValue,
    limit: 30,
    sort: 'end_date desc',
    ...query,
  });

  return (
    <Autocomplete
      inputValue={value}
      onInputChange={(e, nv) => setValue(nv)}
      options={projectData?.list || []}
      getOptionLabel={option => option.projectName}
      renderInput={p => <TextField {...p} label="選擇專案" />}
      renderOption={(props, option) => (
        <Stack direction="row" width="100%" justifyContent="space-between!important" {...props} key={props.key + props.id}>
          <Typography>{option.projectName}</Typography>
          <Typography>{option.isParent ? '母專案' : option.parentProjectId ? '子專案' : ''}</Typography>
        </Stack>
      )}
      loading={isLoading}
      {...params}
    />
  );
}

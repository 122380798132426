import { useMemo } from 'react';
import { Routes, Route, Navigate, Outlet } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';

import SWRMiddlewareToken from 'apis/middleware/getToken';
import SideDrawer from 'components/Drawer';
import ErrorBoundary from 'components/ErrorBoundary';
import useRoleCheck from 'libs/hooks/useRoleCheck';

import Login from 'pages/Login';
import MaintainPage from 'pages/MaintainPage';
import { OwnTaskList, TaskList } from 'pages/Task';
import { CampaignList } from 'pages/Campaign';
import { Customer, CustomerList } from 'pages/Customer';
import { ProjectList } from 'pages/Project';
import { ProductList } from 'pages/Product';
import PlaygroundPage from 'pages/PlaygroundPage';

// TODO 遇到BUG 不知怎麼解Q_Q
// import { EmployeeList } from "pages/Employee"
import EmployeeList from 'pages/Employee/EmployeeList';

import { CreateOrder } from 'pages/Order';
import { useAuth } from 'components/context/auth';

const ManagerRoute = () => {
  const isRoleHasRightAs = useRoleCheck();

  if (!isRoleHasRightAs) {
    return <Navigate to="/campaign/list" replace={true} />;
  }

  return <Outlet />;
};

const Router = () => {
  const showMenu = useMediaQuery('(min-width:1445px)');

  const { isAuth, logout, user } = useAuth();
  const isRoleHasRightAs = useRoleCheck();

  const menuProps = useMemo(() => {
    return {
      isManager: isRoleHasRightAs('MANAGER'),
      onLogout: logout,
      userName: user?.displayName,
    };
    // eslint-disable-next-line
  }, [isAuth, user]);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route
        path="/"
        element={
          <SWRMiddlewareToken>
            {showMenu && <SideDrawer.Menu {...menuProps} />}
            {!showMenu && <SideDrawer {...menuProps} />}
            <ErrorBoundary>
              <Outlet />
            </ErrorBoundary>
          </SWRMiddlewareToken>
        }
      >
        {/* /order: TEMP PAGE */}
        <Route path="/order" element={<CreateOrder />} />
        <Route path="/task" element={<OwnTaskList />} />
        <Route path="/campaign/list" element={<CampaignList />} />
        <Route path="/customer/:id" element={<Customer />} />

        <Route element={<ManagerRoute />}>
          <Route path="/customer/list" element={<CustomerList />} />
          <Route path="/project/list" element={<ProjectList />} />
          <Route path="/product/list" element={<ProductList />} />
          <Route path="/employee/list" element={<EmployeeList />} />
          <Route path="/taskVisitor" element={<TaskList />} />

          <Route path="/maintain" element={<MaintainPage />} />
          <Route path="/playground" element={<PlaygroundPage />} />
        </Route>

        <Route path="*" element={<Navigate to="/campaign/list" />} />
      </Route>
    </Routes>
  );
};

export default Router;

import { useState } from 'react';
import { MenuItem } from '@mui/material';
import { SearchInput, SearchSelect, SearchButtonGroup } from 'components/SearchForm';

export default function SearchForm(props) {
  const [select, setSelect] = useState({
    isEnable: '',
    role: '',
  });

  const handleChange = evt => {
    const { name, value } = evt.target;
    setSelect({ ...select, [name]: value });
  };

  const handleClear = () => {
    props.onClearSearch();
    setSelect({
      isEnable: '',
      role: '',
    });
  };

  return (
    <>
      <SearchInput label="員工姓名" name="username" />
      <SearchInput label="員工電話" name="phone" />
      <SearchInput label="職位" name="position" />

      <SearchSelect label="角色" name="role" value={select.role} onChange={handleChange}>
        <MenuItem value="">清除</MenuItem>
        <MenuItem value="user">一般人員</MenuItem>
        <MenuItem value="manager">主管</MenuItem>
      </SearchSelect>
      <SearchSelect label="啟用狀態" name="isEnable" value={select.isEnable} onChange={handleChange}>
        <MenuItem value="">清除</MenuItem>
        <MenuItem value={true}>只顯示啟用中</MenuItem>
        <MenuItem value={false}>只顯示未啟用</MenuItem>
      </SearchSelect>
      <SearchButtonGroup formId={props.formId} onClearSearch={handleClear} />
    </>
  );
}

import { cloneElement, Fragment, useState } from 'react';
import dayjs from 'dayjs';
import { useBatchService } from 'apis/batch';
import useToggle from 'libs/hooks/useToggle';
import {
  DialogContent,
  styled,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  TableRow,
  Stack,
  Dialog,
  DialogTitle,
  Button,
  Box,
  Tab,
  Tabs,
  Typography,
  DialogActions,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import SyncIcon from '@mui/icons-material/Sync';
import Icon from 'components/Icon';

const StyledDialog = styled(Dialog)({
  '& .MuiPaper-root': {
    borderRadius: 0,
    backgroundColor: '#FAFAFA',
  },
  '& .MuiDialogTitle-root': {
    fontSize: '1.5rem',
  },
});

const StatusCount = styled(Stack)({
  flexDirection: 'row',
  gap: 4,
  alignItems: 'flex-end',
});

const ImportBatchLogDialog = ({ button }) => {
  const { state, toggle } = useToggle();
  const { useBatchLog } = useBatchService();

  const [tab, setTab] = useState('IMPORT');

  //IMPORT, ASSIGN, POOL, TRANSFER
  const { data: logData, mutate } = useBatchLog({ batchType: tab }, null, state);

  const handleChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      {cloneElement(button, { onClick: toggle })}
      <StyledDialog open={state} onClose={toggle.toFalse} fullWidth maxWidth="lg">
        <DialogTitle>批次操作進度查看</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              '& .MuiTabs-root': {
                minHeight: '28px',
              },
              '& .MuiTab-root': {
                minHeight: '28px',
                mr: 0.5,
                borderRadius: 4,
                backgroundColor: 'white',
                color: 'info.main',
                border: '1px solid',
                borderColor: 'info.main',
                '&.Mui-selected': {
                  color: '#FFFFFF',
                  backgroundColor: 'info.main',
                },
              },
            }}
          >
            <Tabs value={tab} onChange={handleChange} TabIndicatorProps={{ sx: { display: 'none' } }}>
              <Tab value="IMPORT" label="名單多筆匯入" />
              <Tab value="ASSIGN" label="客戶名單分配" />
              <Tab value="POOL" label="客戶加入專案" />
              <Tab value="TRANSFER" label="轉移名單" />
              <Tab value="PARENT_TRANSFER" label="母子專案名單轉移" />
            </Tabs>
          </Box>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow sx={{ borderBottom: '2px solid gray' }}>
                  <TableCell>匯入識別碼</TableCell>
                  <TableCell align="center">匯入人員</TableCell>
                  <TableCell align="center">匯入時間 / 最後紀錄時間</TableCell>
                  <TableCell align="center">相關專案</TableCell>
                  <TableCell align="center">狀態</TableCell>
                  <TableCell align="center">名單筆數 (成功 / 失敗 / 重覆)</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {logData &&
                  (logData?.list || []).map(item => (
                    <Fragment key={item.id}>
                      <TableRow
                        sx={{
                          ...(!item.isFinish && {
                            animation: 'bgHopping 0.8s infinite alternate',
                            '@keyframes bgHopping': {
                              to: {
                                backgroundColor: theme => theme.palette.warning.light,
                              },
                            },
                          }),
                          '& .MuiTableCell-root': {
                            borderBottom: item.failMessage || item.ignoreMessage ? 'none' : 'default',
                          },
                        }}
                        hover
                      >
                        <TableCell sx={{ fontWeight: 'bolder' }}>{item.batchCode || '--'}</TableCell>

                        <TableCell align="center">{item.createUsername}</TableCell>
                        <TableCell align="center">
                          <Typography variant="body2">{dayjs(item.createdAt).format('YYYY-MM-DD HH:mm:ss')}</Typography>
                          <Typography variant="body2">{dayjs(item.updatedAt).format('YYYY-MM-DD HH:mm:ss')}</Typography>
                        </TableCell>
                        <TableCell align="center">
                          {item.parentProjectId ? (
                            <Stack alignItems="center">
                              <Typography variant="body2">{item.parentProject.projectName}</Typography>
                              <Icon name="arrowDown" sx={{ fontSize: '16px', my: 0.5 }} />
                              <Typography variant="body2">{item.projectId ? item.project.projectName : '--'}</Typography>
                            </Stack>
                          ) : (
                            <Typography variant="body2">{item.projectId ? item.project.projectName : '--'}</Typography>
                          )}
                        </TableCell>
                        <TableCell align="center">{item.isFinish ? '已完成' : '進行中'}</TableCell>
                        <TableCell align="center">
                          {item.totalCount && `共 ${item.totalCount} 筆`}

                          <Stack direction="row" spacing={2} justifyContent="center">
                            <StatusCount>
                              <CheckIcon color="primary" />
                              {item.successCount}
                            </StatusCount>
                            <StatusCount>
                              <CloseIcon color="error" />
                              {item.failCount}
                            </StatusCount>
                            <StatusCount>
                              <SyncIcon color="warning" />
                              {item.ignoreCount}
                            </StatusCount>
                          </Stack>
                        </TableCell>
                      </TableRow>
                      {item.failMessage && (
                        <TableRow hover>
                          <TableCell colSpan={6}>
                            <Typography
                              sx={{
                                whiteSpace: 'pre-wrap',
                                wordBreak: 'break-word',
                              }}
                            >
                              錯誤訊息：{item.failMessage}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                      {item.ignoreMessage && (
                        <TableRow hover>
                          <TableCell colSpan={6}>
                            <Typography
                              sx={{
                                whiteSpace: 'pre-wrap',
                                wordBreak: 'break-word',
                              }}
                            >
                              重覆訊息：{item.ignoreMessage}
                            </Typography>
                          </TableCell>
                        </TableRow>
                      )}
                    </Fragment>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={mutate}>
            重新整理
          </Button>
          <Button variant="outlined" color="error" onClick={toggle.toFalse}>
            關閉
          </Button>
        </DialogActions>
      </StyledDialog>
    </>
  );
};

export default ImportBatchLogDialog;

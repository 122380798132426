import { Box, Chip, MenuItem } from '@mui/material';
import { SearchSelect } from 'components/SearchForm';
import { useUserService } from 'apis/user';

export default function SelectUsers({ isHierarchy = true, ...props }) {
  const { useAllUser } = useUserService();
  const { data: usersData } = useAllUser({ isHierarchy, limit: 'zero' });
  return (
    <SearchSelect
      multiple
      fullWidth
      sx={{ height: 'auto!important' }}
      renderValue={selected => (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selected.map(value => (
            <Chip key={value} label={usersData.list.find(tag => tag.username === value).displayName} />
          ))}
        </Box>
      )}
      MenuProps={{
        PaperProps: {
          style: {
            maxHeight: '400px',
          },
        },
      }}
      {...props}
    >
      {usersData &&
        usersData.list.map(user => (
          <MenuItem key={user.id} value={user.username}>
            {user.displayName}
          </MenuItem>
        ))}
    </SearchSelect>
  );
}

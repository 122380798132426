import PropTypes from 'prop-types';
import { pick } from 'lodash';
import { Bar, Block } from './style';
import getProjectTimeList from 'libs/getProjectTimeList';
import { visitType, visitTypeDisplayName } from 'constant/visitType';
import { useSystemInfoService } from 'apis/systemInfo';
import { Skeleton } from '@mui/material';

/**
 * The component default as `position: absolute with top/right 0/0`
 * @param {string} height - for BAR
 * @param {string} borderRadius - for BAR
 * @param {string} width - for BLOCK
 * @param {string} fontSize - for BLOCK
 * @param {object} barProps
 * @param {object} blockProps
 * @param {Date} [introducedAt, quotedAt, negotiatedAt, signedAt, closedAt]
 * @returns
 */
export default function ProjectStatusBar(props) {
  const { useOneSystemInfo } = useSystemInfoService();
  const { data: progressBarInfo, isLoading } = useOneSystemInfo('progressBar');

  const { timeList, statusBarCount } = getProjectTimeList(
    pick(props, ['introducedAt', 'quotedAt', 'negotiatedAt', 'signedAt', 'closedAt']),
  );

  return (
    <Bar count={statusBarCount} borderRadius={props.borderRadius} sx={{ height: props.height, ...props.barProps }}>
      {!isLoading &&
        timeList.map((time, index) => (
          <Block
            key={`${visitTypeDisplayName[visitType[index]]}_${time}`}
            sx={{ width: props.width, fontSize: props.fontSize, ...props.blockProps }}
          >
            {(props?.preview && props?.preview?.[visitType[index]]?.customName) ||
              progressBarInfo?.details?.[visitType[index]]?.customName ||
              visitTypeDisplayName[visitType[index]]}
            <span>{time}</span>
          </Block>
        ))}
      {isLoading && (
        <Skeleton
          variant="rectangular"
          animation="wave"
          sx={{
            width: `calc(${props.width} * 5)`,
            height: '100%',
            position: 'relative',
            zIndex: 9,
          }}
        />
      )}
    </Bar>
  );
}

ProjectStatusBar.propTypes = {
  introducedAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  quotedAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  negotiatedAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  signedAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  closedAt: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  height: PropTypes.string,
  width: PropTypes.string,
  fontSize: PropTypes.string,
  borderRadius: PropTypes.string,
};

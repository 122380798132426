import { createContext, useContext, cloneElement, useState } from 'react';
import { size } from 'lodash';
import useToggle from '@build-own-hooks/use-toggle';
import {
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Button,
  Collapse,
  IconButton,
  TextField,
} from '@mui/material';
import { useDialogContext } from '../Provider';
import Icon from 'components/Icon';
import useAsync from 'libs/hooks/useAsync';

import { useBatchService } from 'apis/batch';
import generateBatchCode from 'libs/generateBatchCode';
import { SelectProject } from 'components/Input/AutoComplete';

const CollapseContext = createContext(false);

function CollapseProvider({ defaultCollapse = false, ...props }) {
  const { isOn, toggle } = useToggle({ defaultState: defaultCollapse });
  return <CollapseContext.Provider value={{ isOn, toggle }} {...props} />;
}

function CollapseButton({ children }) {
  const { toggle } = useContext(CollapseContext);

  return cloneElement(children, { onClick: toggle });
}

function CollapseContent(props) {
  const { isOn } = useContext(CollapseContext);
  return <Collapse in={isOn} {...props} />;
}

export default function StepSelectChildProject() {
  const { parentTransfer } = useBatchService();
  const { state, selectionModel, dataGridRow, handleToStepTwo } = useDialogContext();
  const handleAsync = useAsync();

  const [hasRestGroup, setHasRestGroup] = useState(false);

  const [groups, setGroups] = useState(() =>
    selectionModel.map(select => {
      const data = dataGridRow.find(row => row.id === select);
      return { ...data, filters: data.id, childProject: null, quantity: 0 };
    }),
  );

  const handleCreateRestGroup = () => {
    setHasRestGroup(true);
    setGroups(prev => [
      ...prev,
      {
        childProject: null,
        quantity: 0,
        fields: '',
        filters: '',
        notAssignedCount: null,
        type: 'rest',
      },
    ]);
  };

  const handleRemoveRestGroup = () => {
    setHasRestGroup(false);
    setGroups(prev => prev.filter(group => group?.type !== 'rest'));
  };

  const handleChangeGroupData = (groupId, name, value) => {
    setGroups(prev =>
      prev.map(item => {
        if (item.id === groupId) {
          return {
            ...item,
            [name]: value,
          };
        }

        return item;
      }),
    );
  };

  const handleSubmit = () => {
    const data = {
      batchCode: generateBatchCode('PARENT_TRANSFER'),
      parentProjectId: state.project.id,
      rules: groups.map(group => ({
        // assignUsers: group.ref.getAssignUsers(),
        childProjectId: group.childProject?.id,
        quantity: +group.quantity,
        ...(group.filters && {
          filters: group.filters.split(',').map(filter => {
            const [type, field, value] = filter.split('.');
            return {
              type,
              field,
              value,
              operator: 'equal',
            };
          }),
        }),
      })),
    };

    const found = data.rules.find(rule => !rule.childProjectId);

    if (found) {
      const groupName = found.filters.map(filter => filter.value).join(', ');
      alert(`[${groupName}] 此組合尚未填寫專案或數量`);
      return;
    }

    handleAsync(async () => {
      await parentTransfer(data);
    });
  };

  return (
    <Stack>
      <TableContainer sx={{ border: '1px solid lightgray', borderRadius: 1 }}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>組</TableCell>
              <TableCell>篩選條件</TableCell>
              <TableCell>可分配數量</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {groups.map((data, index) => (
              <CollapseProvider key={`stage3_${data?.id || index}`} defaultCollapse={index === 0}>
                <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                  <TableCell>
                    <CollapseButton>
                      <IconButton size="small">
                        <Icon iconName="chevronDown" />
                      </IconButton>
                    </CollapseButton>
                  </TableCell>
                  <TableCell>{data.fields || '無篩選條件'}</TableCell>
                  <TableCell>{data.notAssignedCount || ''}</TableCell>
                </TableRow>
                <TableRow sx={{ '&:last-of-type > *': { borderBottom: 'unset' } }}>
                  <TableCell
                    style={{
                      paddingBottom: 0,
                      paddingTop: 0,
                      backgroundColor: '#E5E5E5',
                    }}
                    colSpan={6}
                  >
                    <CollapseContent>
                      <Stack py={1}>
                        {/* <UserDistributeTable ref={ref => (data.ref = ref)} /> */}
                        <Stack direction="row" gap={1} sx={{ background: 'white', p: 1, borderRadius: 1 }}>
                          <SelectProject
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            query={{
                              parentProjectIds: state?.project?.id,
                              isParent: false,
                            }}
                            fullWidth
                            value={data.childProject}
                            onChange={(e, nv) => handleChangeGroupData(data.id, 'childProject', nv)}
                          />
                          <TextField
                            label="數量"
                            value={data.quantity}
                            onChange={e => handleChangeGroupData(data.id, 'quantity', e.target.value)}
                          />
                        </Stack>
                      </Stack>
                    </CollapseContent>
                  </TableCell>
                </TableRow>
              </CollapseProvider>
            ))}
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
              <TableCell colSpan={4}>
                <Button
                  variant="outlined"
                  onClick={hasRestGroup ? handleRemoveRestGroup : handleCreateRestGroup}
                  color={hasRestGroup ? 'error' : 'primary'}
                >
                  {hasRestGroup ? '移除無篩選條件組' : '新增無篩選條件組'}
                </Button>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
      <Stack direction="row" my={2} spacing={1}>
        <Button variant="contained" onClick={handleSubmit} disabled={!size(groups)}>
          確認
        </Button>
        <Button onClick={handleToStepTwo}>上一步</Button>
      </Stack>
    </Stack>
  );
}

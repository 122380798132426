import { useRef, useContext, useState } from 'react';
import { AuthContext } from 'components/context/auth';
import { useLogin } from 'apis/auth';

import { Button, Stack, TextField, Typography } from '@mui/material';
import useAsync from 'libs/hooks/useAsync';

export default function LoginPage() {
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);

  const handleAsync = useAsync();
  const { dispatch } = useContext(AuthContext);
  const [input, setInput] = useState({ username: '', password: '' });
  const handleLogin = useLogin();

  const handleClick = () => {
    handleAsync(async () => {
      const { token } = await handleLogin(input.username, input.password);
      dispatch({ type: 'LOGIN', payload: { token } });
    });
  };

  const handleChange = e => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const handleKeyEnter = e => {
    if (e.keyCode !== 13) return;

    if (e.target.name === 'username') {
      passwordRef.current.focus();
    }

    if (e.target.name === 'password') {
      handleClick();
    }
  };

  return (
    <Stack alignItems="center" justifyContent="center" flex="1" spacing={2}>
      <Typography variant="h1">電銷系統登入</Typography>
      <TextField
        variant="standard"
        label="帳號"
        name="username"
        value={input.username}
        onChange={handleChange}
        onKeyDown={handleKeyEnter}
        color="warning"
        inputRef={usernameRef}
      />
      <TextField
        variant="standard"
        label="密碼"
        name="password"
        value={input.password}
        onChange={handleChange}
        onKeyDown={handleKeyEnter}
        type="password"
        color="warning"
        inputRef={passwordRef}
      />
      <Button color="warning" variant="contained" size="large" onClick={handleClick}>
        登入
      </Button>
    </Stack>
  );
}

import { styled } from '@mui/system';
import { Stack, Box } from '@mui/material';

export const Container = styled(Stack)(({ theme }) => ({
  backgroundColor: 'white',
  minWidth: '265px',
  maxWidth: '265px',
  height: '100vh',
  maxHeight: '100vh',
  borderRight: '1px solid lightgray',
  position: 'sticky',
  top: 0,
}));

export const TopSection = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '48px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  borderBottom: '1px solid lightgray',
  padding: '0 21px',
}));

export const LinkSection = styled(Stack)(({ theme }) => ({
  width: '100%',
  marginTop: '8px',
  gap: '2px',
  '& a': {
    textDecoration: 'none',
    color: 'black',
    '&.active > div': {
      backgroundColor: theme.palette.primary.main,
      '& p': {
        color: 'white',
        fontWeight: 'bolder',
      },
    },
  },
}));

export const Item = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: '46px',
  justifyContent: 'center',
  padding: '0 21px',
  transition: 'all 0.3s ease-in-out',
}));

import { useState, useEffect, useId } from 'react';
import { Divider, Paper, Stack, TextField, Button } from '@mui/material';
import { useSystemInfoService } from 'apis/systemInfo';
import ProjectStatusBar from 'components/ProjectStatusBar';
import useAsync from 'libs/hooks/useAsync';

export default function ProgressBar() {
  const formId = useId();
  const handleAsync = useAsync();
  const { useOneSystemInfo, updateSystemInfo } = useSystemInfoService();
  const { data: progressBarInfo, mutate } = useOneSystemInfo('progressBar');

  const [progressBarData, setProgressBarData] = useState({});

  useEffect(() => {
    if (!progressBarInfo) return;

    setProgressBarData(progressBarInfo?.details || {});
  }, [progressBarInfo]);

  const handleChange = e => {
    const { name, value } = e.target;
    setProgressBarData(prev => ({
      ...prev,
      [name]: {
        customName: value,
      },
    }));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const obj = {};
    for (const [k, v] of formData) {
      obj[k] = {};
      obj[k].customName = v;
    }
    handleAsync(updateSystemInfo.bind(null, { name: 'progressBar', details: obj }), () => mutate());
  };
  return (
    <Paper
      sx={{
        position: 'relative',
        width: '620px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        p: 1,
        gap: 1,
      }}
    >
      <Stack>
        <ProjectStatusBar
          width="120px"
          height="80px"
          barProps={{ position: 'relative', top: 'unset', right: 'unset', m: 'auto' }}
          borderRadius="5px"
          introducedAt={new Date()}
          quotedAt={new Date()}
          negotiatedAt={new Date()}
          preview={progressBarData}
        />
      </Stack>
      <Divider />
      <Stack as="form" id={formId} mt={1} gap={1.5} onSubmit={handleSubmit}>
        <TextField name="intro" value={progressBarData?.intro?.customName} onChange={handleChange} placeholder="介紹" />
        <TextField name="quotation" value={progressBarData?.quotation?.customName} onChange={handleChange} placeholder="報價" />
        <TextField name="negotiation" value={progressBarData?.negotiation?.customName} onChange={handleChange} placeholder="協商" />

        <TextField name="contract" value={progressBarData?.contract?.customName} onChange={handleChange} placeholder="簽約" />
        <TextField name="close" value={progressBarData?.close?.customName} onChange={handleChange} placeholder="結案" />
      </Stack>
      <Stack>
        <Button sx={{ alignSelf: 'flex-end' }} type="submit" form={formId}>
          儲存
        </Button>
      </Stack>
    </Paper>
  );
}

import Icon from '.';

const commonStyle = {
  color: 'white',
  borderRadius: '50%',
  p: 0.1,
  mx: 0.5,
};

export default function GenderIcon(props) {
  return <Icon name="company" sx={{ backgroundColor: 'gray', ...commonStyle }} {...props} />;
}

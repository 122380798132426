import { useState, useCallback, useMemo } from 'react';
import { styled } from '@mui/system';
import { Paper, TextField, Button, Stack, FormControl, InputLabel, Select, Tooltip, MenuItem } from '@mui/material';
import ColorPicker from './ColorPicker';

const PreviewContainer = styled(Paper)({
  flex: 0.5,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 16,
  position: 'relative',

  '&::before': {
    position: 'absolute',
    content: '"按鈕預覽"',
    top: 4,
    left: 4,
    color: '#00000088',
  },

  '&::after': {
    position: 'absolute',
    content: '"按鈕預覽"',
    right: 4,
    bottom: 4,
    color: '#00000088',
  },
});

const FormContainer = styled(Paper)({
  flex: 1.5,
  padding: 16,
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  gap: 8,
  '& > *': {
    width: '100%',
    '&:nth-of-type(1)': {
      width: '50%',
    },
    '&:nth-of-type(2)': {
      width: '23%',
    },
    '&:nth-of-type(3)': {
      width: '23%',
    },
  },
});

export default function RecordFaqForm({ questionSeqs, answerSeqs, onAdd, mode, defaultValue, onUpdate }) {
  const [formData, setFormData] = useState(
    defaultValue
      ? {
          faqType: defaultValue.faqType,
          label: defaultValue.label,
          content: defaultValue.content,
          color: defaultValue.color,
        }
      : {
          faqType: 'question',
          label: '',
          content: '',
          color: 'info',
        },
  );

  const handleChange = useCallback(evt => {
    const { name, value } = evt.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  }, []);

  const handleColorChange = useCallback(value => {
    setFormData(prev => ({ ...prev, color: value }));
  }, []);

  const handleResetForm = useCallback(() => {
    setFormData({ faqType: 'question', label: '', content: '', color: 'info' });
  }, []);

  const handleSubmit = async () => {
    if (!formData.label || !formData.content) {
      alert('請填寫完整');
      return;
    }

    if (mode === 'create') {
      onAdd({
        ...formData,
        seq: formData.faqType === 'question' ? questionSeqs + 1 : answerSeqs + 1,
      });
      handleResetForm();
    }

    if (mode === 'update') {
      onUpdate({ ...formData });
    }
  };

  const ButtonVariant = useMemo(() => (formData.faqType === 'question' ? 'contained' : 'outlined'), [formData.faqType]);

  return (
    <Stack direction="row" spacing={2}>
      <PreviewContainer>
        <Tooltip title={formData.content}>
          <Button variant={ButtonVariant} color={formData.color}>
            {formData.label || '[請填入名稱]'}
          </Button>
        </Tooltip>
        <Button variant={ButtonVariant} color={formData.color} disabled>
          {formData.label || '[請填入名稱]'}
        </Button>
      </PreviewContainer>
      <FormContainer>
        <TextField label="按鈕名稱" name="label" value={formData.label} onChange={handleChange} required />
        <FormControl required>
          <InputLabel>按鈕類別</InputLabel>
          <Select label="按鈕類別" name="faqType" value={formData.faqType} onChange={handleChange}>
            <MenuItem value="question">問題</MenuItem>
            <MenuItem value="answer">回答</MenuItem>
          </Select>
        </FormControl>
        <ColorPicker buttonVariant={ButtonVariant} value={formData.color} onChoose={handleColorChange} />
        <TextField required label="按鈕內容" name="content" value={formData.content} onChange={handleChange} multiline minRows={2} />
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button variant="outlined" color="error" onClick={handleResetForm}>
            清空
          </Button>
          {mode === 'create' && (
            <Button variant="contained" color="warning" onClick={handleSubmit}>
              新增
            </Button>
          )}
          {mode === 'update' && (
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              更新
            </Button>
          )}
        </Stack>
      </FormContainer>
    </Stack>
  );
}

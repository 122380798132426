import { Stack, Typography } from '@mui/material';
import { SearchButton } from './SearchButton';
import Icon from 'components/Icon';

export default function SearchButtonGroup({ formId, onSearch, onClearSearch }) {
  return (
    <Stack direction="row" spacing={2.75} justifyContent="flex-end" flex="1">
      <SearchButton
        color="error"
        variant="outlined"
        startIcon={<Icon name="close" />}
        onClick={onClearSearch}
        {...(formId && { type: 'reset' })}
      >
        <Typography variant="h4">清除搜尋</Typography>
      </SearchButton>
      <SearchButton
        color="warning"
        variant="contained"
        startIcon={<Icon name="search" />}
        {...(!formId && { onClick: onSearch })}
        {...(formId && { type: 'submit', form: formId })}
      >
        <Typography variant="h4">搜尋</Typography>
      </SearchButton>
    </Stack>
  );
}

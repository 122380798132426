import PropTypes from 'prop-types';
import { Dialog, LinearProgress } from '@mui/material';

import AppBar from './AppBar';
import Toolbar from './Toolbar';
import Transition from './Transition';
import { cloneElement } from 'react';

/**
 *
 * @param {function} onClear - Deprecated: not using
 * @returns
 */
export default function FullScreenDialog(props) {
  const { open, onClose, onConfirm, onClear, formId, isConfirmAsSubmit, isLoading, children, title, toolbar } = props;

  return (
    <>
      <Dialog open={open} onClose={onClose} fullScreen TransitionComponent={Transition} PaperProps={{ sx: { backgroundColor: '#FAFAFA' } }}>
        <AppBar>
          {toolbar ? (
            cloneElement(toolbar, { title, onClose })
          ) : (
            <Toolbar
              isConfirmAsSubmit={isConfirmAsSubmit}
              form={formId}
              title={title}
              onConfirm={onConfirm}
              onClose={onClose}
              onClear={onClear}
            />
          )}
          {isLoading && <LinearProgress color="info" sx={{ position: 'absolute', width: '100%', bottom: 0 }} />}
        </AppBar>
        {children}
      </Dialog>
    </>
  );
}

FullScreenDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  formId: PropTypes.string,
  isConfirmAsSubmit: PropTypes.bool,
  isLoading: PropTypes.bool,
  title: PropTypes.string,
};

import { cloneElement, useEffect, useId } from 'react';
import { isEmpty, size } from 'lodash';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  TextField,
  Stack,
  DialogContentText,
  Typography,
  Box,
  Button,
  Backdrop,
  MenuItem,
  CircularProgress,
  ListItemText,
} from '@mui/material';
import { useProjectService } from 'apis/project';
import { useCustomerSourceService } from 'apis/customer';
import { useBatchService } from 'apis/batch';
import useSheetReader from 'libs/hooks/useSheetReader';
import useToggle from 'libs/hooks/useToggle';
import Icon from 'components/Icon';
import ImportPreviewTable from './ImportPreviewTable';
import useAsync from 'libs/hooks/useAsync';
import CUSTOMER_TYPE from 'constant/customerType';

export default function ImportDialog({ button }) {
  const formId = useId();
  const { state, toggle } = useToggle();
  const handleAsync = useAsync();

  const { useAllProject } = useProjectService();
  const { useAllCustomerSource } = useCustomerSourceService();
  const { data: projectsData } = useAllProject({
    limit: 'zero',
    isEnable: true,
  });
  const { data: sourcesData } = useAllCustomerSource();
  const { importCustomer } = useBatchService();

  const { naturalsData, legalsData, handleFile, handleClear, isLoading } = useSheetReader();

  useEffect(() => {
    return () => handleClear();
    // eslint-disable-next-line
  }, [state]);

  const handleChange = evt => {
    handleFile(evt.target.files[0]);
  };

  const handleDrop = evt => {
    evt.preventDefault();
    handleFile(evt.dataTransfer.files[0]);
  };

  const handleSubmit = evt => {
    evt.preventDefault();

    const formData = new FormData(evt.target);
    const form = {};

    for (const [key, value] of formData) {
      if (value) {
        form[key] = value;
      }
    }

    handleAsync(async () => {
      if (size(naturalsData) > 0) {
        await importCustomer({
          custType: CUSTOMER_TYPE[0],
          list: naturalsData,
          ...form,
        });
      }

      if (size(legalsData) > 0) {
        await importCustomer({
          custType: CUSTOMER_TYPE[1],
          list: legalsData,
          ...form,
        });
      }

      toggle();
    });
  };

  return (
    <>
      {cloneElement(button, { onClick: toggle })}

      <Dialog open={state} onClose={toggle} maxWidth="lg" fullWidth>
        <DialogTitle>批次作業 - 名單多筆匯入</DialogTitle>
        <DialogContent>
          <DialogContentText>
            將多筆名單匯入至系統，匯入過程可至 <b>批次作業進度</b> 查看
          </DialogContentText>
          <Box
            href="/Example.xlsx"
            component="a"
            sx={{
              position: 'absolute',
              right: 22,
              top: 20,
              textDecoration: 'none',
            }}
            download
          >
            <Button variant="outlined" color="success">
              下載範例檔案
            </Button>
          </Box>
          <Stack direction="row" sx={{ '& > *': { flex: 1 } }}>
            <form id={formId} onSubmit={handleSubmit}>
              <Stack spacing={1} mt={2}>
                <TextField
                  label="匯入識別用名稱"
                  required
                  name="batchCode"
                  helperText="必填，方便追蹤與識別。範例：XXX公司第一批/XXX學校第二批"
                  defaultValue=""
                />
                <TextField label="名單來源" name="sourceId" required helperText="必填，可於維護項目管理新增" select defaultValue="">
                  <MenuItem value="">名單來源</MenuItem>
                  {sourcesData &&
                    sourcesData.list.map(source => (
                      <MenuItem key={source.id} value={source.id}>
                        {source.sourceName}
                      </MenuItem>
                    ))}
                </TextField>
                <TextField label="專案" name="projectId" helperText="可同時匯入至既有專案，專案可於專案管理頁面新增" select defaultValue="">
                  <MenuItem value="">專案</MenuItem>
                  {projectsData &&
                    projectsData.list.map(project => (
                      <MenuItem key={project.id} value={project.id}>
                        <ListItemText>{project.projectName}</ListItemText>
                        <Typography>{project.isParent ? '母專案' : project.parentProjectId ? '子專案' : ''}</Typography>
                      </MenuItem>
                    ))}
                </TextField>
              </Stack>
            </form>
            {!(isEmpty(naturalsData) && isEmpty(legalsData)) && <ImportPreviewTable data={[...naturalsData, ...legalsData]} />}
          </Stack>
          {isEmpty(naturalsData) && isEmpty(legalsData) ? (
            <>
              <Stack
                component="label"
                alignItems="center"
                justifyContent="center"
                p={2}
                mt={2}
                sx={{
                  // "& input": { display: "none" },
                  backgroundColor: 'primary.main',
                  borderRadius: 1,
                  color: 'primary.contrastText',
                  cursor: 'pointer',
                }}
                onDrop={handleDrop}
                onDragEnter={e => e.preventDefault()}
                onDragOver={e => e.preventDefault()}
              >
                <input id="upload-xlsx-input" type="file" accept=".xlsx" onChange={handleChange} />
                <Icon name="contentPaste" sx={{ fontSize: 65 }} />
                <Typography variant="h4">點擊或拖曳檔案</Typography>
                <Typography variant="body2">支援檔案類型： .xlsx</Typography>
                <Typography variant="body2">必要欄位名稱：(請參閱範例檔案)</Typography>
              </Stack>
            </>
          ) : (
            <Stack>
              <Typography>
                一般客戶：{size(naturalsData)}筆, 法人(公司行號)：
                {size(legalsData)}筆
              </Typography>
              <Button color="warning" variant="contained" form={formId} type="submit">
                上傳
              </Button>
            </Stack>
          )}
        </DialogContent>
        <Backdrop open={isLoading}>
          <CircularProgress />
        </Backdrop>
      </Dialog>
    </>
  );
}

import dayjs from 'dayjs';

export default function dateFormat(date, format) {
  if (!date) {
    return null;
  }

  if (!format) {
    throw new Error('dateFormat: format is required');
  }

  return dayjs(date).format(format);
}

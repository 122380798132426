import dayjs from 'dayjs';
import dateFormat from './date/dateFormat';

export default function getProjectTimeList({ introducedAt, quotedAt, negotiatedAt, signedAt, closedAt }) {
  const introducedAtTime = dateFormat(introducedAt, 'MM/DD');
  const quotedAtTime = dateFormat(quotedAt, 'MM/DD');
  const negotiatedAtTime = dateFormat(negotiatedAt, 'MM/DD');
  const signedAtTime = dateFormat(signedAt, 'MM/DD');
  const closedAtTime = dateFormat(closedAt, 'MM/DD');

  const statusBarCount = closedAtTime ? 5 : signedAtTime ? 4 : negotiatedAtTime ? 3 : quotedAtTime ? 2 : introducedAtTime ? 1 : 0;

  const originalTimeList = [
    { _i: 0, time: introducedAt },
    { _i: 1, time: quotedAt },
    { _i: 2, time: negotiatedAt },
    { _i: 3, time: signedAt },
    { _i: 4, time: closedAt },
  ];

  const timeList = [introducedAtTime, quotedAtTime, negotiatedAtTime, signedAtTime, closedAtTime];

  const latestTime = originalTimeList
    .sort((a, b) => {
      const isTimeLater = dayjs(a.time || 0).isAfter(dayjs(b.time || 0));

      return isTimeLater ? -1 : 1;
    })
    .map(item => ({ ...item, time: dateFormat(item.time, 'MM/DD') }))[0];

  return {
    introducedAtTime,
    quotedAtTime,
    negotiatedAtTime,
    signedAtTime,
    closedAtTime,
    statusBarCount,
    timeList,
    latestTime,
  };
}

import { isBoolean } from 'lodash';

import Icon from '.';

const commonStyle = {
  color: 'white',
  borderRadius: '50%',
  p: 0.1,
  mx: 0.5,
};

export default function GenderIcon(props) {
  const { gender, ...restProps } = props;

  if (!isBoolean(gender)) {
    return null;
  }

  const iconName = gender ? 'male' : 'female';
  const iconColor = gender ? 'primary' : 'error';

  return <Icon name={iconName} sx={{ backgroundColor: `${iconColor}.main`, ...commonStyle }} {...restProps} />;
}

import { styled } from '@mui/system';
import { Box, ButtonBase } from '@mui/material';

export const StyledButtonBase = styled(ButtonBase)({
  padding: '20px 0 20px 10px',
  width: '100%',
  height: '100%',
  alignItems: 'flex-start',
  flexDirection: 'column',
  '& > div': {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    gap: 4,
  },
  userSelect: 'text',
});

export const Container = styled(Box, { shouldForwardProp: prop => prop !== 'count' })(({ count }) => ({
  width: 1120,
  minHeight: 100,
  borderRadius: 5,
  backgroundColor: 'white',
  boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.25)',
  overflow: 'hidden',

  display: 'grid',
  gridTemplateColumns: '2fr 1fr',
  gridTemplateRows: '1fr 1fr',
  gridTemplateAreas: ` "main sub"
    "main ${count === 3 ? 'actions' : 'sub'}"
  `,
  '& > *': {
    width: '100%',
    height: '100%',
  },

  '& > *:nth-of-type(1)': {
    gridArea: 'main',
  },
  '& > *:nth-of-type(2)': {
    paddingRight: 10,
    gridArea: 'sub',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  '& > *:nth-of-type(3)': {
    paddingRight: 10,
    gridArea: 'actions',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 12,
  },
  transition: 'all 0.2s',
  '&:hover': {
    boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.45)',
    transform: 'translateY(-2px) scale(1.005)',
  },
}));

import { useState, cloneElement } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Typography, Select, MenuItem, ListItemText, Button } from '@mui/material';
import useToggle from 'libs/hooks/useToggle';
import { useProjectService } from 'apis/project';
import { useCampaignService } from 'apis/campaign';
import useAsync from 'libs/hooks/useAsync';

export default function JoinProjectDialog({ button, onAdd, customerId }) {
  const { useAllProject } = useProjectService();
  const { addCustomerToCampaign } = useCampaignService();
  const handleAsync = useAsync();
  const { state: open, toggle } = useToggle();

  const [projectId, setProjectId] = useState();

  const { data: projectsData } = useAllProject({ isEnable: true, limit: 'zero' }, null, open);

  const handleAdd = () => {
    handleAsync(
      addCustomerToCampaign.bind(null, projectId, customerId),
      () => onAdd(),
      () => toggle(),
    );
  };

  return (
    <>
      {cloneElement(button, { onClick: toggle })}
      <Dialog open={open} onClose={toggle} maxWidth="sm" fullWidth>
        <DialogTitle>加入專案</DialogTitle>
        <DialogContent>
          <Select fullWidth onChange={e => setProjectId(e.target.value)} MenuProps={{ PaperProps: { style: { maxHeight: '400px' } } }}>
            {projectsData &&
              projectsData.list.map(project => (
                <MenuItem key={`select_opt_${project.id}`} value={project.id}>
                  <ListItemText>{project.projectName}</ListItemText>
                  <Typography>{project.isParent ? '母專案' : project.parentProjectId ? '子專案' : ''}</Typography>
                </MenuItem>
              ))}
          </Select>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAdd} disabled={!projectId}>
            確認
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

import { cloneElement } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Stack,
  Collapse,
  Alert,
  Stepper,
  Step,
  StepLabel,
  StepContent,
} from '@mui/material';
import { isEqual } from 'lodash';
import useToggle from '@build-own-hooks/use-toggle';
import { DialogProvider } from './Provider';
import useAssignStep from './useAssignStep';

import { StepSelectProject, StepSelectFields, StepSelectUser } from './Step';

const steps = [
  {
    key: 'StepSelectProject',
    component: <StepSelectProject />,
  },
  {
    key: 'StepSelectFields',
    component: <StepSelectFields />,
  },
  {
    key: 'StepSelectUser',
    component: <StepSelectUser />,
  },
];

export default function AssignDialog({ button, defaultProjectId }) {
  const { state, step, warning, handleReset, ...rest } = useAssignStep(defaultProjectId);
  const { isOn, toggle, toggleOff } = useToggle({ onOff: handleReset });

  const isStepTwoNoBack = Boolean(defaultProjectId);

  return (
    <>
      {button ? cloneElement(button, { onClick: toggle }) : <Button>客戶名單分配</Button>}
      <Dialog open={isOn} maxWidth="lg" fullWidth>
        <DialogTitle>批次作業 - 客戶名單分配</DialogTitle>
        <DialogContent>
          <DialogContentText>
            將特定專案下且無業務人員之客戶分配給特定業務，分配過程可至 <b>批次作業進度</b> 查看
          </DialogContentText>
          <Stack mt={1}>
            <DialogProvider value={{ state, step, isStepTwoNoBack, ...rest }}>
              <Collapse in={Boolean(warning)}>
                <Alert severity="warning">{warning}</Alert>
              </Collapse>

              <Stepper activeStep={step} orientation="vertical">
                {steps.map((step, index) => (
                  <Step key={step.key}>
                    <StepLabel>
                      {isEqual(0, index) && (
                        <Stack direction="row" spacing={2}>
                          <Stack>選擇專案</Stack>
                          {state.project && (
                            <>
                              <div>{state.project.projectName}</div>
                              <div>{`總數：${state.project.statistics.total} / 已分配：${state.project.statistics.assigned} / 未分配：${
                                state.project.statistics.total - state.project.statistics.assigned
                              }`}</div>
                            </>
                          )}
                        </Stack>
                      )}
                      {isEqual(1, index) && (
                        <Stack direction="row" spacing={2}>
                          <Stack>選擇篩選項目</Stack>
                          <div>{state.fields.map(e => e.displayName).join(', ')}</div>
                        </Stack>
                      )}
                      {isEqual(2, index) && '選擇人員'}
                    </StepLabel>
                    <StepContent>{step.component}</StepContent>
                  </Step>
                ))}
              </Stepper>
            </DialogProvider>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={toggleOff}>
            離開
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

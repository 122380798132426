export default class Formatter {
  static fullPhone(areaCode, phone, ext) {
    let formatted = '';

    if (areaCode) {
      formatted += areaCode;
    }

    if (phone) {
      if (formatted) {
        formatted += '-';
      }
      formatted += phone;
    }

    if (ext) {
      formatted += ' #' + ext;
    }

    return formatted;
  }

  static genderName(gender) {
    return gender ? '男性' : '女性';
  }

  static marriageStatus(marriageStatus) {
    return Boolean(marriageStatus) ? '已婚' : '未婚';
  }

  static fullAddress(zipCode, county, city, street) {
    let formatted = '';

    if (zipCode) {
      formatted += zipCode;
    }
    if (county) {
      formatted += county;
    }
    if (city) {
      formatted += city;
    }
    if (street) {
      formatted += street;
    }

    return formatted;
  }

  static combineTime(hourFrom, minFrom, hourTo, minTo) {
    let formatted = '';

    if (hourFrom) {
      formatted += hourFrom;
    }
    if (minFrom) {
      formatted += ':' + minFrom;
    }
    if (hourTo) {
      formatted += ' - ' + hourTo;
    }
    if (minTo) {
      formatted += ':' + minTo;
    }

    return formatted;
  }

  static combineSource(sources) {
    return sources ? sources.map(source => source.sourceName).join(', ') : '無來源';
  }

  static combineNameWithJobTitle(companyName, department, jobTitle, name) {
    let formatted = '';

    if (companyName) {
      formatted += companyName;
    }
    if (department) {
      if (formatted) formatted += '-';
      formatted += department;
    }
    if (jobTitle) {
      if (formatted) formatted += '-';
      formatted += jobTitle;
    }
    if (name) {
      if (formatted) formatted += '-';
      formatted += name;
    }

    return formatted;
  }
}

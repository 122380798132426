import { Autocomplete, Avatar, Badge, Chip, MenuItem, TextField, Typography } from '@mui/material';
import { useUserService } from 'apis/user';

export default function PrincipleUsersForm({ value, onChange }) {
  const { useAllUser } = useUserService();
  const { data: allUserData } = useAllUser({ isEnable: true }, null);

  return (
    <>
      {allUserData && (
        <Autocomplete
          fullWidth
          multiple
          options={allUserData.list}
          getOptionLabel={option => option.displayName}
          value={value}
          onChange={(e, nv) => onChange(nv)}
          renderInput={params => <TextField {...params} label="人員" />}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          renderOption={(props, option, { selected }) => (
            <MenuItem {...props} sx={{ py: '12px!important' }}>
              <Badge
                color="secondary"
                badgeContent={value.findIndex(e => e.id === option.id) + 1}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                sx={{ '& .MuiBadge-badge': { top: 1, left: 1 } }}
              >
                <Typography pl={2}>{option.displayName}</Typography>
              </Badge>
            </MenuItem>
          )}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                color="info"
                label={option.displayName}
                avatar={<Avatar sx={{ backgroundColor: '#E5E5E5!important' }}>{index + 1}</Avatar>}
                {...getTagProps({ index })}
              />
            ))
          }
        />
      )}
    </>
  );
}

import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@mui/material';

import Icon from 'components/Icon';

export default function TooltipIconButton(props) {
  const { title, color, iconName, onClick, disabled } = props;

  return (
    <Tooltip title={title}>
      <IconButton color={color} onClick={onClick} disabled={disabled}>
        <Icon name={iconName} />
      </IconButton>
    </Tooltip>
  );
}

TooltipIconButton.defaultProps = {
  title: '',
  color: 'default',
  onClick: () => {},
};

TooltipIconButton.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  iconName: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};

import { useState, useEffect, useRef } from 'react';

/**
 * @typedef {Object} useDelayValueReturned
 * @property {*} value
 * @property {*} delayValue
 * @property {function} setValue
 */

/**
 *
 * @param {number} delayTime
 * @returns {useDelayValueReturned} See {@link useDelayValueReturned}
 */
export default function useDelayValue(delayTime = 500) {
  const initRef = useRef(true);

  const [value, setValue] = useState('');
  const [delayValue, setDelayValue] = useState(value);

  useEffect(() => {
    if (initRef.current) {
      initRef.current = false;
      return;
    }

    const timeout = setTimeout(() => setDelayValue(value), delayTime);

    return () => {
      if (initRef.current) return;

      clearTimeout(timeout);
    };
    // eslint-disable-next-line
  }, [value]);

  return { value, delayValue, setValue };
}

import { Buffer } from 'buffer';
import useSWR from 'swr';
import fetcher, { postFetch } from 'apis/fetcher';
import URLs from 'constant/urls';

const URL = URLs.user;

export const useLogin = () => {
  const handleLogin = async (username, password) => {
    if (!username || !password) {
      throw new Error('Username and password are required');
    }

    const token = 'Basic ' + Buffer.from(`${username}:${password}`).toString('base64');

    return await postFetch(URL + '/login', { token });
  };

  return handleLogin;
};

export const useCurrentUser = () => {
  const { data, error, mutate } = useSWR(`${URL}/me`, fetcher, {
    revalidateOnFocus: false,
  });

  return { data, isLoading: !data && !error, isError: error, mutate };
};

import PropTypes from 'prop-types';
import { useMemo, memo } from 'react';
import { Stack, Button, Tooltip } from '@mui/material';
import { useFaqService } from 'apis/faq';

function FaqButtons(props) {
  const { useAllFaq } = useFaqService();
  const { data: faqsData } = useAllFaq({ limit: 'zero', sort: 'seq asc' });

  const question = useMemo(() => {
    if (!faqsData) return [];

    return faqsData.list.filter(faq => faq.faqType === 'question');
  }, [faqsData]);

  const answer = useMemo(() => {
    if (!faqsData) return [];

    return faqsData.list.filter(faq => faq.faqType === 'answer');
  }, [faqsData]);

  return (
    <Stack sx={{ backgroundColor: '#E5E5E550', p: 1, borderRadius: 1 }} spacing={1}>
      <Stack direction="row" gap={1}>
        {question.map(q => (
          <Tooltip key={q.id} title={q.content}>
            <Button color={q.color} variant="contained" onClick={props.onClick.bind(null, q.content)}>
              {q.label}
            </Button>
          </Tooltip>
        ))}
      </Stack>
      <Stack direction="row" gap={1}>
        {answer.map(a => (
          <Tooltip key={a.id} title={a.content}>
            <Button color={a.color} variant="outlined" onClick={props.onClick.bind(null, a.content, false)}>
              {a.label}
            </Button>
          </Tooltip>
        ))}
      </Stack>
    </Stack>
  );
}

export default memo(FaqButtons);

FaqButtons.propTypes = {
  onClick: PropTypes.func.isRequired,
};

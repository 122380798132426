import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { MenuItem, ListItemText, Box, Chip, Typography } from '@mui/material';

import { useUserService } from 'apis/user';
import { useCustomerTagService } from 'apis/customer';
import { useProjectService } from 'apis/project';
import { SearchInput, SearchSelect, SearchButtonGroup } from 'components/SearchForm';
import { useSystemInfoService } from 'apis/systemInfo';
import { visitTypeDisplayName } from 'constant/visitType';
import FreeSoloCompanyName from 'components/Input/Search/FreeSoloCompanyName';
import { FreeSoloCounty, FreeSoloPhone, SelectContactStatus } from 'components/Input/Search';

const defaultState = {
  phone: '',
  companyName: '',
  addressCounty: '',
  gender: '',
  grade: '',
  customerType: '',
  visitType: '',
  projectIds: [],
  ownerUsernames: [],
  tagIds: [],
  lastVisitedResultList: [],
  sort: '',
};

export default function SearchForm(props) {
  const { useAllUser } = useUserService();
  const { useAllProject } = useProjectService();
  const { useAllCustomerTag } = useCustomerTagService();
  const { useOneSystemInfo } = useSystemInfoService();

  const { data: usersData } = useAllUser({ isHierarchy: true, limit: 'zero' });
  const { data: tagsData } = useAllCustomerTag({ limit: 'zero' });
  const { data: projectsData } = useAllProject({
    limit: 'zero',
    isEnable: true,
  });
  const { data: progressBarInfo } = useOneSystemInfo('progressBar');

  const [select, setSelect] = useState(defaultState);

  const handleChange = evt => {
    const { name, value } = evt.target;
    setSelect({ ...select, [name]: value });
  };

  const handleClear = () => {
    props.onClearSearch();
    setSelect(defaultState);
  };

  useEffect(() => {
    const query = props.defaultQuery;

    setSelect({
      phone: query.phone ?? '',
      companyName: query.companyName ?? '',
      addressCounty: query.addressCounty ?? '',
      gender: query.gender ?? '',
      grade: query.grade ?? '',
      customerType: query.customerType ?? '',
      visitType: query.visitType,
      projectIds: query.projectIds ? query.projectIds.split(',') : [],
      ownerUsernames: query.ownerUsernames ? query.ownerUsernames.split(',') : [],
      tagIds: query.tagIds ? query.tagIds.split(',') : [],
      lastVisitedResultList: query.lastVisitedResultList ? query.lastVisitedResultList.split(',') : [],
      sort: query.sort ?? '',
    });
  }, [props.defaultQuery]);

  return (
    <>
      {/* <SearchInput label="電話" name="phone" defaultValue={props.defaultQuery.phone} /> */}
      <FreeSoloPhone
        inputValue={select.phone}
        onInputChange={(e, nv) => handleChange({ target: { name: 'phone', value: nv } })}
        renderInput={p => {
          return <SearchInput label="電話" name="phone" defaultValue={props.defaultQuery.phone} {...p} size="small" />;
        }}
        sx={{ width: '24%' }}
      />
      <SearchInput label="姓名/公司名稱" name="customerName" defaultValue={props.defaultQuery.customerName} />
      <FreeSoloCounty
        inputValue={select.addressCounty}
        onInputChange={(e, nv) => handleChange({ target: { name: 'addressCounty', value: nv } })}
        renderInput={p => {
          return (
            <SearchInput label="地址(縣市)" name="addressCounty" defaultValue={props.defaultQuery.addressCounty} {...p} size="small" />
          );
        }}
        sx={{ width: '24%' }}
      />
      {/* <SearchInput label="地址(縣市)" name="addressCounty" defaultValue={props.defaultQuery.addressCounty} /> */}
      <SearchSelect label="性別" name="gender" value={select.gender} onChange={handleChange}>
        <MenuItem value="">清除</MenuItem>
        <MenuItem value={true}>男性</MenuItem>
        <MenuItem value={false}>女性</MenuItem>
      </SearchSelect>
      <FreeSoloCompanyName
        inputValue={select.companyName}
        onInputChange={(e, nv) => handleChange({ target: { name: 'companyName', value: nv } })}
        renderInput={p => {
          return <SearchInput label="服務機關名稱" name="companyName" defaultValue={props.defaultQuery.companyName} {...p} size="small" />;
        }}
        sx={{ width: '24%' }}
      />
      <SearchInput label="服務機關部門" name="department" defaultValue={props.defaultQuery.department} />
      <SearchInput label="服務機關職稱" name="jobTitle" defaultValue={props.defaultQuery.jobTitle} />
      <SearchSelect label="等級" name="grade" value={select.grade} onChange={handleChange}>
        <MenuItem value="">清除</MenuItem>
        <MenuItem value="normal">一般</MenuItem>
        <MenuItem value="VIP">VIP</MenuItem>
      </SearchSelect>
      <SearchInput label="統一編號" name="taxIdNo" defaultValue={props.defaultQuery.taxIdNo} />
      <SearchInput label="年紀範圍(起)" name="ageStart" defaultValue={props.defaultQuery.ageStart} />
      <SearchInput label="年紀範圍(迄)" name="ageEnd" defaultValue={props.defaultQuery.ageEnd} />
      <SearchSelect label="客戶類別" name="customerType" value={select.customerType} onChange={handleChange}>
        <MenuItem value="">客戶類別</MenuItem>
        <MenuItem value="natural">一般客戶</MenuItem>
        <MenuItem value="legal">法人(公司行號)</MenuItem>
      </SearchSelect>
      <SearchSelect label="訪談進度" name="visitType" value={select.visitType} onChange={handleChange}>
        <MenuItem value="">訪談進度</MenuItem>
        {Object.entries(visitTypeDisplayName).map(([key, value]) => (
          <MenuItem key={key} value={key}>
            {progressBarInfo?.details?.[key]?.customName || value}
          </MenuItem>
        ))}
      </SearchSelect>

      <SearchInput label="專案關鍵字" name="projectName" defaultValue={props.defaultQuery.projectName} />

      <SearchSelect
        width="74.75%"
        label="專案(複選)"
        name="projectIds"
        value={select.projectIds}
        onChange={handleChange}
        onClear={() => setSelect(prev => ({ ...prev, projectIds: [] }))}
        multiple
        renderValue={selected => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map(value => (
              <Chip key={value} label={projectsData.list.find(tag => tag.id === value)?.projectName} />
            ))}
          </Box>
        )}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: '400px',
            },
          },
        }}
      >
        {projectsData &&
          projectsData.list.map(project => (
            <MenuItem key={project.id} value={project.id}>
              <ListItemText>{project.projectName}</ListItemText>
              <Typography>{project.isParent ? '母專案' : project.parentProjectId ? '子專案' : ''}</Typography>
            </MenuItem>
          ))}
      </SearchSelect>
      <SearchInput label="負責業務關鍵字" name="ownerUsername" defaultValue={props.defaultQuery.ownerUsername} />
      <SearchSelect
        multiple
        width="74.75%"
        label="業務(複選)"
        name="ownerUsernames"
        value={select.ownerUsernames}
        onChange={handleChange}
        onClear={() => setSelect(prev => ({ ...prev, ownerUsernames: [] }))}
        sx={{ height: 'auto!important' }}
        renderValue={selected => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map(value => (
              <Chip key={value} label={usersData.list.find(tag => tag.username === value).displayName} />
            ))}
          </Box>
        )}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: '400px',
            },
          },
        }}
      >
        {usersData &&
          usersData.list.map(user => (
            <MenuItem key={user.id} value={user.username}>
              {user.displayName}
            </MenuItem>
          ))}
      </SearchSelect>
      <SelectContactStatus
        name="lastVisitedResultList"
        value={select.lastVisitedResultList}
        onChange={handleChange}
        onClear={() => setSelect(prev => ({ ...prev, lastVisitedResultList: [] }))}
      />
      <SearchSelect
        label="客戶標籤(複選)"
        name="tagIds"
        multiple
        value={select.tagIds}
        onChange={handleChange}
        onClear={() => setSelect(prev => ({ ...prev, tagIds: [] }))}
        renderValue={selected => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
            {selected.map(value => (
              <Chip key={value} label={tagsData.list.find(tag => tag.id === value).tagName} />
            ))}
          </Box>
        )}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: '400px',
            },
          },
        }}
      >
        {tagsData &&
          tagsData.list.map(tag => (
            <MenuItem key={tag.id} value={tag.id}>
              {tag.tagName}
            </MenuItem>
          ))}
      </SearchSelect>
      <SearchSelect label="排序" name="sort" value={select.sort} onChange={handleChange}>
        <MenuItem value="">清除</MenuItem>
        <MenuItem value="name asc">客戶姓名/公司名稱</MenuItem>
        <MenuItem value="department asc">服務機關部門</MenuItem>
      </SearchSelect>
      <SearchButtonGroup formId={props.formId} onClearSearch={handleClear} />
    </>
  );
}

SearchForm.propTypes = {
  formId: PropTypes.string.isRequired,
  onClearSearch: PropTypes.func.isRequired,
  defaultQuery: PropTypes.object,
};

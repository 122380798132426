import { styled } from '@mui/system';
import { Stack } from '@mui/material';

const ContentContainer = styled(Stack, { shouldForwardProp: prop => prop !== 'gap' })(({ theme, gap }) => ({
  flexDirection: 'row',
  width: '1180px',
  margin: '0 auto',
  padding: '0 30px',
  gap: gap ?? 15,
  flexWrap: 'wrap',
}));

export default ContentContainer;

import { createContext, useReducer, useEffect, useContext, useCallback } from 'react';
import { useNavigate, useMatch } from 'react-router-dom';
import { has } from 'lodash';

import apiOptionsHelper from 'apis/apiOptionsHelper';
import useAsync from 'libs/hooks/useAsync';
import URLs from 'constant/urls';

const AuthContext = createContext({ isAuth: false });

const reducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN': {
      localStorage.setItem('token', action.payload.token);
      return { ...state, isAuth: true, ...action.payload };
    }
    case 'LOGOUT': {
      localStorage.removeItem('token');
      return { isAuth: false };
    }
    default: {
      return state;
    }
  }
};

const AuthProvider = ({ children }) => {
  const isLoginPage = useMatch('/login');

  const navigate = useNavigate();
  const handleAsync = useAsync({
    loadingMsg: '驗證中',
    successMsg: '驗證成功',
    errorMsg: '請重新登入',
  });
  const [state, dispatch] = useReducer(reducer, {
    isAuth: false,
  });

  useEffect(() => {
    async function auth() {
      const token = localStorage.getItem('token');
      if (!token) {
        navigate('/login', { replace: true });
        throw new Error('請重新登入');
      }

      const res = await fetch(`${URLs.user}/me`, apiOptionsHelper(token));
      const user = await res.json();

      if (has(user, 'error')) {
        navigate('/login', { replace: true });
        throw new Error('請重新登入');
      }

      dispatch({ type: 'LOGIN', payload: { token, user } });

      if (isLoginPage) {
        navigate('/campaign/list', { replace: true });
      }
    }

    handleAsync(auth);
    // eslint-disable-next-line
  }, [state.token]);

  const logout = useCallback(() => {
    dispatch({ type: 'LOGOUT' });
  }, []);

  return <AuthContext.Provider value={{ ...state, dispatch, logout }}>{children}</AuthContext.Provider>;
};

function useMe() {
  const { user } = useContext(AuthContext);
  return user;
}

function useAuth() {
  return useContext(AuthContext);
}

export { AuthContext, AuthProvider, useMe, useAuth };

import { ActionButton } from 'components/Button';
import ProjectFormDialog from 'components/Project/ProjectFormDialog';

export default function Actions({ view, onViewChange }) {
  return (
    <>
      <ProjectFormDialog
        addNewProject
        button={
          <ActionButton iconName="add" color="info">
            建立新專案
          </ActionButton>
        }
      />
      <ActionButton onClick={onViewChange} iconName={view === 'CARD' ? 'analytics' : 'cards'} color="info">
        檢視{view === 'CARD' ? '分配表' : '卡片列表'}
      </ActionButton>
    </>
  );
}

import { useContext } from 'react';
import { AuthContext } from 'components/context/auth';
import { useGetFetch, postFetch, putFetch, deleteFetch } from 'apis/fetcher';
import URLs from 'constant/urls';
import toQueryString from 'libs/api/toQueryString';

export const useFaqService = () => {
  const serviceURL = URLs.faq;
  const { token } = useContext(AuthContext);

  return {
    useAllFaq: (queryOptions, swrOptions, ableToFetch = true) => {
      const query = toQueryString({ sort: 'seq asc', limit: 'zero', ...queryOptions });
      const fetchURL = ableToFetch ? `${serviceURL}${query}` : null;
      return useGetFetch(fetchURL, swrOptions);
    },

    useOneFaq: (faqId, swrOptions, ableToFetch = true) => {
      const fetchURL = faqId && (ableToFetch ? `${serviceURL}/${faqId}` : null);
      return useGetFetch(fetchURL, swrOptions);
    },

    addNewFaq: async data => {
      try {
        return await postFetch(serviceURL, { data, token });
      } catch (error) {
        throw new Error(error);
      }
    },

    updateFaq: async (faqId, data) => {
      try {
        return await putFetch(`${serviceURL}/${faqId}`, { data, token });
      } catch (error) {
        throw new Error(error);
      }
    },

    deleteFaq: async faqId => {
      try {
        return await deleteFetch(`${serviceURL}/${faqId}`, { token });
      } catch (error) {
        throw new Error(error);
      }
    },
  };
};

export const useProjectFaqService = () => {
  const serviceURL = URLs.projectFaq;

  return {
    useAllProjectFaq: (queryOptions, swrOptions, ableToFetch = true) => {
      const query = toQueryString({ sort: 'seq asc', limit: 'zero', ...queryOptions });
      const fetchURL = ableToFetch ? `${serviceURL}${query}` : null;
      return useGetFetch(fetchURL, swrOptions);
    },
    useOneProjectFaq: (projectFaqId, swrOptions, ableToFetch = true) => {
      const fetchURL = projectFaqId && (ableToFetch ? `${serviceURL}/${projectFaqId}` : null);
      return useGetFetch(fetchURL, swrOptions);
    },
  };
};

import Router from 'router';
import MainContainer from 'components/UI/layout/MainContainer';
import { AuthProvider } from 'components/context/auth';

function App() {
  return (
    <MainContainer>
      <AuthProvider>
        <Router />
      </AuthProvider>
    </MainContainer>
  );
}

export default App;

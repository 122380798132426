import { styled } from '@mui/system';
import { TextField } from '@mui/material';

const SizedInput = styled(TextField, { shouldForwardProp: prop => prop !== 'noAutoMargin' })(({ noAutoMargin }) => ({
  width: '520px',
  margin: '0 0 11px 0' + (noAutoMargin ? '!important' : ''),
  '& label': {
    backgroundColor: 'white',
    borderRadius: 5,
    padding: '0 3rem 0 0',
    // transition: "all 0.5s ease-in-out",
    '&.Mui-focused': {
      margin: '0 0 0 -5px',
      padding: '0 8px',
      backgroundColor: 'white',
    },
    '&.MuiInputLabel-shrink': {
      margin: '0 0 0 -5px',
      padding: '0 8px',
      backgroundColor: 'white',
    },
  },
  '&:nth-of-type(2n)': {
    marginLeft: 20,
  },
  '&:nth-of-type(2n + 1)': {
    marginRight: 20,
  },
}));

export default SizedInput;

import { styled } from '@mui/system';
import { Stack, TableCell, TableRow } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';

export const Section = styled(Stack)({
  width: '100%',
  padding: 16,
  gap: 16,
  backgroundColor: 'white',
  flexWrap: 'wrap',
});

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
  textAlign: 'center',
  borderRight: '1px solid white',
  borderBottom: 'unset',
  '&:last-of-type': {
    borderRight: 'unset',
  },
}));

import { MenuItem, Box, Chip } from '@mui/material';
import { SearchSelect } from 'components/SearchForm';
import SystemDataProvider from '../DataProvider/SystemDataProvider';

export default function SearchSelectContactStatus(p) {
  return (
    <SystemDataProvider systemDataName="contactStatus">
      {({ data }) => (
        <SearchSelect
          label="聯絡狀態/訪談結果(複選)"
          multiple
          renderValue={selected => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map(value => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={{
            PaperProps: {
              style: {
                maxHeight: '400px',
              },
            },
          }}
          {...p}
        >
          {data?.map(item => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </SearchSelect>
      )}
    </SystemDataProvider>
  );
}

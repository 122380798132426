import fontColorContrast from 'font-color-contrast';
import { useState } from 'react';
import { styled } from '@mui/system';
import { Stack, Button, Menu, MenuItem } from '@mui/material';
import { colorNames, colorNameTable } from 'constant/color';
const StyledMenuItem = styled(MenuItem, {
  shouldForwardProp: prop => prop !== 'customColor' && prop !== 'variant',
})(({ theme, variant, customColor }) => {
  const color = theme.palette[customColor].main;
  const contrastColor = fontColorContrast(color);

  const commonStyle = {
    margin: '4px 8px',
    borderRadius: '4px',
  };

  const hoverEffect = {
    '&:hover': {
      backgroundColor: `${color}55`,
      borderColor: color,
    },
  };

  if (variant === 'contained') {
    return {
      backgroundColor: color,
      color: contrastColor,
      ...commonStyle,
      ...hoverEffect,
    };
  }

  if (variant === 'outlined') {
    return {
      border: '1px solid',
      borderColor: color,
      color: color,
      ...commonStyle,
      ...hoverEffect,
    };
  }
});
export default function ColorPicker({ buttonVariant = 'outlined', value, onChoose }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <Stack direction="row">
      <Button fullWidth onClick={handleClick} variant={buttonVariant} color={value} disableElevation>
        點擊選擇顏色
      </Button>
      <Menu id="basic-menu" anchorEl={anchorEl} open={open} onClose={handleClose}>
        {colorNames.map(color => (
          <StyledMenuItem
            key={color}
            onClick={() => {
              onChoose(color);
              handleClose();
            }}
            variant={buttonVariant}
            customColor={color}
          >
            {colorNameTable[color]}
          </StyledMenuItem>
        ))}
      </Menu>
    </Stack>
  );
}

import { TextField, MenuItem } from '@mui/material';
import SystemDataProvider from '../DataProvider/SystemDataProvider';

export default function SelectContactStatus(props) {
  return (
    <SystemDataProvider systemDataName="contactStatus">
      {({ data }) => (
        <TextField label="聯絡狀態/訪談結果" select fullWidth {...props}>
          {data?.map(item => (
            <MenuItem key={item} value={item}>
              {item}
            </MenuItem>
          ))}
        </TextField>
      )}
    </SystemDataProvider>
  );
}

import { useState } from 'react';
import { styled } from '@mui/system';
import { Stack, Typography, TextField, Button, Select, MenuItem } from '@mui/material';

import { ADDRESS, COUNTY_LIST, COUNTY_TOWN_MAP } from 'constant/addressData';

const AddressContainer = styled(Stack)({
  marginBottom: 20,
  width: '100%',
});
const TitleSection = styled(Stack)({
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingBottom: 6,
  '& p': {
    fontSize: 18,
    color: '#6E6E6E',
  },
});
const InputSection = styled(Stack)({
  flexDirection: 'row',
  gap: 26,
  '& > *': {
    flex: '1 0 150px',
    '&:nth-of-type(1)': {
      flex: '1 0 80px',
    },
    '&:nth-of-type(4)': {
      flex: '1 0 550px',
    },
  },
});

function AddressInputs({ formData, addressType, onChange }) {
  const zipCodeKey = `${addressType}AddressZipCode`;
  const countyKey = `${addressType}AddressCounty`;
  const cityKey = `${addressType}AddressCity`;
  const streetKey = `${addressType}AddressStreet`;

  let isCountyValueOutOfList = false;
  //if formData[key] is not exist in the list
  if (formData[countyKey] && !COUNTY_LIST.find(county => county === formData[countyKey])) {
    isCountyValueOutOfList = true;
  }

  let isCityValueOutOfList = false;
  if (
    formData[cityKey] &&
    !isCountyValueOutOfList &&
    !COUNTY_TOWN_MAP[formData[countyKey]]?.find(town => town === formData[cityKey])
  ) {
    isCityValueOutOfList = true;
  }

  return (
    <>
      <TextField
        label="郵遞區號"
        name={zipCodeKey}
        value={
          formData[zipCodeKey] ||
          (formData[cityKey] &&
            ADDRESS.find(item => item.countyName === formData[countyKey])?.towns?.find(
              town => town.townName === formData[cityKey],
            )?.zipCode)
        }
        onChange={onChange}
      />
      <Select name={countyKey} value={formData[countyKey]} onChange={onChange}>
        {COUNTY_LIST.map(county => (
          <MenuItem key={`${addressType}_opt_${county}`} value={county}>
            {county}
          </MenuItem>
        ))}
        {isCountyValueOutOfList && <MenuItem value={formData[countyKey]}>{formData[countyKey]}</MenuItem>}
      </Select>
      <Select name={cityKey} value={formData[cityKey]} onChange={onChange}>
        {formData[countyKey]
          ? COUNTY_TOWN_MAP[formData[countyKey]]?.map(town => (
              <MenuItem key={`${addressType}_opt_${town}`} value={town}>
                {town}
              </MenuItem>
            ))
          : null}
        {(isCityValueOutOfList || isCountyValueOutOfList) && (
          <MenuItem value={formData[cityKey]}>{formData[cityKey]}</MenuItem>
        )}
      </Select>
      <TextField label="街道" name={streetKey} value={formData[streetKey]} onChange={onChange} />
    </>
  );
}

export default function AddressForm({ data, addNewCustomer }) {
  const [formData, setFormData] = useState(
    addNewCustomer
      ? {
          communicationAddressZipCode: '',
          communicationAddressCounty: '',
          communicationAddressCity: '',
          communicationAddressStreet: '',
          shipAddressZipCode: '',
          shipAddressCounty: '',
          shipAddressCity: '',
          shipAddressStreet: '',
          invoiceAddressZipCode: '',
          invoiceAddressCounty: '',
          invoiceAddressCity: '',
          invoiceAddressStreet: '',
        }
      : {
          communicationAddressZipCode: data.communicationAddressZipCode ?? '',
          communicationAddressCounty: data.communicationAddressCounty ?? '',
          communicationAddressCity: data.communicationAddressCity ?? '',
          communicationAddressStreet: data.communicationAddressStreet ?? '',
          shipAddressZipCode: data.shipAddressZipCode ?? '',
          shipAddressCounty: data.shipAddressCounty ?? '',
          shipAddressCity: data.shipAddressCity ?? '',
          shipAddressStreet: data.shipAddressStreet ?? '',
          invoiceAddressZipCode: data.invoiceAddressZipCode ?? '',
          invoiceAddressCounty: data.invoiceAddressCounty ?? '',
          invoiceAddressCity: data.invoiceAddressCity ?? '',
          invoiceAddressStreet: data.invoiceAddressStreet ?? '',
        },
  );

  const handleChange = e => {
    const { name, value } = e.target;

    //若改縣市，郵遞區號跟區 (zipcode & city)套空
    if (name.includes('County')) {
      setFormData({
        ...formData,
        [name]: value,
        [name.replace('County', 'ZipCode')]: '',
        [name.replace('County', 'City')]: '',
      });
      return;
    }

    setFormData({ ...formData, [name]: value });
  };

  const handleSyncAsCommunicationAddress = name => e => {
    setFormData(prev => ({
      ...prev,
      [`${name}ZipCode`]: prev.communicationAddressZipCode,
      [`${name}County`]: prev.communicationAddressCounty,
      [`${name}City`]: prev.communicationAddressCity,
      [`${name}Street`]: prev.communicationAddressStreet,
    }));
  };

  return (
    <>
      <AddressContainer>
        <TitleSection>
          <Typography>通訊地址</Typography>
          <Typography>郵遞區號將會依據行政區自動帶入，地址須完整填入才會保存</Typography>
        </TitleSection>
        <InputSection>
          <AddressInputs formData={formData} addressType="communication" onChange={handleChange} />
        </InputSection>
      </AddressContainer>
      <AddressContainer>
        <TitleSection>
          <Typography>送貨地址</Typography>
          <Button variant="outlined" onClick={handleSyncAsCommunicationAddress('shipAddress')}>
            同步為通訊地址
          </Button>
        </TitleSection>
        <InputSection>
          <AddressInputs formData={formData} addressType="ship" onChange={handleChange} />
        </InputSection>
      </AddressContainer>
      <AddressContainer>
        <TitleSection>
          <Typography>發票地址</Typography>
          <Button variant="outlined" onClick={handleSyncAsCommunicationAddress('invoiceAddress')}>
            同步為通訊地址
          </Button>
        </TitleSection>
        <InputSection>
          <AddressInputs formData={formData} addressType="invoice" onChange={handleChange} />
        </InputSection>
      </AddressContainer>
    </>
  );
}

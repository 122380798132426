import { createContext, useContext } from 'react';

const DialogContext = createContext({});

export function DialogProvider(props) {
  return <DialogContext.Provider {...props} />;
}

export function useDialogContext() {
  return useContext(DialogContext);
}

import { styled } from '@mui/material';
import { Stack } from '@mui/material';

export const Block = styled(Stack)({
  position: 'relative',
  zIndex: 2,

  width: 65,
  height: '100%',
  padding: '2px 0',

  color: 'white',
  backgroundColor: '#DEDBDB',

  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',

  fontSize: 14,
  fontWeight: 700,
  '& span': { fontWeight: 400 },
});

export const Bar = styled(Stack, {
  shouldForwardProp: prop => prop !== 'count' || prop !== 'borderRadius',
})(({ count = 1, borderRadius }) => ({
  height: 55,
  background: '#DEDBDB',
  flexDirection: 'row',
  alignItems: 'center',
  borderRadius: borderRadius || '0 0 0 5px',

  position: 'absolute',
  top: 0,
  right: 0,

  '&::before': {
    content: '""',
    position: 'absolute',
    background: 'red',
    borderRadius: borderRadius || '0 0 0px 5px',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    backgroundImage: 'linear-gradient(to right, #6CD6EC, #89DFCA)',
    zIndex: 1,
  },
  [`& > div:nth-of-type(-n + ${count})`]: {
    backgroundColor: 'transparent',
  },
  '& > div:first-of-type': {
    borderRadius: borderRadius || '0 0 0 5px',
  },
  '& > div:last-of-type': {
    borderRadius: borderRadius,
  },
}));

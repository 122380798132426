import { useMemo } from 'react';
import { useSystemInfoService } from 'apis/systemInfo';

/**
 * @param {Object} props
 * @param {("contactStatus"|"progressBar")} props.systemDataName
 * @param {Function} props.children
 */
export default function SystemDataProvider({ systemDataName, children }) {
  const { useOneSystemInfo } = useSystemInfoService();
  const { data } = useOneSystemInfo(systemDataName);

  const details = useMemo(() => {
    if (!data || !data.details) return null;

    return data.details;
  }, [data]);

  return children({ data: details });
}

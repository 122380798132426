import { useState } from 'react';
import { useProductCategoryService } from 'apis/product';

import useAsync from 'libs/hooks/useAsync';
import {
  TableContainer,
  Paper,
  TableHead,
  TableRow,
  TableBody,
  TableCell,
  TextField,
  Table,
  Button,
  IconButton,
  Checkbox,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import MaintainContainer from 'components/UI/layout/MaintainContainer';

const ListItem = ({ category, onUpdate }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const [input, setInput] = useState({
    categoryName: category.categoryName,
    isEnable: category.isEnable,
  });

  const handleToggleEditMode = () => {
    setIsEditMode(prev => !prev);
  };

  const handleChange = e => {
    if (e.target.name === 'isEnable') {
      setInput({ ...input, [e.target.name]: e.target.checked });
      return;
    }

    setInput({ ...input, [e.target.name]: e.target.value });
  };

  if (isEditMode) {
    return (
      <TableRow hover>
        <TableCell>
          <TextField size="small" name="categoryName" value={input.categoryName} onChange={handleChange} />
        </TableCell>
        <TableCell align="center">
          <Checkbox name="isEnable" checked={input.isEnable} onChange={handleChange} />
        </TableCell>
        <TableCell align="right">
          <IconButton size="small" onClick={onUpdate.bind(null, input, handleToggleEditMode)}>
            <SaveIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow hover>
      <TableCell>{category.categoryName}</TableCell>
      <TableCell align="center">{category.isEnable ? '是' : '否'}</TableCell>
      <TableCell align="right">
        <IconButton size="small" onClick={handleToggleEditMode}>
          <EditIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  );
};

export default function ProductCategory() {
  const handleAsync = useAsync();
  const { useAllProductCategory, addProductCategory, updateProductCategory } = useProductCategoryService();
  const { data: categoryData, mutate } = useAllProductCategory();

  const [input, setInput] = useState('');

  const handleChange = event => {
    setInput(event.target.value);
  };

  const handleAdd = () => handleAsync(addProductCategory.bind(null, { categoryName: input }), mutate);

  const handleUpdate = (categoryId, data, doneFunc) => handleAsync(updateProductCategory.bind(null, categoryId, data), mutate, doneFunc);

  return (
    <MaintainContainer title="商品類別管理">
      <Paper sx={{ p: 2, display: 'flex', gap: 2, justifyContent: 'space-between' }}>
        <TextField fullWidth label="商品類別" color="warning" value={input} onChange={handleChange} />
        <Button variant="contained" color="warning" onClick={handleAdd}>
          新增
        </Button>
      </Paper>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>商品類別</TableCell>
              <TableCell align="center">啟用中</TableCell>
              <TableCell align="right">動作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {categoryData &&
              categoryData.list.map(category => (
                <ListItem key={category.id} category={category} onUpdate={handleUpdate.bind(null, category.id)} />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </MaintainContainer>
  );
}

import { useContext } from 'react';
import { SWRConfig } from 'swr';

import { AuthContext } from 'components/context/auth';
import apiOptionsHelper from 'apis/apiOptionsHelper';

const getToken = useSWRNext => {
  return (key, fetcher, config) => {
    // Before hook runs...
    const { token } = useContext(AuthContext);
    const apiOptions = apiOptionsHelper(token);

    // Handle the next middleware, or the `useSWR` hook if this is the last one.
    const swr = useSWRNext(key && token ? [key, apiOptions] : null, fetcher, config);

    // After hook runs...
    return swr;
  };
};

export default function SWRMiddlewareToken(props) {
  return <SWRConfig value={{ use: [getToken] }} {...props} />;
}

import PropTypes from 'prop-types';
import { Chip } from '@mui/material';

import { styleProps } from './style';

/**
 *
 * @param {Object} props
 * @param {("source"|"project"|"ownerUser"|"noOwnerUser"|"time"|"phone")} props.type
 */
export default function InformationLabel(props) {
  const { type, ...restProps } = props;

  return <Chip {...styleProps(type)} {...restProps} />;
}

InformationLabel.propTypes = {
  type: PropTypes.oneOf(['source', 'project', 'ownerUser', 'noOwnerUser', 'time', 'phone']).isRequired,
};

import * as React from 'react';
import { Stack, Alert, AlertTitle } from '@mui/material';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // 更新 state 以至於下一個 render 會顯示 fallback UI

    return { hasError: true, message: error.message };
  }

  render() {
    if (this.state.hasError) {
      // 你可以 render 任何客製化的 fallback UI
      if (this.props.fallback) {
        return this.props.fallback;
      }

      return (
        <Stack alignItems="center" justifyContent="center" width="100%">
          <Alert severity="error">
            <AlertTitle>發生錯誤</AlertTitle>
            請回報給開發人員以下訊息，截圖回報以及說明剛剛操作流程。
            <br />
            錯誤訊息：<code>{this.state.message}</code>
          </Alert>
        </Stack>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

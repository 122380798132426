import dayjs from 'dayjs';
import { Stack, Typography } from '@mui/material';
import Icon from 'components/Icon';

import TabContainer from './TabContainer';
import InboxTab from './InboxTab';

const TodayIcon = props => (
  <Stack sx={{ w: 3, h: 3, position: 'relative' }} {...props}>
    <Icon name="calendarToday" />
    <Typography
      sx={{
        margin: 'auto',
        position: 'absolute',
        fontSize: 12,
        fontWeight: 'bolder',
        top: 6,
        left: 4.5,
      }}
    >
      {dayjs().format('DD')}
    </Typography>
  </Stack>
);

export default function Tabs({ currentTab, onTabChange }) {
  return (
    <TabContainer>
      <Typography variant="h3">待辦事項</Typography>
      <Stack direction="row" alignItems="flex-end" justifyContent="space-between" width="100%" position="relative">
        <InboxTab onClick={() => onTabChange(1)} on={currentTab === 1}>
          <Icon className="icon" name="calendarHistory" />
          <Typography className="title" variant="h6">
            過去事項
          </Typography>
        </InboxTab>
        <InboxTab onClick={() => onTabChange(2)} on={currentTab === 2}>
          <TodayIcon className="icon" />
          <Typography className="title" variant="h6">
            今日事項
          </Typography>
        </InboxTab>
        <InboxTab onClick={() => onTabChange(3)} on={currentTab === 3}>
          <Icon className="icon" name="calendarMonth" />
          <Typography className="title" variant="h6">
            未來事項
          </Typography>
        </InboxTab>
      </Stack>
    </TabContainer>
  );
}

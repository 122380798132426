import { useState } from 'react';
import { Stack, Autocomplete, Checkbox, TextField, FormControlLabel, Alert, Typography } from '@mui/material';
import { useProjectService } from 'apis/project';

export default function ProjectProperty(props) {
  /**
   * case:
   * [1,0,0]
   * [0,1,0]
   * [0,0,1]
   * 專案一定要有一種性質
   */
  const [projectProperty, setProjectProperty] = useState(() => {
    if (props.projectData) {
      if (props.projectData.isParent) {
        return [false, true, false];
      }
      if (props.projectData.parentProjectId) {
        return [false, false, true];
      }
    }
    return [true, false, false];
  });
  const [normal, AsParent, AsChild] = projectProperty;

  const { useAllProject } = useProjectService();

  const [chooseProject, setChooseProject] = useState(props?.projectData?.parentProjectId ? props.projectData.parentProject : null);
  const [searchQuery, setSearchQuery] = useState('');

  const { data: projectsData } = useAllProject({ isParent: true, limit: 30, projectName: searchQuery, isEnable: true }, null, AsChild);

  //radio
  const handleChange = index => evt => {
    setProjectProperty(prev => prev.map((e, i) => (i === index ? true : false)));

    const obj = {};
    if (index === 0 || index === 1) {
      setChooseProject(null);
    }
    if (index === 1) {
      obj.isParent = true;
    }
    if (index === 2) {
      obj.parentProject = chooseProject;
    }

    props.onChange(obj);
  };

  //parent project selector
  const handleChooseProject = (e, n) => {
    setChooseProject(n);

    props.onChange({ parentProject: n });
  };

  return (
    <Stack sx={{ width: '100%' }}>
      <Alert severity="warning" variant={props.addNewProject ? 'filled' : 'outlined'} sx={{ width: '100%', mb: 1 }}>
        {props.addNewProject ? '專案建立後無法變更專案性質' : '專案性質無法變更'}
      </Alert>
      <FormControlLabel
        checked={normal}
        onChange={handleChange(0)}
        control={<Checkbox />}
        label="一般專案"
        disabled={!props.addNewProject}
      />
      <FormControlLabel
        checked={AsParent}
        onChange={handleChange(1)}
        control={<Checkbox />}
        label="母專案"
        disabled={!props.addNewProject}
      />
      <Stack direction="row" alignItems="center" spacing={1}>
        <FormControlLabel
          checked={AsChild}
          onChange={handleChange(2)}
          control={<Checkbox />}
          label="子專案"
          disabled={!props.addNewProject}
        />
        <Autocomplete
          disabled={!AsChild || !props.addNewProject}
          size="small"
          sx={{ width: '500px' }}
          value={chooseProject}
          onChange={handleChooseProject}
          inputValue={searchQuery}
          onInputChange={(e, n) => setSearchQuery(n)}
          options={projectsData ? projectsData.list : []}
          isOptionEqualToValue={(opt, val) => opt.id === val.id}
          getOptionLabel={option => option.projectName}
          renderInput={params => <TextField {...params} label="選擇母專案" />}
        />
        {chooseProject && (
          <Typography>
            專案日期：{chooseProject.startDate}~{chooseProject.endDate}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
}

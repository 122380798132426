import { useState } from 'react';
import { Box, Tabs, Tab, Stack, Alert } from '@mui/material';

import MainContainer from 'components/UI/layout/MaintainContainer';
import ProgressBar from './ProgressBar';
import ContactStatus from './ContactStatus';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" {...other}>
      {value === index && <Box sx={{ px: 1, py: 3, backgroundColor: '#FFFFFF50' }}>{children}</Box>}
    </div>
  );
}

export default function SystemInfo() {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <MainContainer title="系統設定" noDivider>
      <Alert severity="warning">為確保系統設定能確實套用，每項設定需記得個別儲存並確認結果</Alert>
      <Stack>
        <Tabs
          variant="scrollable"
          value={value}
          onChange={handleChange}
          aria-label="Vertical tabs example"
          sx={{ borderBottom: 1, borderColor: 'divider', backgroundColor: 'white' }}
        >
          <Tab label="進度條文字" />
          <Tab label="聯絡狀態/訪談結果文字" />
        </Tabs>
        <TabPanel value={value} index={0}>
          <ProgressBar />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ContactStatus />
        </TabPanel>
      </Stack>
    </MainContainer>
  );
}

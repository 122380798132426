import { useContext } from 'react';
import { AuthContext } from 'components/context/auth';

export default function useUserAbility() {
  const { user } = useContext(AuthContext);

  return {
    isAssign: user?.isAssign,
  };
}

import PropTypes from 'prop-types';
import dayjs from 'dayjs';

import { Stack, Typography } from '@mui/material';

import Formatter from 'libs/fomatter';
import { LevelLabel, InformationLabel, TagLabel, PrincipleUserLabel } from 'components/Label';
import GenderIcon from 'components/Icon/GenderIcon';
import CompanyIcon from 'components/Icon/CompanyIcon';
import { ActionButton } from 'components/Button';
import ListItem from 'components/ListItem';
import { CustomerFormDialog } from '.';

export default function CustomerListItem(props) {
  const latestInteractProject = props?.projectCustomers?.sort(
    (a, b) => new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime(),
  )[0];

  const isLegal = props.customerType === 'legal';
  const isNatural = props.customerType === 'natural';

  return (
    <ListItem>
      <ListItem.MainContent>
        <Stack>
          <Typography variant="h4">
            {Formatter.combineNameWithJobTitle(props.companyName, props.department, props.jobTitle, props.name)}
          </Typography>
          {isNatural && <GenderIcon gender={props.gender} />}
          {isLegal && <CompanyIcon />}
        </Stack>
        <Stack>
          <Typography>
            {isNatural && props.phone + ' / '}
            {Formatter.fullPhone(props.localAreaCode, props.localPhone, props.localExt)}
          </Typography>
        </Stack>
        <Stack mb={0.5}>
          {props?.principalUsers && <PrincipleUserLabel users={props.principalUsers} />}
          {props.grade && <LevelLabel isVip={props.grade === 'VIP'} />}
          <InformationLabel type="source" label={Formatter.combineSource(props.sources)} />
          {props.tags && props.tags.map(tag => <TagLabel key={tag.id} tag={tag} />)}
        </Stack>
        <Stack>
          <InformationLabel type="project" label={latestInteractProject?.project?.projectName || '未加入任一專案'} />

          {latestInteractProject && (
            <>
              {latestInteractProject.ownerUsername ? (
                <InformationLabel type="ownerUser" label={latestInteractProject.ownerUser.displayName} />
              ) : (
                <InformationLabel type="noOwnerUser" label={'尚無業務接洽'} />
              )}
              <InformationLabel
                type="time"
                label={
                  latestInteractProject.lastVisitedAt
                    ? dayjs(latestInteractProject.lastVisitedAt).format('YYYY/MM/DD HH:mm:ss') +
                      ` (${latestInteractProject.lastVisitedUsername})`
                    : '尚無聯絡紀錄'
                }
              />
            </>
          )}
        </Stack>
      </ListItem.MainContent>
      <ListItem.Actions gap={1}>
        <CustomerFormDialog
          customerId={props.id}
          button={
            <ActionButton iconName="edit" color="info">
              編輯客戶資料
            </ActionButton>
          }
        />
        <ActionButton iconName="arrowRight" color="primary" onClick={props.onNav}>
          前往客戶資訊
        </ActionButton>
      </ListItem.Actions>
    </ListItem>
  );
}

CustomerListItem.propTypes = {
  onNav: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  phone: PropTypes.string,
  localAreaCode: PropTypes.string,
  localPhone: PropTypes.string,
  localExt: PropTypes.string,
  gender: PropTypes.bool,
  grade: PropTypes.string,
  sources: PropTypes.arrayOf(
    PropTypes.shape({
      sourceName: PropTypes.string,
    }),
  ),
  companyName: PropTypes.string,
  department: PropTypes.string,
  jobTitle: PropTypes.string,
  projectCustomers: PropTypes.arrayOf(
    PropTypes.shape({
      projectId: PropTypes.string,
      project: PropTypes.shape({
        id: PropTypes.string,
        projectName: PropTypes.string,
      }),
      ownerUsername: PropTypes.string,
      ownerUser: PropTypes.shape({
        displayName: PropTypes.string,
      }),
      updateAt: PropTypes.string,
      lastVisitedUsername: PropTypes.string,
      lastVisitedAt: PropTypes.string,
      introducedAt: PropTypes.string,
      quotedAt: PropTypes.string,
      negotiatedAt: PropTypes.string,
      signedAt: PropTypes.string,
      closedAt: PropTypes.string,
    }),
  ),
};

import { Toolbar as Bar, IconButton, Typography } from '@mui/material';
import Icon from 'components/Icon';
import { CustomerFormDialog } from 'components/Customer';

export default function Toolbar(props) {
  return (
    <Bar sx={{ height: '100%' }}>
      <IconButton edge="start" color="inherit" onClick={props.onClose}>
        <Icon name="close" />
      </IconButton>
      <Typography sx={{ ml: 1, flex: 1, whiteSpace: 'pre-wrap' }} variant="h4" component="div">
        {props.title}
      </Typography>
      <CustomerFormDialog customerId={props.customerId} />
    </Bar>
  );
}

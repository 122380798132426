import { HelmetProvider } from 'react-helmet-async';
import ThemeProvider from './theme';
import ToastProvider from './toast';
import { LoadingBackdropProvider } from 'components/context/loadingBackdrop';

export default function HocProviders({ children }) {
  return (
    <ThemeProvider>
      <ToastProvider />
      <HelmetProvider>
        <LoadingBackdropProvider>{children}</LoadingBackdropProvider>
      </HelmetProvider>
    </ThemeProvider>
  );
}

import { Stack, Chip, Typography } from '@mui/material';

export default function CustomChip({ label, text, ...props }) {
  return (
    <Stack direction="row" alignItems="center" spacing={0.5}>
      <Chip label={label} size="small" sx={{ minWidth: 53, minHeight: 32, fontSize: 16, color: '#3E3E3E' }} />
      <Typography variant="body1">{text || '無填寫'}</Typography>
    </Stack>
  );
}

import { useEffect, useId } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { useCampaignService } from 'apis/campaign';
import useSearch from 'libs/hooks/useSearch';
import ContentContainer from 'components/UI/layout/ContentContainer';
import PageContainer from 'components/UI/layout/PageContainer';
import PaginatorContainer from 'components/UI/layout/PaginatorContainer';
import SearchContainer from 'components/UI/layout/SearchContainer';
import ListItem from 'components/ListItem';
import { CampaignListItem } from 'components/Campaign';
import Actions from './Actions';
import SearchForm from './SearchForm';
import useRoleCheck from 'libs/hooks/useRoleCheck';

export default function CampaignListPage() {
  const formId = useId();

  const isRoleHasRightAs = useRoleCheck();
  const navigate = useNavigate();
  const { useAllCampaign } = useCampaignService();

  //0919目前只限制在行銷管理頁面(此頁面)
  //將搜尋項目丟入URL，使用者回來時能夠繼續保留上次搜尋項目以及搜尋結果
  const [searchParams, setSearchParams] = useSearchParams();

  //這個query則不trigger 搜尋，但批次作業會使用到
  const { page, limit, query, handleChangePage, handleChangeLimit, handleChangeQuery } = useSearch();
  const {
    data: campaignsData,
    isLoading,
    mutate,
  } = useAllCampaign({ page, limit, rawString: searchParams.toString() + '&sort=last_visited_at desc, name asc' });

  const handleToCustomerPage = customerId => {
    navigate(`/customer/${customerId}`);
  };

  const handleSearch = evt => {
    evt.preventDefault();
    const formData = new FormData(evt.target);
    const queryObj = {};
    for (const [k, v] of formData) {
      if (v === '') {
        continue;
      }
      queryObj[k] = v;
    }
    handleChangePage(1);
    handleChangeQuery(queryObj);
    setSearchParams(queryObj);
  };

  const handleClearSearch = () => {
    handleChangeQuery({});
    setSearchParams({});
  };

  useEffect(() => {
    const queryObj = {};
    for (const [k, v] of searchParams) {
      if (v === '') {
        continue;
      }
      queryObj[k] = v;
    }
    handleChangeQuery(queryObj);
    // eslint-disable-next-line
  }, []);

  return (
    <PageContainer>
      <SearchContainer color="secondary">
        <form id={formId} onSubmit={handleSearch}>
          <ContentContainer>
            <SearchForm key={JSON.stringify(query)} formId={formId} onClearSearch={handleClearSearch} defaultQuery={query} />
          </ContentContainer>
        </form>
      </SearchContainer>
      <ContentContainer justifyContent="center">
        <PaginatorContainer
          totalPage={campaignsData?.totalPage || 0}
          currentPage={page}
          limit={limit}
          onPageChange={handleChangePage}
          onLimitChange={handleChangeLimit}
          totalCount={campaignsData?.totalCount || 0}
          onRefresh={() => mutate(null)}
          isLoading={isLoading}
          actions={isRoleHasRightAs('MANAGER') && <Actions query={query} totalCount={campaignsData?.totalCount || 0} />}
        >
          {isLoading && Array.from({ length: 5 }, (_, i) => <ListItem.Skeleton key={i} />)}
          {campaignsData &&
            campaignsData.list.map(campaign => (
              <CampaignListItem
                key={`${campaign.projectId}_${campaign.customerId}`}
                {...campaign}
                onNav={handleToCustomerPage.bind(null, campaign.customerId)}
              />
            ))}
        </PaginatorContainer>
      </ContentContainer>
    </PageContainer>
  );
}

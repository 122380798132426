import { Divider, Stack, Typography } from '@mui/material';

export default function MainContainer({ title, children, noDivider }) {
  return (
    <Stack spacing={2}>
      <Typography variant="h2">{title}</Typography>
      {!noDivider && <Divider />}
      {children}
    </Stack>
  );
}

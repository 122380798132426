import { useState, useEffect } from 'react';
import { Reorder, useDragControls, AnimatePresence } from 'framer-motion';
import { toast } from 'react-toastify';
import { styled } from '@mui/system';
import { Paper, Stack, TextField, Button, Select, MenuItem, Divider, Typography, IconButton } from '@mui/material';
import Icon from 'components/Icon';
import { useSystemInfoService } from 'apis/systemInfo';

const PreviewContainer = styled(Paper)({
  flex: 0.5,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 16,
  position: 'relative',

  '&::before': {
    position: 'absolute',
    content: '"預覽"',
    top: 4,
    left: 4,
    color: '#00000088',
  },

  '&::after': {
    position: 'absolute',
    content: '"預覽"',
    right: 4,
    bottom: 4,
    color: '#00000088',
  },
});

const ReorderGroup = styled(Reorder.Group)({
  display: 'flex',
  flexDirection: 'column',
  gap: 4,
  marginTop: 4,
  listStyleType: 'none',
  padding: 'unset',
  counterReset: 'orderItem',
});

const ReorderItem = styled(Reorder.Item)(({ theme }) => {
  const labelWidth = 20;

  return {
    position: 'relative',
    '& > div': {
      paddingLeft: `calc(${labelWidth}px * 1.2)`,
    },
    '&:before': {
      counterIncrement: 'orderItem',
      content: 'counter(orderItem)',
      position: 'absolute',
      width: labelWidth,
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      color: 'white',
      backgroundColor: theme.palette.info.light,
    },
  };
});

const MainContainer = styled(Paper)({
  background: 'white',
  padding: 8,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  height: '100%',
  overflow: 'hidden',
});

function ContactItem({ status, onRemove }) {
  const controls = useDragControls();
  return (
    <ReorderItem
      value={status}
      dragListener={false}
      dragControls={controls}
      initial={{ opacity: 0, x: 0, height: 56 }}
      animate={{ opacity: 1, x: 0, height: 56 }}
      exit={{ opacity: 0, x: 20, height: 0, transition: { duration: 0.6 } }}
      style={{ overflow: 'hidden', borderRadius: 4 }}
    >
      <MainContainer elevation={1}>
        <Typography>{status}</Typography>
        <Stack direction="row" alignItems="center">
          <IconButton onClick={onRemove}>
            <Icon iconName="delete" />
          </IconButton>
          <IconButton onPointerDown={e => controls.start(e)}>
            <Icon iconName="drag" />
          </IconButton>
        </Stack>
      </MainContainer>
    </ReorderItem>
  );
}

export default function ContactStatus() {
  const { useOneSystemInfo, updateSystemInfo } = useSystemInfoService();
  const { data } = useOneSystemInfo('contactStatus');
  const [value, setValue] = useState('');
  const [list, setList] = useState([]);

  useEffect(() => {
    if (!data) return;

    if (data.details) {
      setList(data.details);
    }
  }, [data]);

  const handlePressEnter = e => {
    if (e.key === 'Enter') {
      handleAdd(e);
    }
  };

  const handleAdd = e => {
    const newValue = value.trim();

    if (list.includes(newValue)) {
      alert('已有相同的聯絡狀態名稱');
      return;
    }

    setList(prev => [...prev, newValue]);
    setValue('');
  };

  const handleRemove = removeItem => {
    setList(prev => prev.filter(item => item !== removeItem));
  };

  const handleSave = () => {
    toast.promise(updateSystemInfo({ name: 'contactStatus', details: list }), {
      pending: '處理中',
      success: '儲存成功',
      error: '儲存失敗',
    });
  };

  return (
    list && (
      <Stack>
        <Stack direction="row" spacing={1} sx={{ height: 100 }} justifyContent="space-between">
          <PreviewContainer sx={{ flex: 1, px: 6 }}>
            <Select fullWidth>
              {list.map(item => (
                <MenuItem key={`menu_item_${item}`}>{item}</MenuItem>
              ))}
            </Select>
          </PreviewContainer>
          <Button variant="contained" sx={{ width: 200 }} onClick={handleSave}>
            儲存
          </Button>
        </Stack>
        <Divider sx={{ mt: 2, mb: 1 }} />
        <ReorderGroup axis="y" values={list} onReorder={setList}>
          <AnimatePresence>
            {list.map(item => (
              <ContactItem key={item} status={item} onRemove={() => handleRemove(item)} />
            ))}
          </AnimatePresence>
        </ReorderGroup>
        <Paper elevation={1} sx={{ mt: 3, p: 1, display: 'flex', position: 'relative', zIndex: 9 }}>
          <TextField
            fullWidth
            label="新增聯絡狀態名稱 (按下ENTER新增)"
            value={value}
            onChange={e => setValue(e.target.value)}
            onKeyDown={handlePressEnter}
          />
          <Button onClick={handleAdd}>新增</Button>
        </Paper>
      </Stack>
    )
  );
}

import { useMemo, useRef } from 'react';
import { Pagination } from '@mui/material';

const Paginator = ({ totalPage, currentPage, onChange, sx, ...props }) => {
  const totalPageRef = useRef(totalPage);
  const memoTotalPage = useMemo(() => {
    if (totalPage || totalPage === 0) {
      return (totalPageRef.current = totalPage);
    }
    return totalPageRef.current;
  }, [totalPage]);

  return <Pagination count={memoTotalPage} page={currentPage} onChange={(e, n) => onChange(n)} sx={sx} {...props} />;
};

export default Paginator;

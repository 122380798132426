import { AppBar as Bar } from '@mui/material';

export default function AppBar(props) {
  return (
    <Bar
      {...props}
      sx={{
        height: 80,
        color: 'info.main',
        backgroundColor: 'secondary.main',
        boxShadow: 'none',
        position: 'sticky',
        top: 0,
      }}
    />
  );
}

import { useContext } from 'react';
import { AuthContext } from 'components/context/auth';
import { useGetFetch, postFetch } from 'apis/fetcher';
import URLs from 'constant/urls';
import toQueryString from 'libs/api/toQueryString';

export const useBatchService = () => {
  const serviceURL = URLs.batch;
  const { token } = useContext(AuthContext);

  return {
    useBatchLog: (queryOptions, swrOptions, ableToFetch = true) => {
      const query = toQueryString({ sort: 'created_at desc', ...queryOptions });
      const fetchURL = ableToFetch ? `${serviceURL}${query}` : null;
      return useGetFetch(fetchURL, swrOptions);
    },
    importCustomer: async data => {
      return await postFetch(`${serviceURL}/customer/import`, { data, token });
    },
    customerAssignToUsers: async data => {
      return await postFetch(`${serviceURL}/project/customer/assign`, { data, token });
    },
    addFromCustomerPool: async (customerQueryOpts, data) => {
      const query = toQueryString(customerQueryOpts);

      return await postFetch(`${serviceURL}/project/customer/pool${query}`, { data, token });
    },
    transferCustomer: async (customerQueryOpts, data) => {
      const query = toQueryString(customerQueryOpts);
      return await postFetch(`${serviceURL}/project/customer/transfer${query}`, { data, token });
    },
    parentTransfer: async data => {
      return await postFetch(`${serviceURL}/parent-project/customer/transfer`, { data, token });
    },
  };
};

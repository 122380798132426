import { useId } from 'react';

import { useProductService } from 'apis/product';
import useSearch from 'libs/hooks/useSearch';
import ContentContainer from 'components/UI/layout/ContentContainer';
import PageContainer from 'components/UI/layout/PageContainer';
import SearchContainer from 'components/UI/layout/SearchContainer';
import PaginatorContainer from 'components/UI/layout/PaginatorContainer';
import ListItem from 'components/ListItem';
import { ProductListItem } from 'components/Product';
import Actions from './Actions';
import SearchForm from './SearchForm';

export default function ProductList() {
  const formId = useId();
  const { useAllProduct } = useProductService();

  const { page, limit, query, handleChangePage, handleChangeLimit, handleChangeQuery } = useSearch();
  const { data: productsData, isLoading, mutate } = useAllProduct({ page, limit, ...query, sort: 'product_name desc' });

  const handleSearch = evt => {
    evt.preventDefault();
    const formData = new FormData(evt.target);
    const query = {};
    for (const [k, v] of formData) {
      query[k] = v;
    }
    handleChangePage(1);
    handleChangeQuery(query);
  };

  const handleClearSearch = () => {
    handleChangeQuery({});
  };

  return (
    <PageContainer>
      <SearchContainer>
        <form id={formId} onSubmit={handleSearch}>
          <ContentContainer>
            <SearchForm formId={formId} onClearSearch={handleClearSearch} />
          </ContentContainer>
        </form>
      </SearchContainer>

      <ContentContainer justifyContent="center">
        <PaginatorContainer
          totalPage={productsData?.totalPage || 0}
          currentPage={page}
          limit={limit}
          onPageChange={handleChangePage}
          onLimitChange={handleChangeLimit}
          totalCount={productsData?.totalCount || 0}
          onRefresh={() => mutate(null)}
          isLoading={isLoading}
          actions={<Actions query={query} totalCount={productsData?.totalCount || 0} />}
        >
          {isLoading && Array.from({ length: 5 }, (_, i) => <ListItem.Skeleton key={i} />)}
          {productsData && productsData.list.map(product => <ProductListItem key={product.id} {...product} />)}
        </PaginatorContainer>
      </ContentContainer>
    </PageContainer>
  );
}

import { Stack } from '@mui/material';
import { StyledContent } from './style';

const TabPanel = props => {
  const { children, value, index, panelProps, ...other } = props;

  return (
    <StyledContent role="tabpanel" hidden={value !== index} {...other}>
      {value === index && (
        <Stack direction="row" flexWrap="wrap" width="100%" columnGap={1.5} rowGap={1} {...panelProps}>
          {children}
        </Stack>
      )}
    </StyledContent>
  );
};
export default TabPanel;

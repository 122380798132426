import { cloneElement, useId } from 'react';
import { Stack, MenuItem } from '@mui/material';
import { useProductService, useProductCategoryService } from 'apis/product';
import useToggle from 'libs/hooks/useToggle';
import useAsync from 'libs/hooks/useAsync';
import { FullScreenDialog } from 'components/Dialog';
import { FormContainer } from 'components/Container';
import { SizedInput } from 'components/Input';

export default function ProductFormDialog({ button, addNewProduct, productId }) {
  const formId = useId();
  const { state, toggle } = useToggle();
  const handleAsync = useAsync();

  const { useOneProduct, updateProduct, addProduct } = useProductService();
  const { useAllProductCategory } = useProductCategoryService();

  const { data: productData, isLoading } = useOneProduct(productId, null, state && !addNewProduct);
  const { data: categoryData } = useAllProductCategory({ limit: 'zero' });

  const handleSubmit = evt => {
    evt.preventDefault();
    const form = new FormData(evt.target);
    const obj = {};
    for (const [k, v] of form) {
      if (k === 'price') {
        obj[k] = Number(v);
        continue;
      }
      obj[k] = v;
    }
    handleAsync(addNewProduct ? addProduct.bind(null, obj) : updateProduct.bind(null, productId, obj), () => toggle());
  };

  return (
    <>
      {cloneElement(button, { onClick: toggle })}
      <FullScreenDialog
        open={state}
        onClose={toggle}
        onConfirm={toggle}
        formId={formId}
        isConfirmAsSubmit
        isLoading={isLoading && !addNewProduct}
        title={button ? button.props.children : addNewProduct ? '建立新商品' : '編輯商品資訊'}
      >
        {(productData || addNewProduct) && (
          <Stack
            component="form"
            id={formId}
            onSubmit={handleSubmit}
            sx={{
              padding: '36px 30px 16rem',
              gap: 4,
            }}
          >
            <FormContainer title="基本資訊">
              <SizedInput name="productName" label="商品名稱" required defaultValue={addNewProduct ? '' : productData.productName} />
              <SizedInput name="productCode" label="商品代碼" required defaultValue={addNewProduct ? '' : productData.productCode} />
              <SizedInput
                select
                label="商品類別"
                name="productCategoryId"
                defaultValue={addNewProduct ? '' : productData.productCategoryId}
              >
                <MenuItem value="">清除</MenuItem>
                {categoryData &&
                  categoryData.list.map(cat => (
                    <MenuItem key={cat.id} value={cat.id} disabled={!cat.isEnable}>
                      {cat.categoryName} {!cat.isEnable && '(未啟用)'}
                    </MenuItem>
                  ))}
              </SizedInput>
              <SizedInput name="price" label="商品價格" type="number" required defaultValue={addNewProduct ? '' : productData.price} />
              <SizedInput
                name="internationalBarcode"
                label="國際條碼"
                defaultValue={addNewProduct ? '' : productData.internationalBarcode}
              />
              <SizedInput
                name="description"
                label="商品敘述"
                multiline
                minRows={8}
                sx={{ width: '100%' }}
                noAutoMargin
                defaultValue={addNewProduct ? '' : productData.description}
              />
            </FormContainer>
          </Stack>
        )}
      </FullScreenDialog>
    </>
  );
}

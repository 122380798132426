import { cloneElement, useState } from 'react';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@mui/material';
import Icon from 'components/Icon';
import TaskEditDialog from './TaskEditDialog';

export default function TaskItemMenu({ button, taskId, onCheck, onEdit, onDelete, isClicked }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const bindWithClose = cb => () => {
    cb();
    handleClose();
  };

  return (
    <>
      {cloneElement(button, { onClick: handleClick })}
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <MenuItem onClick={bindWithClose(onCheck)}>
          <ListItemIcon>
            <Icon name="circleCheck" />
          </ListItemIcon>
          <ListItemText primary={`設為${isClicked ? '未讀' : '已讀'}`} />
        </MenuItem>
        <TaskEditDialog
          taskId={taskId}
          onEdit={onEdit}
          button={
            <MenuItem>
              <ListItemIcon>
                <Icon name="edit" />
              </ListItemIcon>
              <ListItemText primary={'編輯'} />
            </MenuItem>
          }
        ></TaskEditDialog>
        <MenuItem onClick={bindWithClose(onDelete)}>
          <ListItemIcon>
            <Icon name="delete" />
          </ListItemIcon>
          <ListItemText primary={'刪除'} />
        </MenuItem>
      </Menu>
    </>
  );
}

import { useContext } from 'react';
import { AuthContext } from 'components/context/auth';
import { useGetFetch, postFetch, putFetch, deleteFetch } from 'apis/fetcher';
import URLs from 'constant/urls';
import toQueryString from 'libs/api/toQueryString';

export const useCustomerService = () => {
  const serviceURL = URLs.customer;
  const { token } = useContext(AuthContext);

  return {
    useAllCustomer: (queryOptions, swrOptions, ableToFetch = true) => {
      const query = toQueryString({ sort: 'name asc', ...queryOptions });
      const fetchURL = ableToFetch ? `${serviceURL}${query}` : null;
      return useGetFetch(fetchURL, swrOptions);
    },
    useOneCustomer: (customerId, swrOptions, ableToFetch = true) => {
      const fetchURL = customerId && (ableToFetch ? `${serviceURL}/${customerId}` : null);
      return useGetFetch(fetchURL, swrOptions);
    },
    updateCustomer: async (customerId, data) => {
      return await putFetch(`${serviceURL}/${customerId}`, { data, token });
    },
    createCustomer: async data => {
      return await postFetch(serviceURL, { data, token });
    },
    addProjectToCustomer: async (customerId, projectId) => {
      return await postFetch(`${URLs.project}/${projectId}/customer/${customerId}`, {
        token,
      });
    },
    deleteCustomer: async customerId => {
      return await deleteFetch(`${serviceURL}/${customerId}`, { token });
    },
  };
};

export const useCustomerSourceService = () => {
  const serviceURL = URLs.customerSource;
  const { token } = useContext(AuthContext);

  return {
    useAllCustomerSource: (queryOptions, swrOptions, ableToFetch = true) => {
      const query = toQueryString(queryOptions);
      const fetchURL = ableToFetch ? `${serviceURL}${query}` : null;
      return useGetFetch(fetchURL, swrOptions);
    },
    addNewCustomerSource: async data => {
      try {
        return await postFetch(serviceURL, { data, token });
      } catch (error) {
        throw new Error(error);
      }
    },
  };
};

export const useCustomerTagService = () => {
  const serviceURL = URLs.customerTag;
  const { token } = useContext(AuthContext);

  return {
    useAllCustomerTag: (queryOptions, swrOptions, ableToFetch = true) => {
      const query = toQueryString({ limit: 'zero', sort: 'seq asc', ...queryOptions });
      const fetchURL = ableToFetch ? `${serviceURL}${query}` : null;
      return useGetFetch(fetchURL, swrOptions);
    },
    useOneCustomerTag: (customerTagId, swrOptions, ableToFetch = true) => {
      const fetchURL = customerTagId && (ableToFetch ? `${serviceURL}/${customerTagId}` : null);
      return useGetFetch(fetchURL, swrOptions);
    },

    addNewCustomerTag: async data => {
      try {
        return await postFetch(serviceURL, { data, token });
      } catch (error) {
        throw new Error(error);
      }
    },
    updateCustomerTag: async (customerTagId, data) => {
      try {
        return await putFetch(`${serviceURL}/${customerTagId}`, { data, token });
      } catch (error) {
        throw new Error(error);
      }
    },
    deleteCustomerTag: async customerTagId => {
      try {
        return deleteFetch(`${serviceURL}/${customerTagId}`, { token });
      } catch (error) {
        throw new Error(error);
      }
    },
  };
};

import { useId, useState } from 'react';
import useSearch from 'libs/hooks/useSearch';
import { useProjectService } from 'apis/project';
import ContentContainer from 'components/UI/layout/ContentContainer';
import PageContainer from 'components/UI/layout/PageContainer';
import PaginatorContainer from 'components/UI/layout/PaginatorContainer';
import SearchContainer from 'components/UI/layout/SearchContainer';
import ListItem from 'components/ListItem';
import { ProjectListItem } from 'components/Project';
import Actions from './Actions';
import SearchForm from './SearchForm';
import TableView from './TableView';

export default function ProjectList() {
  const formId = useId();
  const { useAllProject } = useProjectService();

  const [view, setView] = useState('CARD'); //CARD, TABLE
  const isCardView = view === 'CARD';
  const isTableView = view === 'TABLE';

  const { page, limit, query, handleChangePage, handleChangeLimit, handleChangeQuery } = useSearch();
  const { data: projectsData, isLoading, mutate } = useAllProject({ page, limit, isEnable: true, ...query, sort: 'end_date desc' });

  const handleSearch = evt => {
    evt.preventDefault();
    const formData = new FormData(evt.target);
    const query = {};
    for (const [k, v] of formData) {
      query[k] = v;
    }

    handleChangePage(1);
    handleChangeQuery(query);
  };

  const handleClearSearch = () => {
    handleChangeQuery({ isEnable: '' });
  };

  const handleViewChange = () => {
    setView(prev => (prev === 'CARD' ? 'TABLE' : 'CARD'));
  };

  return (
    <PageContainer>
      <SearchContainer>
        <form id={formId} onSubmit={handleSearch}>
          <ContentContainer>
            <SearchForm formId={formId} onClearSearch={handleClearSearch} />
          </ContentContainer>
        </form>
      </SearchContainer>
      <ContentContainer justifyContent="center">
        <PaginatorContainer
          totalPage={projectsData?.totalPage || 0}
          currentPage={page}
          limit={limit}
          onPageChange={handleChangePage}
          onLimitChange={handleChangeLimit}
          totalCount={projectsData?.totalCount || 0}
          onRefresh={() => mutate(null)}
          isLoading={isLoading}
          actions={<Actions view={view} onViewChange={handleViewChange} />}
        >
          {isLoading && Array.from({ length: 5 }, (_, i) => <ListItem.Skeleton key={i} />)}
          {isCardView && projectsData && projectsData.list.map(project => <ProjectListItem key={project.id} {...project} />)}
          {isTableView && projectsData && <TableView data={projectsData} />}
        </PaginatorContainer>
      </ContentContainer>
    </PageContainer>
  );
}

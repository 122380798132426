import { Paper, Typography, Stack } from '@mui/material';
import { TooltipIconButton } from 'components/Button';
import { DateBlock } from 'components/Date';
import { InformationLabel } from 'components/Label';
import ProjectStatusBar from 'components/ProjectStatusBar';
import JoinUserDialog from '../JoinUserDialog';
import useToggle from 'libs/hooks/useToggle';

export default function CampaignCard(props) {
  const { state, toggle } = useToggle();

  return (
    <Stack position="relative" onMouseOver={toggle.toTrue} onMouseOut={toggle.toFalse}>
      {!props.ownerUsername && (
        <JoinUserDialog
          projectId={props.projectId}
          customerId={props.customerId}
          onAdd={props.onAddUser}
          button={
            <Stack
              sx={{
                position: 'absolute',
                right: 10,
                top: state ? -20 : 0,
                opacity: state ? 1 : 0,
                transition: 'top 0.2s, opacity 0.2s',
                zIndex: 2,
                backgroundColor: 'warning.main',
                borderRadius: '50%',
              }}
            >
              <TooltipIconButton iconName="user" title="指派人員" />
            </Stack>
          }
        />
      )}
      <Paper
        sx={{
          width: '297px',
          minHeight: 'min(100%, 140px)',
          position: 'relative',
          p: 1,
          cursor: 'pointer',
        }}
        onClick={props.onClick}
      >
        <Stack spacing={1.5} justifyContent="space-between" sx={{ height: 'calc(100% - 50px)' }}>
          <Stack direction="row" justifyContent="space-between" spacing={1}>
            <Typography variant="h4">{props.project.projectName}</Typography>
            {props.ownerUsername && <InformationLabel type="ownerUser" label={props.ownerUser.displayName} />}
          </Stack>
          <Stack direction="row" spacing={1} divider={<div>-</div>} alignItems="center">
            <DateBlock date={props.project.startDate} />
            <DateBlock date={props.project.endDate} />
          </Stack>
        </Stack>
        <Stack sx={{ height: '50px' }} />

        <ProjectStatusBar {...props} barProps={{ width: '100%', height: '50px', top: 'unset', bottom: 0 }} />
      </Paper>
    </Stack>
  );
}

import { useState, useId } from 'react';
import { isEmpty } from 'lodash';
import { motion, AnimatePresence } from 'framer-motion';
import { List } from '@mui/material';

import PageContainer from 'components/UI/layout/PageContainer';
import SearchContainer from 'components/UI/layout/SearchContainer';
import ContentContainer from 'components/UI/layout/ContentContainer';
import PaginatorContainer from 'components/UI/layout/PaginatorContainer';
import { TaskItem } from 'components/Task';
import RecordDialog from 'components/Campaign/RecordDialog';

import { useTaskService } from 'apis/task';
import useSearch from 'libs/hooks/useSearch';
import SearchForm from './SearchForm';

const MotionList = motion(List);

export default function TaskList() {
  const formId = useId();
  const { useAllTask } = useTaskService();

  const { page, limit, query, handleChangePage, handleChangeLimit, handleChangeQuery } = useSearch();
  const { data: taskData, isLoading, mutate } = useAllTask({ isHierarchy: true, page, limit, sort: 'created_at desc', ...query });

  const [dialogProps, setDialogProps] = useState({});

  const handleOpenDialog = (customerId, projectId) => {
    setDialogProps({
      customerId,
      projectId,
    });
  };

  const handleSearch = evt => {
    evt.preventDefault();
    const formData = new FormData(evt.target);
    const queryObj = {};
    for (const [k, v] of formData) {
      if (v === '') {
        continue;
      }
      queryObj[k] = v;
    }

    handleChangePage(1);
    handleChangeQuery(queryObj);
  };

  const handleClearSearch = () => {
    handleChangeQuery({});
  };

  return (
    <PageContainer>
      <SearchContainer>
        <form id={formId} onSubmit={handleSearch}>
          <ContentContainer>
            <SearchForm formId={formId} onClearSearch={handleClearSearch} />
          </ContentContainer>
        </form>
      </SearchContainer>
      <ContentContainer justifyContent="center" gap={0}>
        <PaginatorContainer
          showBtmPaginator
          totalPage={taskData?.totalPage || 0}
          currentPage={page}
          limit={limit}
          totalCount={taskData?.totalCount || 0}
          onPageChange={handleChangePage}
          onLimitChange={handleChangeLimit}
          onRefresh={() => mutate(null)}
          isLoading={isLoading}
        >
          <AnimatePresence mode="wait">
            <MotionList
              key={`${page}_${limit}_${JSON.stringify(query)}`}
              sx={{ my: 1, width: '100%' }}
              initial={{ x: -25, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              exit={{ x: 25, opacity: 0 }}
              transition={{ type: 'string' }}
            >
              <AnimatePresence>
                {taskData &&
                  taskData.list.map(task => (
                    <TaskItem
                      key={task.id}
                      {...task}
                      onOpen={() => handleOpenDialog(task.customerId, task.projectId)}
                      onUpdate={() => mutate()}
                      noInteraction
                    />
                  ))}
              </AnimatePresence>
            </MotionList>
          </AnimatePresence>
        </PaginatorContainer>
      </ContentContainer>
      <RecordDialog {...dialogProps} isOpen={!isEmpty(dialogProps)} onClose={() => setDialogProps({})} />
    </PageContainer>
  );
}

import { toast } from 'react-toastify';
import useSWR from 'swr';

const fetcher = args => {
  return fetch(...args)
    .then(res => {
      return res.json();
    })
    .then(data => {
      if (data.hasOwnProperty('error')) {
        // eslint-disable-next-line
        throw `${data.error}`;
      }

      return data;
    })
    .catch(err => {
      toast.error(err.message);
      throw new Error(err.message);
    });
};

export const useGetFetch = (url, options) => {
  const { data, error, mutate } = useSWR(url, fetcher, {
    revalidateOnFocus: false,
    ...options,
  });
  return { data, isLoading: !data && !error, isError: error, mutate };
};

export const getFetch = async (url, payload) => {
  const res = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      ...(payload.token && {
        Authorization: 'Bearer ' + payload.token,
      }),
    },
  });
  const data = await res.json();
  if (res.status !== 200) {
    throw new Error(JSON.stringify(data));
  }

  return data;
};

export const postFetch = async (url, payload) => {
  const res = await fetch(url, {
    method: 'POST',
    ...(payload.data && { body: JSON.stringify(payload.data) }),
    headers: {
      'Content-Type': 'application/json',
      ...(payload.token && {
        Authorization: payload.token.includes('Basic') ? payload.token : 'Bearer ' + payload.token,
      }),
    },
  });
  const data = await res.json();
  if (res.status !== 200) {
    throw new Error(JSON.stringify(data));
  }

  return data;
};

export const putFetch = async (url, payload) => {
  const res = await fetch(url, {
    method: 'PUT',
    ...(payload.data && { body: JSON.stringify(payload.data) }),
    headers: {
      'Content-Type': 'application/json',
      ...(payload.token && {
        Authorization: 'Bearer ' + payload.token,
      }),
    },
  });
  const data = await res.json();
  if (res.status !== 200) {
    throw new Error(JSON.stringify(data));
  }

  return data;
};

export const deleteFetch = async (url, payload) => {
  const res = await fetch(url, {
    method: 'DELETE',
    ...(payload.data && { body: JSON.stringify(payload.data) }),
    headers: {
      'Content-Type': 'application/json',
      ...(payload.token && {
        Authorization: 'Bearer ' + payload.token,
      }),
    },
  });
  const data = await res.json();
  if (res.status !== 200) {
    throw new Error(JSON.stringify(data));
  }

  return data;
};

export const patchFetch = async (url, payload) => {
  const res = await fetch(url, {
    method: 'PATCH',
    ...(payload?.data && { body: JSON.stringify(payload.data) }),
    headers: {
      'Content-Type': 'application/json',
      ...(payload.token && {
        Authorization: 'Bearer ' + payload.token,
      }),
    },
  });
  const data = await res.json();
  if (res.status !== 200) {
    throw new Error(JSON.stringify(data));
  }

  return data;
};

export default fetcher;

import { useState, useCallback } from 'react';
import { styled } from '@mui/system';
import { Chip, Tooltip, Button, Stack, Paper, TextField } from '@mui/material';
import ColorPicker from './ColorPicker';

const PreviewContainer = styled(Paper)({
  flex: 0.5,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 16,
  position: 'relative',

  '&::before': {
    position: 'absolute',
    content: '"標籤預覽"',
    top: 4,
    left: 4,
    color: '#00000088',
  },

  '&::after': {
    position: 'absolute',
    content: '"標籤預覽"',
    right: 4,
    bottom: 4,
    color: '#00000088',
  },
});

const FormContainer = styled(Paper)({
  flex: 1.5,
  padding: 16,
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  gap: 8,
  '& > *': {
    width: '100%',
    '&:nth-of-type(1)': {
      width: '70%',
    },
    '&:nth-of-type(2)': {
      width: '28%',
    },
    '&:nth-of-type(3)': {
      width: '100%',
    },
  },
});

export default function CustomerTagForm({ mode, defaultValue, onUpdate, onAdd }) {
  const [formData, setFormData] = useState({
    tagName: defaultValue?.tagName || '',
    description: defaultValue?.description || '',
    color: defaultValue?.color || 'primary',
  });

  const handleChange = useCallback(evt => {
    const { name, value } = evt.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  }, []);

  const handleColorChange = useCallback(value => {
    setFormData(prev => ({ ...prev, color: value }));
  }, []);

  const handleResetForm = useCallback(() => {
    setFormData({
      tagName: '',
      description: '',
      color: 'primary',
    });
  }, []);

  const handleSubmit = useCallback(() => {
    if (!formData.tagName) {
      alert('請輸入標籤名稱');
      return;
    }

    if (mode === 'create') {
      onAdd(formData);
      handleResetForm();
    }

    if (mode === 'update') {
      onUpdate({ ...formData });
    }
    // eslint-disable-next-line
  }, [formData]);

  const clipTagName = formData.tagName
    ? formData.tagName.length > 5
      ? formData.tagName.slice(0, 5) + '...'
      : formData.tagName
    : '[請輸入內容]';

  return (
    <Stack direction="row" spacing={2}>
      <PreviewContainer>
        <Tooltip title={formData.description}>
          <Chip label={clipTagName} variant="outlined" color={formData.color} />
        </Tooltip>
        <Tooltip title={formData.description}>
          <Chip size="small" label={clipTagName} variant="outlined" color={formData.color} />
        </Tooltip>
      </PreviewContainer>
      <FormContainer>
        <TextField required label="標籤名稱" name="tagName" value={formData.tagName} onChange={handleChange} />
        <ColorPicker value={formData.color} onChoose={handleColorChange} />
        <TextField label="標籤說明" name="description" value={formData.description} onChange={handleChange} />
        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button variant="outlined" color="error" onClick={handleResetForm}>
            清空
          </Button>
          {mode === 'create' && (
            <Button variant="contained" color="warning" onClick={handleSubmit}>
              新增
            </Button>
          )}
          {mode === 'update' && (
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              更新
            </Button>
          )}
        </Stack>
      </FormContainer>
    </Stack>
  );
}

import { ProductFormDialog } from 'components/Product';
import { ActionButton } from 'components/Button';

export default function Actions() {
  return (
    <ProductFormDialog
      addNewProduct
      button={
        <ActionButton iconName="add" color="info">
          新增商品
        </ActionButton>
      }
    />
  );
}

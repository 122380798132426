import { useState, useMemo } from 'react';
import { isNull, isBoolean, isEqual } from 'lodash';
import { useCampaignService } from 'apis/campaign';
import { useProjectService } from 'apis/project';
import { dataFieldsMap } from '../data';
import { useEffect } from 'react';

export default function useAssignStep(defaultProjectId) {
  const { useCampaignStatisticsClassify } = useCampaignService();
  const { useOneProject } = useProjectService();

  const { data: defaultProject } = useOneProject(defaultProjectId, null, Boolean(defaultProjectId));

  const [warning, setWarning] = useState('');
  const [state, setState] = useState({
    project: null,
    isFieldsOn: null,
    fields: [],
  });
  const [selectionModel, setSelectionModel] = useState([]);

  const { data: campaignStatic } = useCampaignStatisticsClassify(
    {
      projectId: state.project?.id,
      fields: state.fields.map(field => field.key),
    },
    null,
    Boolean(state.project),
  );

  const dataGridRow = useMemo(() => {
    if (!campaignStatic || !campaignStatic?.list) return [];

    const newList = [];

    campaignStatic.list.forEach(item => {
      if (state.fields.some(e => isNull(item[e.key]) || item[e.key] === '')) {
        return;
      }

      const id = state.fields.map(e => `${e.key}.${item[e.key]}`).join(',');
      newList.push({
        id,
        fields: state.fields
          .map(e => {
            if (dataFieldsMap[e.key].opt) {
              return dataFieldsMap[e.key].opt[item[e.key]].toString();
            }
            return item[e.key].toString();
          })
          .join(', '),
        totalCount: item.totalCount,
        assignedCount: item.assignedCount,
        notAssignedCount: item.totalCount - item.assignedCount,
      });
    });

    return newList;
    // eslint-disable-next-line
  }, [campaignStatic]);

  const step = useMemo(() => {
    if (isBoolean(state.isFieldsOn)) return 2;
    if (state.project) {
      const { total, assigned } = state.project.statistics;
      if (isEqual(total - assigned, 0)) {
        setWarning('專案無可分配的名單');
        return 1;
      } else {
        setWarning('');
        return 1;
      }
    }
    if (!state.project) return 0;
  }, [state]);

  //if defaultProject exist
  useEffect(() => {
    if (defaultProject) {
      setState(prev => ({ ...prev, project: defaultProject }));
    }
  }, [defaultProject]);

  const handleSetProject = v => setState(p => ({ ...p, project: v }));
  const handleSetIsFieldsOn = v => setState(p => ({ ...p, isFieldsOn: v }));
  const handleSetFields = v => setState(p => ({ ...p, fields: v }));

  const handleToStepOne = () => {
    setState({
      project: null,
      isFieldsOn: null,
      fields: [],
    });
  };

  const handleToStepTwo = () => {
    setState(p => ({
      ...p,
      isFieldsOn: null,
    }));
  };

  const handleReset = () => {
    setWarning('');
    setState({
      project: defaultProject || null,
      isFieldsOn: null,
      fields: [],
    });
    setSelectionModel([]);
  };

  return {
    state,
    step,
    warning,
    campaignStatic,
    dataGridRow,
    handleSetProject,
    handleSetIsFieldsOn,
    handleSetFields,
    handleToStepOne,
    handleToStepTwo,
    selectionModel,
    setSelectionModel,
    handleReset,
  };
}

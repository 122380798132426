import { useCustomerTagService } from 'apis/customer';
import useAsync from 'libs/hooks/useAsync';
import { Box, Paper, Stack, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Typography } from '@mui/material';
import MainContainer from 'components/UI/layout/MaintainContainer';
import CustomerTagForm from 'components/maintain/CustomerTagForm';
import { TooltipIconButton } from 'components/Button';
import CustomerTagFormDialog from 'components/maintain/CustomerTagFormDialog';
import { colorNameTable } from 'constant/color';
import { ConfirmDialog } from 'components/Dialog';

const ListItem = ({ tag, onUpdate, onDelete }) => {
  const sequence = tag.seq;
  const tagName = tag.tagName;
  const description = tag.description;
  const color = tag.color;
  const colorName = colorNameTable[color];

  return (
    <TableRow hover>
      <TableCell>{sequence}</TableCell>
      <TableCell>{tagName}</TableCell>
      <TableCell>{description}</TableCell>
      <TableCell>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Box
            sx={{
              width: 15,
              height: 15,
              borderRadius: 1,
              backgroundColor: `${color}.main`,
            }}
          />
          <Typography variant="body2">{colorName}</Typography>
        </Stack>
      </TableCell>
      <TableCell align="center">啟用中</TableCell>
      <TableCell align="right">
        <CustomerTagFormDialog
          tag={tag}
          onUpdate={onUpdate}
          ButtonComponent={props => <TooltipIconButton title="編輯" iconName="edit" {...props} />}
        />
        <ConfirmDialog onConfirm={onDelete} button={<TooltipIconButton title="刪除" iconName="close" color="error" />} />
      </TableCell>
    </TableRow>
  );
};

export default function CustomerTag() {
  const {
    useAllCustomerTag,
    // useOneCustomerTag,
    addNewCustomerTag,
    updateCustomerTag,
    deleteCustomerTag,
  } = useCustomerTagService();
  const handleAsync = useAsync();
  const { data: customerTagsData, mutate: customerTagsDataMutate } = useAllCustomerTag();

  const handleAdd = data =>
    handleAsync(
      addNewCustomerTag.bind(null, {
        ...data,
        seq: customerTagsData?.totalCount + 1,
      }),
      customerTagsDataMutate,
    );
  const handleUpdate = (tagId, data) => handleAsync(updateCustomerTag.bind(null, tagId, data), customerTagsDataMutate);
  const handleDelete = tagId => handleAsync(deleteCustomerTag.bind(null, tagId), customerTagsDataMutate);

  return (
    <MainContainer title="客戶標籤管理">
      <Stack spacing={2}>
        {customerTagsData && <CustomerTagForm mode="create" onAdd={handleAdd} />}
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>次序</TableCell>
                <TableCell>標籤名稱</TableCell>
                <TableCell>標籤內容</TableCell>
                <TableCell>按鈕顏色</TableCell>
                <TableCell align="center">啟用中</TableCell>
                <TableCell align="right">動作</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {customerTagsData &&
                customerTagsData.list.map(tag => (
                  <ListItem key={tag.id} tag={tag} onUpdate={handleUpdate.bind(null, tag.id)} onDelete={handleDelete.bind(null, tag.id)} />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </MainContainer>
  );
}

import { Stack } from '@mui/material';
import { styled } from '@mui/system';

const InboxTab = styled(Stack, { shouldForwardProp: prop => prop !== 'on' })(({ theme, on }) => ({
  width: '100%',
  borderRadius: `2rem 2rem 0 0`,

  color: on ? '#000000' : '#3E3E3E',
  height: on ? '70px' : '50px',
  transition: 'height 0.2s, background-color 0.25s',
  '&:hover': {
    backgroundColor: '#00000015',
  },

  alignItems: 'center',
  cursor: 'pointer',
  position: 'relative',
  overflow: 'hidden',

  '& > *': {
    position: 'relative',
    zIndex: 5,
  },
  '& > .icon': {
    top: `calc(50% - ${on ? 20 : 12}px)`,
    transition: 'top 0.25s',
  },
  '& > .title': {
    position: 'absolute',
    bottom: on ? 2 : -50,
    transition: 'bottom 0.5s',
  },

  '&:after': {
    content: '""',
    transition: 'bottom 0.35s ease-out',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.warning.main,
    position: 'absolute',
    bottom: on ? 0 : -100,
    zIndex: 1,
    alignSelf: 'flex-start',
  },
}));

export default InboxTab;

import { Autocomplete } from '@mui/material';
import { useCampaignService } from 'apis/campaign';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';

export default function FreeSoloCompanyName(props) {
  const { useCampaignStatisticsClassify } = useCampaignService();
  const { data } = useCampaignStatisticsClassify({ fields: ['customer.companyName'] });

  const list = useMemo(() => {
    if (!data || !data.list) return [];
    const map = {};

    return [...data.list].reduce((acc, cur) => {
      const curName = cur['customer.companyName'];

      if (!curName) return acc;

      if (map[curName]) {
        //pass
      } else {
        acc.push(cur['customer.companyName']);
      }

      return acc;
    }, []);
  }, [data]);

  return (
    <Autocomplete
      freeSolo
      options={list.map(option => option)}
      {...props}
      loading={isEmpty(list)}
      loadingText={'建議選項整理中...'}
      openOnFocus
    />
  );
}

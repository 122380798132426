import Icon from 'components/Icon';

export const iconStyleProps = themeColor => {
  return {
    '& .MuiSvgIcon-root': {
      color: `${[themeColor]}.main`,
      backgroundColor: 'white',
      borderRadius: '50%',
      padding: '1px',
    },
  };
};

export const styleProps = type => {
  const commonStyle = { size: 'small' };
  switch (type) {
    case 'source': {
      return { ...commonStyle, variant: 'outlined' };
    }
    case 'project': {
      return {
        ...commonStyle,
        color: 'primary',
        icon: <Icon name="project" />,
        sx: {
          color: 'white',
          fontWeight: 'bolder',
          ...iconStyleProps('primary'),
        },
      };
    }
    case 'ownerUser': {
      return {
        ...commonStyle,
        color: 'warning',
        icon: <Icon name="user" />,
        sx: { color: 'white', ...iconStyleProps('warning') },
      };
    }
    case 'noOwnerUser': {
      return {
        ...commonStyle,
        color: 'warning',
        icon: <Icon name="user" />,
        variant: 'outlined',
        sx: {
          color: 'warning.contrastTextColor',
          ...iconStyleProps('warning'),
        },
      };
    }
    case 'time': {
      return {
        ...commonStyle,
        variant: 'outlined',
        icon: <Icon name="time" />,
      };
    }
    case 'phone': {
      return {
        ...commonStyle,
        color: 'secondary',
        icon: <Icon name="phone" />,
      };
    }
    default: {
      throw new Error(`Unknown type: ${type}`);
    }
  }
};

import { NavLink } from 'react-router-dom';
import { Stack, Typography, Button, Divider } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';

import { Container, TopSection, LinkSection, Item } from './style';
import links from './data';

const LinkItem = ({ to, linkName, linkProps, onLinkClick }) => {
  return (
    <NavLink to={to} aria-label={linkName} {...linkProps} onClick={onLinkClick}>
      <Item>
        <Typography>{linkName}</Typography>
      </Item>
    </NavLink>
  );
};

/**
 * @component
 * @param {Object} props
 * @param {string} props.userName - to show the user's name
 * @param {bool} props.isManager - to determine to show the manager only page
 * @param {Function} props.onLogout
 * @param {Function} props.onLinkClick - cb when link is clicked
 * @returns
 */
export default function SideMenu({ userName, isManager, onLogout, onLinkClick }) {
  return (
    <Container>
      <TopSection>
        <Typography variant="subtitle2" fontWeight="bolder">
          功能選單
        </Typography>
      </TopSection>
      <LinkSection>
        {links.user.map(link => (
          <LinkItem key={link.linkName} {...link} onLinkClick={onLinkClick} />
        ))}

        {isManager && (
          <>
            <Divider sx={{ mx: 1 }} />
            {links.manager.map(link => (
              <LinkItem key={link.linkName} {...link} onLinkClick={onLinkClick} />
            ))}
          </>
        )}
        <Divider sx={{ mx: 1 }} />
        <LinkItem to="/order" linkName="訂單功能 (DEMO)" />
      </LinkSection>
      <Stack flex="1" justifyContent="flex-end">
        <Stack direction="row" p={2}>
          <Typography flex="1" variant="body2" whiteSpace="pre-wrap">{`使用者：\n${userName}`}</Typography>
          <Button color="error" variant="outlined" startIcon={<LogoutIcon />} onClick={onLogout}>
            登出
          </Button>
        </Stack>
      </Stack>
    </Container>
  );
}

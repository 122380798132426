import { useContext, useCallback } from 'react';
import { AuthContext } from 'components/context/auth';

import ROLE from 'constant/role';

export default function useRoleCheck() {
  const { user } = useContext(AuthContext);

  const isRoleHasRightAs = useCallback(
    role => {
      return ROLE[user?.role.toLowerCase()] <= ROLE[role.toLowerCase()];
    },
    [user],
  );

  return isRoleHasRightAs;
}

import { styled } from '@mui/system';

const Brand = styled('div', { shouldForwardProp: prop => prop !== 'color' })(({ theme, color = 'primary' }) => ({
  borderRadius: 2,
  padding: '1px 2px',
  color: theme.palette[color].contrastText,
  backgroundColor: theme.palette[color].main,
}));

export default Brand;

import dayjs from 'dayjs';
import { useEffect, useState, useRef } from 'react';
import { Badge, Stack, Typography, ListItemText, IconButton } from '@mui/material';

import { useTaskService } from 'apis/task';
import useAsync from 'libs/hooks/useAsync';
import { ActionButton } from 'components/Button';
import Icon from 'components/Icon';
import { InformationLabel } from 'components/Label';
import TaskItemMenu from './TaskItemMenu';
import { TaskItemContainer, TaskItemMainContent, TaskItemSubContent } from './style';

import ErrorBoundary from 'components/ErrorBoundary';

export default function WrappedErrorBoundary(props) {
  return (
    <ErrorBoundary fallback={null}>
      <TaskItem {...props} />
    </ErrorBoundary>
  );
}

function TaskItem(props) {
  const handleAsync = useAsync();
  const prevClickStatus = useRef(props.clicked);
  const [clicked, setClicked] = useState(props.clicked);
  const { updateTask, deleteTask } = useTaskService();

  useEffect(() => {
    if (props.noInteraction) return;

    if (prevClickStatus.current !== clicked) {
      updateTask(props.id, { clicked: true });
      prevClickStatus.current = true;
    }
    // eslint-disable-next-line
  }, [clicked]);

  //for Menu
  const handleUpdateTask = () => {
    updateTask(props.id, { clicked: !clicked });
    prevClickStatus.current = !clicked;
    setClicked(!clicked);
  };

  //for Menu
  const handleEditTask = data => {
    handleAsync(updateTask.bind(null, props.id, data), props.onUpdate);
  };

  //for Menu
  const handleDeleteTask = () => {
    handleAsync(deleteTask.bind(null, props.id), props.onUpdate);
  };

  return (
    <TaskItemContainer
      disablePadding
      whileHover={{
        backgroundColor: '#F5F5F5',
        transition: { duration: 0.2 },
      }}
      exit={{ opacity: 0, height: 0 }}
    >
      <TaskItemMainContent>
        <Stack flex="1">
          <Badge
            color="error"
            badgeContent=""
            invisible={props.noInteraction || clicked}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <Stack ml={1.5}>
              <Stack direction="row" alignItems="center" gap={0.5}>
                <Icon iconName="user" fontSize="small" color="action" />
                <Typography variant="body2">{props.customer.name}</Typography>
              </Stack>
              <Stack direction="row" alignItems="center" gap={0.5}>
                <Icon iconName="project" fontSize="small" color="action" />
                <Typography variant="body2">{props.project.projectName}</Typography>
              </Stack>
            </Stack>
          </Badge>
        </Stack>
        <ListItemText
          sx={{ flex: 1 }}
          primary={props.topic}
          secondary={props.description}
          secondaryTypographyProps={{ whiteSpace: 'pre-wrap' }}
        />
        <Stack direction="row" alignItems="center" gap={1}>
          <ActionButton
            iconName="board"
            color="warning"
            onClick={() => {
              props.onOpen();
              setClicked(true);
            }}
          >
            訪談紀錄
          </ActionButton>
          {!props.noInteraction && (
            <TaskItemMenu
              taskId={props.id}
              isClicked={clicked}
              onCheck={handleUpdateTask}
              onDelete={handleDeleteTask}
              onEdit={handleEditTask}
              button={
                <IconButton>
                  <Icon name="more" />
                </IconButton>
              }
            />
          )}
        </Stack>
      </TaskItemMainContent>
      <TaskItemSubContent>
        <Stack alignItems="flex-start">
          <InformationLabel type="ownerUser" label={'行銷負責人員：' + props.projectCustomers.ownerUser.displayName} />
        </Stack>
        <Stack direction="row" alignItems="center">
          <Icon name="alarm" color="action" sx={{ fontSize: 18, mr: 0.5 }} />
          <Typography variant="body2">{dayjs(props.remindAt).format('YYYY-MM-DD HH:mm')}</Typography>
        </Stack>
        <Stack alignItems="flex-end">
          <Typography variant="body2">建立人： {props.user.displayName}</Typography>
          <Typography variant="body2">建立時間：{dayjs(props.createdAt).format('YYYY-MM-DD HH:mm')}</Typography>
        </Stack>
      </TaskItemSubContent>
    </TaskItemContainer>
  );
}

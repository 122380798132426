import { useContext } from 'react';
import { useGetFetch, postFetch, putFetch, patchFetch, getFetch } from 'apis/fetcher';
import { AuthContext } from 'components/context/auth';
import URLs from 'constant/urls';
import toQueryString from 'libs/api/toQueryString';

export const useUserService = () => {
  const serviceURL = URLs.user;
  //TODO create a customer hook of use user
  const { user, token } = useContext(AuthContext);

  return {
    useAllUser: (queryOptions, swrOptions, ableToFetch = true) => {
      const query = toQueryString({
        limit: 'zero',
        sort: 'updated_at desc',
        ...queryOptions,
        ...(queryOptions?.supervisorUsername && { supervisorUsername: user?.username }),
      });
      const fetchURL = ableToFetch ? `${serviceURL}${query}` : null;
      return useGetFetch(fetchURL, swrOptions);
    },
    useOneUser: (userId, swrOptions, ableToFetch = true) => {
      const fetchURL = ableToFetch ? `${serviceURL}/${userId}` : null;
      return useGetFetch(fetchURL, swrOptions);
    },
    updateUser: async (userId, data) => {
      if (data.role === 'user') {
        if (!data.supervisorUsername) {
          throw new Error('一般人員需有主管歸屬');
        }
      }

      await patchFetch(`${serviceURL}/${userId}/supervisor?supervisorUsername=${data.supervisorUsername || ''}`, { token });

      return await putFetch(`${serviceURL}/${userId}`, { data, token });
    },
    validateDuplicateUsername: async username => {
      return await getFetch(`${serviceURL}/validate/duplicate?username=${username}`, { token });
    },
    addUser: async data => {
      if (data.role === 'user') {
        if (!data.supervisorUsername) {
          throw new Error('一般人員需有主管歸屬');
        }
      }
      return await postFetch(serviceURL, { token, data });
    },
  };
};

var visitType;
(function (visitType) {
  visitType[(visitType['intro'] = 0)] = 'intro';
  visitType[(visitType['quotation'] = 1)] = 'quotation';
  visitType[(visitType['negotiation'] = 2)] = 'negotiation';
  visitType[(visitType['contract'] = 3)] = 'contract';
  visitType[(visitType['close'] = 4)] = 'close';
})(visitType || (visitType = {}));

var visitTypeDisplayName;
(function (visitTypeDisplayName) {
  visitTypeDisplayName['intro'] = '\u4ECB\u7D39';
  visitTypeDisplayName['quotation'] = '\u5831\u50F9';
  visitTypeDisplayName['negotiation'] = '\u5354\u5546';
  visitTypeDisplayName['contract'] = '\u7C3D\u7D04';
  visitTypeDisplayName['close'] = '\u7D50\u6848';
})(visitTypeDisplayName || (visitTypeDisplayName = {}));

var visitTypeMap;
(function (visitTypeMap) {
  visitTypeMap['intro'] = 'introducedAt';
  visitTypeMap['quotation'] = 'quotedAt';
  visitTypeMap['negotiation'] = 'negotiatedAt';
  visitTypeMap['contract'] = 'signedAt';
  visitTypeMap['close'] = 'closedAt';
})(visitTypeMap || (visitTypeMap = {}));

export { visitType, visitTypeDisplayName, visitTypeMap };

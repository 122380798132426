import { motion } from 'framer-motion';
import { styled } from '@mui/system';
import { Stack, ListItem } from '@mui/material';

export const TaskItemContainer = styled(motion(ListItem))(({ theme }) => ({
  flexDirection: 'column',
  backgroundColor: 'white',
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: '0px 12px',
  '&:first-of-type': { borderRadius: '4px 4px 0 0' },
  '&:last-of-type': { borderRadius: '0 0 4px 4px' },
  '&:only-of-type': { borderRadius: 4 },
}));

export const TaskItemMainContent = styled(Stack)(({ theme }) => ({
  flex: 1,
  gap: 4,
  width: '100%',
  minHeight: 82,
  padding: '8px 0px',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& > *:nth-of-type(1)': { flex: '1.6' },
  '& > *:nth-of-type(2)': { flex: '2', padding: '0 22px 0 14px' },
  '& > *:nth-of-type(3)': { flex: '0.8', justifyContent: 'flex-end' },
}));

export const TaskItemSubContent = styled(Stack)(({ theme }) => ({
  width: '100%',
  padding: '4px 0px',
  borderTop: `1px solid ${theme.palette.divider}`,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  '& > *:nth-of-type(1)': { flex: '3.75' },
  '& > *:nth-of-type(2)': { flex: '4' },
  '& > *:nth-of-type(3)': { flex: '2.5' },
}));

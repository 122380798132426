import { cloneElement } from 'react';
import PropTypes from 'prop-types';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PersonIcon from '@mui/icons-material/Person';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import StarsIcon from '@mui/icons-material/Stars';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import MaleIcon from '@mui/icons-material/Male';
import FemaleIcon from '@mui/icons-material/Female';
import BusinessIcon from '@mui/icons-material/Business';
import EditIcon from '@mui/icons-material/Edit';
import Refresh from '@mui/icons-material/Refresh';
import Search from '@mui/icons-material/Search';
import Close from '@mui/icons-material/Close';
import Add from '@mui/icons-material/Add';
import Save from '@mui/icons-material/Save';
import Delete from '@mui/icons-material/Delete';
import MenuIcon from '@mui/icons-material/Menu';
import LoginIcon from '@mui/icons-material/Login';
import TableViewIcon from '@mui/icons-material/TableView';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ContentPaste from '@mui/icons-material/ContentPaste';
import CachedIcon from '@mui/icons-material/Cached';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import NotificationsIcon from '@mui/icons-material/Notifications';
import EmailIcon from '@mui/icons-material/Email';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import LabelIcon from '@mui/icons-material/Label';
import PrintIcon from '@mui/icons-material/Print';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import EscalatorWarningIcon from '@mui/icons-material/EscalatorWarning';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import ViewAgendaIcon from '@mui/icons-material/ViewAgenda';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import CategoryIcon from '@mui/icons-material/Category';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import PinIcon from '@mui/icons-material/Pin';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import CircleIcon from '@mui/icons-material/Circle';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import RecentActorsIcon from '@mui/icons-material/RecentActors';
import Drag from '@mui/icons-material/DragIndicator';

const icons = {
  project: <AssignmentIcon />,
  user: <PersonIcon />,
  time: <AccessTimeIcon />,
  star: <StarsIcon />,
  phone: <LocalPhoneIcon />,
  male: <MaleIcon />,
  female: <FemaleIcon />,
  company: <BusinessIcon />,
  edit: <EditIcon />,
  refresh: <Refresh />,
  search: <Search />,
  close: <Close />,
  add: <Add />,
  save: <Save />,
  delete: <Delete />,
  menu: <MenuIcon />,
  login: <LoginIcon />,
  assign: <LoginIcon />,
  assignInd: <AssignmentIndIcon />,
  table: <TableViewIcon />,
  contentPaste: <ContentPaste />,
  cached: <CachedIcon />,
  circleOutline: <CircleOutlinedIcon />,
  notification: <NotificationsIcon />,
  email: <EmailIcon />,
  infoOutline: <InfoOutlinedIcon />,
  expand: <KeyboardArrowDownIcon />,
  board: <ContentPasteIcon />,
  transfer: <TransferWithinAStationIcon />,
  label: <LabelIcon />,
  print: <PrintIcon />,
  checkCircle: <CheckCircleIcon />,
  outlineCheckBox: <CheckBoxOutlineBlankIcon />,
  checkBox: <CheckBoxIcon />,
  parentChild: <EscalatorWarningIcon />,
  arrowDown: <ArrowDownwardIcon />,
  arrowLeft: <ArrowForwardIcon />,
  arrowRight: <ArrowRightAltIcon />,
  analytics: <AnalyticsIcon />,
  cards: <ViewAgendaIcon />,
  chevronUp: <KeyboardArrowUpIcon />,
  chevronDown: <KeyboardArrowDownIcon />,
  category: <CategoryIcon />,
  coin: <AttachMoneyIcon />,
  number: <PinIcon />,
  calendarToday: <CalendarTodayIcon />,
  calendarMonth: <CalendarMonthIcon />,
  calendarHistory: <EventRepeatIcon />,
  circle: <CircleIcon />,
  alarm: <NotificationsIcon />,
  more: <MoreVertIcon />,
  circleCheck: <TaskAltIcon />,
  principleUsers: <RecentActorsIcon />,
  drag: <Drag />,
};
/**
 * @param  {string} name - Deprecated: use `iconName` instead
 */
export default function Icon({ name, iconName, ...props }) {
  return cloneElement(icons[iconName || name], { ...props });
}

Icon.propTypes = {
  name: PropTypes.string,
  iconName: PropTypes.string,
};

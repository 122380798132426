import { Autocomplete, TextField } from '@mui/material';
import { useUserService } from 'apis/user';
import useDelayValue from 'libs/hooks/useDelayValue';

export default function SelectUser(params) {
  const { useAllUser } = useUserService();
  const { value, delayValue, setValue } = useDelayValue();
  const { data: user, isLoading } = useAllUser({
    isEnable: true,
    isHierarchy: true,
    displayName: delayValue,
  });

  return (
    <Autocomplete
      inputValue={value}
      onInputChange={(e, nv) => setValue(nv)}
      options={user?.list || []}
      getOptionLabel={option => option.displayName}
      renderInput={p => <TextField {...p} label="選擇人員" />}
      loading={isLoading}
      {...params}
    />
  );
}

import useToggle from 'libs/hooks/useToggle';
import { useFaqService } from 'apis/faq';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import RecordFaqForm from 'components/maintain/RecordFaqForm';

export default function RecordFaqFormDialog({ ButtonComponent, faq, onUpdate }) {
  const { state, toggle } = useToggle();
  const { useOneFaq } = useFaqService();
  const { data: oneFaqData } = useOneFaq(faq.id, null, state);
  return (
    <>
      <ButtonComponent onClick={toggle} />
      <Dialog open={state} onClose={toggle.toFalse} maxWidth="lg" fullWidth>
        <DialogTitle>編輯</DialogTitle>
        <DialogContent>{oneFaqData && <RecordFaqForm mode="update" defaultValue={oneFaqData} onUpdate={onUpdate} />}</DialogContent>
      </Dialog>
    </>
  );
}

import { useCallback, useEffect, useState } from 'react';
import { size, isEqual } from 'lodash';
import { Stack, Autocomplete, TextField, Collapse, Button } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useDialogContext } from '../Provider';
import { dataFieldsList, dataGridColumn } from '../data';

export default function StepSelectFields() {
  const { state, handleToStepOne, handleSetIsFieldsOn, handleSetFields, selectionModel, setSelectionModel, dataGridRow, isStepTwoNoBack } =
    useDialogContext();

  const [isLoading, setIsLoading] = useState(true);

  const handleSkip = useCallback(() => {
    if (size(state.fields) > 0) {
      const isConfirm = window.confirm('已經有篩選項目，確認要略過嗎？');
      if (isConfirm) {
        handleSetIsFieldsOn(false);
        handleSetFields([]);
        setSelectionModel([]);
        return;
      }
      return;
    }

    handleSetIsFieldsOn(false);
    // eslint-disable-next-line
  }, [state]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsLoading(false);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [dataGridRow]);

  return (
    <Stack gap={1}>
      <Autocomplete
        multiple
        options={dataFieldsList}
        getOptionLabel={option => option.displayName}
        renderInput={p => <TextField {...p} label="篩選項目" />}
        onChange={(e, nv) => handleSetFields(nv)}
        value={state.fields}
        isOptionEqualToValue={(option, value) => option.key === value.key}
      />
      <Collapse in={size(state.fields) > 0}>
        <Stack sx={{ height: '400px' }}>
          <DataGrid
            rows={dataGridRow}
            columns={dataGridColumn}
            checkboxSelection
            disableColumnMenu
            hideFooter
            isRowSelectable={p => !isEqual(p.row.notAssignedCount, 0)}
            onSelectionModelChange={model => {
              setSelectionModel(model);
            }}
            selectionModel={selectionModel}
            loading={isLoading}
          />
        </Stack>
      </Collapse>
      <Stack direction="row" mb={2} spacing={1}>
        <Button
          variant="contained"
          onClick={() => handleSetIsFieldsOn(true)}
          disabled={!size(state.fields) || isEqual(size(dataGridRow), 0)}
        >
          確認
        </Button>
        <Button variant="outlined" onClick={handleSkip}>
          略過
        </Button>
        <Button onClick={handleToStepOne} disabled={isStepTwoNoBack}>
          上一步
        </Button>
      </Stack>
    </Stack>
  );
}

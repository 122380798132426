import { Stack } from '@mui/material';
import { styled } from '@mui/system';

const TabContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: 'white',
  boxShadow: theme.shadows[1],
  width: '100%',
  height: '140px',
  padding: '16px',
  paddingTop: '28px',
  paddingBottom: '0px',
  borderRadius: '0 0 12px 12px',
  justifyContent: 'space-between',
}));

export default TabContainer;

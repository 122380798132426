import { useState, useEffect, forwardRef, useImperativeHandle, useMemo } from 'react';

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  FormControlLabel,
  Switch,
  TableContainer,
} from '@mui/material';

/**
 * @Deprecated
 */
const DistributeTable = forwardRef(({ isAutoAssign, toggleAutoAssign, users, onRemove, statCount, disabled }, ref) => {
  const [list, setList] = useState({});

  useImperativeHandle(ref, () => ({
    getList: () => list,
  }));

  useEffect(() => {
    setList(prev => {
      const obj = {};
      users.forEach(user => {
        const [username] = user.split('_');
        obj[username] = prev[username] || 0;
      });
      return obj;
    });
    // eslint-disable-next-line
  }, [users.length]);

  const filledCount = useMemo(
    () => Object.values(list).reduce((acc, cur) => acc + Number(cur), 0),
    // eslint-disable-next-line
    [JSON.stringify(list)],
  );

  const handleChange = (username, value) => {
    setList(prev => {
      const obj = { ...prev };
      obj[username] = value;
      return obj;
    });
  };

  return (
    <TableContainer sx={{ backgroundColor: '#E5E5E535', borderRadius: 1, mt: 1, p: 1 }}>
      <Table
        size="small"
        title={disabled ? '請先選擇專案與人員' : ''}
        sx={{
          opacity: disabled ? 0.3 : 1,
          cursor: disabled ? 'not-allowed' : 'default',
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell colSpan={statCount ? 1 : 3}>
              <FormControlLabel
                disabled={disabled}
                control={<Switch checked={isAutoAssign} onChange={toggleAutoAssign} />}
                label="自動分配"
              />
            </TableCell>
            {statCount && (
              <TableCell colSpan={2} align="right">
                可分配客戶數量：{statCount[0] - statCount[1]}
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>人員</TableCell>
            {!isAutoAssign && <TableCell>數量</TableCell>}
            <TableCell align="right">動作</TableCell>
          </TableRow>
          {users.map((user, index) => {
            const [username, userDisplayName] = user.split('_');
            return (
              <TableRow key={`assigning_${username}`}>
                <TableCell sx={{ height: '53px' }}>{userDisplayName}</TableCell>
                {!isAutoAssign && (
                  <TableCell>
                    <TextField type="number" size="small" value={list[username]} onChange={e => handleChange(username, e.target.value)} />
                  </TableCell>
                )}
                <TableCell align="right">
                  <Button variant="outlined" color="error" onClick={onRemove.bind(null, index)}>
                    移除
                  </Button>
                </TableCell>
              </TableRow>
            );
          })}
          <TableRow>
            <TableCell colSpan={3} align="right">
              {isAutoAssign ? '已採用自動分配' : `已填寫 ${filledCount}`}
              {!isAutoAssign && statCount ? `，仍有 ${statCount[0] - statCount[1] - filledCount} 未分配` : ''}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
});

export default DistributeTable;

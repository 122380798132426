import { styled } from '@mui/system';
import { Stack } from '@mui/material';

export const Container = styled(Stack)({
  flexDirection: 'row',
  height: '100%',

  '& > div': {
    '&:nth-of-type(1)': {
      flex: 2,
    },
    '&:nth-of-type(2)': {
      flex: 1,
    },
  },
});

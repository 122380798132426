import { styled } from '@mui/system';
import { Box, Typography } from '@mui/material';

const FormContainer = styled(({ title, children, ...props }) => (
  <Box {...props}>
    <Typography variant="h3" color="#6E6E6E" mb={2.5} flexBasis="100%">
      {title}
    </Typography>
    {children}
  </Box>
))({
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'flex-start',
  width: 1120,
  margin: '0 auto',
  padding: '19px 20px',
  backgroundColor: 'white',
  boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)',

  position: 'relative',
});

export default FormContainer;

import { styled } from '@mui/system';
import { Button } from '@mui/material';

const SearchButton = styled(Button)({
  color: 'white',
  width: 265,
  height: 56,
});

export default SearchButton;

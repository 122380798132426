import { Typography, Stack } from '@mui/material';
import Brand from 'components/Brand';
import { ActionButton } from 'components/Button';
import Icon from 'components/Icon';
import ListItem from 'components/ListItem';
import EmployeeFormDialog from './EmployeeFormDialog';
import usePathCheck from 'libs/hooks/usePathCheck';

export default function EmployeeListItem(props) {
  const isCurUserIncludeIn = usePathCheck();

  const isManager = props.role === 'manager';
  const isUser = props.role === 'user';

  return (
    <ListItem>
      <ListItem.MainContent disabled sx={{ gap: 0.5 }}>
        <Stack>
          {isManager && <Brand color="info">主管</Brand>}
          {isUser && <Brand color="secondary">一般人員</Brand>}
          {!props.isEnable && <Brand>未啟用</Brand>}
        </Stack>
        <Typography variant="h4">
          {props.zone} {props.position} {props.displayName}
        </Typography>
        {props.phone && (
          <Stack>
            <Icon name="phone" fontSize="small" />
            <Typography>{props.phone}</Typography>
          </Stack>
        )}
      </ListItem.MainContent>
      <ListItem.Actions>
        <EmployeeFormDialog
          employeeId={props.id}
          button={
            <ActionButton color="info" iconName="edit" disabled={!isCurUserIncludeIn(props.path)}>
              編輯員工資料
            </ActionButton>
          }
        />
      </ListItem.Actions>
    </ListItem>
  );
}

import { useState, useCallback, useDebugValue } from 'react';

export default function useInputRangeSelection() {
  const [selection, setSelection] = useState({ selectionStart: 0, selectionEnd: 0 });

  //use it when click on input
  const handleChange = useCallback(evt => {
    if (evt.type !== 'click' && evt.type !== 'keyup') {
      console.error('[useInputRangeSelection]: handleChange only works with click and keydown');
      return;
    }
    const target = evt.target;
    const { selectionStart, selectionEnd } = target;

    setSelection({ selectionStart, selectionEnd });

    //eslint-disable-next-line
  }, []);

  //use it when you need to move the selection position by programming but not from user interaction
  const handleTranslation = useCallback(unit => {
    setSelection(prev => ({
      selectionStart: prev.selectionStart + unit,
      selectionEnd: prev.selectionEnd + unit,
    }));
    //eslint-disable-next-line
  }, []);

  useDebugValue(selection);

  return [selection, handleChange, handleTranslation];
}

import { useId } from 'react';
import { useNavigate } from 'react-router-dom';

import { useCustomerService } from 'apis/customer';
import useSearch from 'libs/hooks/useSearch';
import ContentContainer from 'components/UI/layout/ContentContainer';
import PageContainer from 'components/UI/layout/PageContainer';
import SearchContainer from 'components/UI/layout/SearchContainer';
import PaginatorContainer from 'components/UI/layout/PaginatorContainer';
import ListItem from 'components/ListItem';
import { CustomerListItem } from 'components/Customer';
import Actions from './Actions';
import SearchForm from './SearchForm';

export default function CustomerList() {
  const formId = useId();
  const navigate = useNavigate();
  const { useAllCustomer } = useCustomerService();

  const { page, limit, query, handleChangePage, handleChangeLimit, handleChangeQuery } = useSearch();
  const { data: customersData, isLoading, mutate } = useAllCustomer({ page, limit, ...query });

  const handleToCustomerPage = customerId => {
    navigate(`/customer/${customerId}`);
  };

  const handleSearch = evt => {
    evt.preventDefault();
    const formData = new FormData(evt.target);
    const query = {};
    for (const [k, v] of formData) {
      if (v === '') {
        continue;
      }
      query[k] = v;
    }
    handleChangePage(1);
    handleChangeQuery(query);
  };

  const handleClearSearch = () => {
    handleChangeQuery({});
  };

  return (
    <PageContainer>
      <SearchContainer>
        <form id={formId} onSubmit={handleSearch}>
          <ContentContainer>
            <SearchForm formId={formId} onClearSearch={handleClearSearch} />
          </ContentContainer>
        </form>
      </SearchContainer>

      <ContentContainer justifyContent="center">
        <PaginatorContainer
          totalPage={customersData?.totalPage || 0}
          currentPage={page}
          limit={limit}
          onPageChange={handleChangePage}
          onLimitChange={handleChangeLimit}
          totalCount={customersData?.totalCount || 0}
          onRefresh={() => mutate(null)}
          isLoading={isLoading}
          actions={<Actions query={query} totalCount={customersData?.totalCount || 0} />}
        >
          {isLoading && Array.from({ length: 5 }, (_, i) => <ListItem.Skeleton key={i} />)}
          {customersData &&
            customersData.list.map(customer => (
              <CustomerListItem key={customer.id} {...customer} onNav={handleToCustomerPage.bind(null, customer.id)} />
            ))}
        </PaginatorContainer>
      </ContentContainer>
    </PageContainer>
  );
}

import { useDialogContext } from '../Provider';
import { SelectProject } from 'components/Input/AutoComplete';

export default function StepSelectParentProject() {
  const { state, handleSetProject } = useDialogContext();
  return (
    <SelectProject
      value={state.project}
      onChange={(e, nv) => handleSetProject(nv)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      query={{
        isParent: true,
      }}
    />
  );
}

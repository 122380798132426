import { useState } from 'react';
import { Menu, MenuItem, ListItemIcon, ListItemText, Divider } from '@mui/material';
import { ActionButton } from 'components/Button';

import Icon from 'components/Icon';

import { TransferDialog, BatchLogDialog } from 'components/BatchAction';

export default function Actions({ query, totalCount }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <ActionButton color="secondary" startIcon={<Icon name="menu" />} onClick={handleClick}>
        批次作業選單
      </ActionButton>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <TransferDialog
          query={query}
          totalCount={totalCount}
          button={
            <MenuItem>
              <ListItemIcon>
                <Icon name="transfer" />
              </ListItemIcon>
              <ListItemText>轉移名單</ListItemText>
            </MenuItem>
          }
        />
        <Divider sx={{ my: 1 }} />
        <BatchLogDialog
          button={
            <MenuItem>
              <ListItemIcon>
                <Icon name="cached" />
              </ListItemIcon>
              <ListItemText>批次作業進度查看</ListItemText>
            </MenuItem>
          }
        />
      </Menu>
    </>
  );
}

import * as React from 'react';
import { Alert } from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Icon from 'components/Icon';
import { useCampaignService } from 'apis/campaign';

import AssignDialog from 'components/BatchAction/Assign/AssignDialog';
import { TooltipIconButton } from 'components/Button';
import ParentTransferDialog from 'components/BatchAction/ParentTransfer/ParentTransferDialog';

function Row(props) {
  const [open, setOpen] = React.useState(false);

  const { useCampaignStatisticsAssign } = useCampaignService();
  const { data } = useCampaignStatisticsAssign({ projectIds: props.id }, null, open);

  const total = props.statistics.total;
  const assigned = props.statistics.assigned;
  const alone = props.statistics.total - props.statistics.assigned;
  console.log(props);
  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton onClick={() => setOpen(prev => !prev)} disabled={props.statistics.assigned === 0}>
            <Icon name={open ? 'chevronUp' : 'chevronDown'} />
          </IconButton>
        </TableCell>
        <TableCell>
          <Typography variant="body2" sx={{ color: props.isParent ? 'lightgray' : 'auto' }}>
            {props.parentProjectId ? props.parentProject.projectName : ''}
            {props.isParent ? '母專案' : ''}
          </Typography>
        </TableCell>
        <TableCell component="th" scope="row">
          <Stack direction="row" alignItems="center" gap={1}>
            {props.projectName}
            <Icon name="checkCircle" color="success" sx={{ opacity: props.isEnable ? 1 : 0.2 }} />
          </Stack>
        </TableCell>
        <TableCell align="right">{total}</TableCell>
        <TableCell align="right">{assigned}</TableCell>
        <TableCell align="right">{alone}</TableCell>
        <TableCell align="right">
          {props.isParent && (
            <ParentTransferDialog
              defaultParentProjectId={props.id}
              button={<TooltipIconButton title="母子專案名單轉移" iconName="parentChild" disabled={props.statistics.total === 0} />}
            />
          )}
          <AssignDialog
            defaultProjectId={props.id}
            button={
              <TooltipIconButton
                title="客戶名單分配"
                iconName="assign"
                disabled={props.statistics.total - props.statistics.assigned === 0 || props.isParent}
              />
            }
          />
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          style={{
            paddingBottom: 0,
            paddingTop: 0,
            backgroundColor: '#E5E5E548',
          }}
          colSpan={7}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 2 }}>
              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h6" gutterBottom>
                  {props.projectName} 專案員工分配明細
                </Typography>
                <Typography gutterBottom>
                  總數: {total} / 已分配: {assigned} / 未分配: {alone}
                </Typography>
              </Stack>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>員工名字</TableCell>
                    <TableCell align="right">分配數量</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data &&
                    data.list.map(item => (
                      <TableRow key={`${item.id}_${item.ownerUsername}`}>
                        <TableCell>{item.displayName}</TableCell>
                        <TableCell align="right">{item.count}</TableCell>
                      </TableRow>
                    ))}
                  <TableRow>
                    <TableCell colSpan={7} align="right">
                      總計已分配：{assigned}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function TableView({ data }) {
  return (
    <>
      <Alert sx={{ width: '100%' }} severity="info">
        <Stack direction="row" alignItems="center">
          <Typography>
            當分配按鈕
            <IconButton size="small" disabled>
              <Icon iconName="assign" />
            </IconButton>
            顯示淺灰色不可點擊時，代表專案為「無可分配人數」或是為「母專案」
          </Typography>
        </Stack>
      </Alert>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>母專案</TableCell>
              <TableCell>專案名稱</TableCell>
              <TableCell align="right">專案名單總數</TableCell>
              <TableCell align="right">專案名單已分配</TableCell>
              <TableCell align="right">專案名單未分配</TableCell>
              <TableCell align="right">動作</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.list.map(row => (
              <Row key={row.id} {...row} />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

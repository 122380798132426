import { Toolbar as Bar, IconButton, Typography } from '@mui/material';
import ToolButton from 'components/Dialog/FullScreenDialog/ToolButton';
import Icon from 'components/Icon';

export default function Toolbar({ isConfirmAsSubmit, title, form, onConfirm, onClose, onClear }) {
  return (
    <Bar sx={{ height: '100%' }}>
      <IconButton edge="start" color="inherit" onClick={onClose}>
        <Icon name="close" />
      </IconButton>
      <Typography sx={{ ml: 1, flex: 1 }} variant="h2" component="div">
        {title}
      </Typography>
      <ToolButton
        color="info"
        variant="contained"
        {...(isConfirmAsSubmit && {
          form: form,
          type: 'submit',
        })}
        {...(!isConfirmAsSubmit && { onClick: onConfirm })}
      >
        確認儲存
      </ToolButton>
      {/* <ToolButton
        color="error"
        variant="contained"
        type="reset"
        form={form}
        onClick={() => onClear?.()}
      >
        還原/清除資料
      </ToolButton> */}
      <ToolButton color="error" variant="outlined" onClick={onClose}>
        關閉視窗
      </ToolButton>
    </Bar>
  );
}
